'use strict';

var $ = require('jquery')
    , utils = require('../../utils.js')
    , unescape = require('lodash/unescape')
    , customParams = require('../../player-params')
    , events = require('../../global-events')
    , players = [];

events.global.on('logout', destroyContent);

module.exports = {
    inject: function (element, asset, success) {
        try {
            var content = JSON.parse(unescape(asset.content));
            element.empty();

            utils.loadScripts([
                {src: '//play2.qbrick.com/framework/GoBrain.min.js', type: 'script'}
            ], function(){
                var pageContainer = $('<div></div>', {
                    id: 'divPageContainer'
                }).appendTo(element);

                var playerContainer = $('<div></div>', {
                    id: 'divPlayerContainer'
                }).appendTo(pageContainer);

                var account_id = content.account_id,
                    configuration_id = content.configuration_id || 'default',
                    media_id = content.media_id;

                var embedSettings = {
                    config: '//video.qbrick.com/play2/api/v1/accounts/' + account_id + '/configurations/' + configuration_id,
                    data: '//video.qbrick.com/api/v1/public/accounts/' + account_id + '/medias/' + media_id,
                    widgetId: 'player',
                    repeat: false
                };

                $.extend(embedSettings, customParams.getParams('qbrick'));

                var reInitialize = function () {
                    this.embedSettings.autoplay = false;
                    GoBrain.initialize(embedSettings).on('queueComplete', reInitialize);
                };

                var player = GoBrain.create(document.getElementById('divPlayerContainer'), embedSettings).on('queueComplete', reInitialize);

                if (players.indexOf(player) === -1){
                    players.push(player);
                }
            });

            success && success();
        } catch (e) {
            throw new Error(e);
        }
    }
};

function destroyContent(){
    $.each(players, function(i, player){
        player.destroy(true);
    });

    players = [];
}