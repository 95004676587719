module.exports = {
	access_expired_or_revoked: 'Your access has expired or has been revoked',
	complete_registration: 'Register',
	update_profile_data: 'Update',
	social_platform_auth_could_not: 'Social platform authentication could not be completed.',
	you_have_been_logged_out: 'You have been logged out. Your session has expired or your account has reached the limit of simultaneous sessions allowed or has been deleted.',
	account_details: 'Account Details',
	account_updated: 'Account updated',
	password_updated: 'Password updated',
	console_element_doesnt_exist: 'Element with id "%s" doesn\'t exist.',
	console_element_doesnt_exist_2: 'Element with id "inplayer-%s" or attribute "inplayer-id" of value "%s" doesn\'t exist.',
	console_saved_cards_not_implemented: 'Payment with saved cards not implemented yet.',
	console_form_element_not_found: 'Form element not found, id: %s',
	console_body_element_not_found: 'Body element not found, id: %s',
	console_method_doesnt_exist: 'Method %s does not exist.',
	console_asset_no_access_fees: 'This asset has no access fees.',
	global_ise_pls_try_again: 'Internal server error. Please try again.',
	message_full_name: 'The full name should not be empty.',
	message_username: 'The email should not be empty.',
    message_email: 'The email should not be empty.',
	message_password: 'The password should not be empty.',
	message_password_confirmation: 'The password confirmation should match the password input.',
	message_number: 'The card number is not valid.',
	message_card_expiry: 'The card expiry is not valid.',
	message_cvv: 'The cvv is not valid.',
	message_card_name: 'The card name should not be empty.',
	message_unauthorized: 'Invalid token. Please login again.',
	subs_no_active_subscriptions: 'No active subscriptions',
	subs_created_on: 'Created on',
	subs_next_billing_on: 'Next billing on',
	subs_status: 'Status',
	paywall_free_trial_text: 'Paying for this asset will create subscription with %s free trial. You will be charged %s %s after the free trial expires. You can always cancel you subscription before the free trial period ends.',
	paywall_full_discount: '100% discount',
	placeholder_email_address: 'Email address',
	placeholder_password: 'Password',
	placeholder_token: 'Token',
	placeholder_repeat_password: 'Repeat Password',
	placeholder_name_on_card: 'Name on card',
	placeholder_voucher_code: 'voucher code',
	placeholder_full_name: 'Full name',
    placeholder_first_name: 'First name',
    placeholder_surname: 'Last name',
	placeholder_mm_yyyy: 'MM / YYYY',
	placeholder_cvv: 'CVV',
	tooltip_forgot_password: 'forgot password',
	tooltip_cancel_subscription: 'Cancel subscription',
	modal_enter_your_email: 'Enter your email address.',
	modal_submit: 'Submit',
	modal_back: 'back',
	modal_login: 'login',
	modal_sign_up: 'register',
    modal_forgot_password: 'Forgotten your password?',
	modal_register_capital: 'REGISTER',
	modal_login_capital: 'LOGIN',
	modal_choose_price: 'CHOOSE PRICE',
	modal_payment_details: 'PAYMENT DETAILS',
	modal_login_to_your_account: 'Log in to your <%brand_name%> account',
	modal_dont_have_one: 'Don\'t have an account?',
	modal_register_here: 'Register here',
	modal_enter_your_new_password: 'Enter your new password.',
	modal_please_check_your_email: 'Please check your email and copy the token to proceed.',
	modal_resend_token: 'resend token',
	modal_copyright: 'Copyright',
	modal_all_rights_reserved: 'All rights reserved.',
	modal_terms: 'Terms',
	modal_faq: 'FAQ',
	ladda_log_in: 'Log in',
	ladda_apply: 'Apply',
	ladda_next: 'Next',
	ladda_create_account: 'Create Account',
	account_your_subscriptions: 'Your Subscriptions',
	payment_pls_enter_your_card: 'Please enter your card',
	payment_have_a_promotional_code: 'Have a promotional code?',
	payment_im_paying_for: 'I\'m paying for',
	payment_price: 'Price',
	payment_pay: 'Pay',
	payment_subscribe: 'Subscribe',
	payment_add: 'Add',
	preview_protected_by_inplayer: 'Protected by InPlayer',
	preview_this_premium_content: 'This premium content requires an account to access.',
	preview_already_have_access: 'Already have access?',
	preview_login_here: 'Login here with your <%brand_name%> account.',
	prices_pls_select_price: 'Please select price option',
	register_register_new_account: 'Register a new <%brand_name%> account',
    complete_registration_with_social_login: 'Please fill out the fields below to complete your registration',
	register_by_clicking_agree: 'By clicking the button below You agree to the <%register_terms_of_service%> and <%register_privacy_policy%>.',
	register_terms_of_service: 'Terms of Service',
	register_privacy_policy: 'Privacy Policy',
	register_already_have_account: 'Already have an account?',
	register_login_here: 'Log in here',
	saved_cards_pay_with_saved: 'Pay with one of your already saved cards?',
	saved_cards_or_enter_new_card: 'Or enter new card here',
	saved_cards_card_number: 'Card number',
	saved_cards_item_ordered: 'Item Ordered',
	saved_cards_go_back: 'go Back',
	user_menu_use_different: 'Use a different account',
	user_menu_account_details: 'My Account...',
	user_menu_my_account: 'My Subscriptions...',
	user_menu_change_password: 'Change Password...',
	user_menu_log_out: 'Log Out',
	dlc_download: 'Download',
	code: 'en',
	lang_macedonian: 'Macedonian',
	lang_english: 'English',
	lang_spanish: 'Spanish',
	lang_polish: 'Polish',
	lang_french: 'French',
	lang_swedish: 'Swedish',
	select_country: 'Select country',
	card_payment: 'Card Payment',
	paypal_payment: 'PayPal Payment',
	card_management: 'Card Мanagement',
	subscriptions: 'Subscriptions',
	subscribed_for: 'Subscribed for',
	manage_your_cards: 'Manage Your Cards',
	select_card: 'Select Card',
	edit_card: 'Edit…',
	add_new_card: 'Add New Card…',
	sales_currently_not_available: 'Sales currently not available',
	account_management: 'Account Management',
	modal_payment_method: 'payment method',
	pls_select_payment_method: 'Please select payment method',
	card_method: 'Card',
	paypal_method: 'PayPal',
    account_no_active_subscriptions: 'No active subscriptions',
	additional_fee: 'Additional Fee',
	additional_fee_text: '<%&setup_fee_description%>: By purchasing this premium content you will be charged an additional one-time fee in the amount of <%setup_fee_amount%> <%fee_currency%>. This additional amount will be charged even if the subscription has a free-trial period.',
	paypal_not_supported_with_setup_fee: 'PayPal payment method does not currently support subscriptions with an additional setup fee.',
    placeholder_old_password: 'Current password',
	access_code: 'Access Code',
	enter_your_access_code: 'Enter your access code',
    erase_your_account: 'Erase Your Account...',
    erase_your_account_question: 'Are you sure you want to erase your account?',
    erase_account_explanation: 'Erase Account action will erase all your personal data from the system. This includes the following:\n' +
    '\n- Personal information you provided during the registration process' +
    '\n- Location and device information we gather during authentication process' +
    '\n- All other personal information stored in other parts of our database\n' +
    '\nThe only personal data we will keep in the system is one which is part of the transactions and subscriptions records due to other ' +
    'legislation we have to abide by.\n' +
    '\nIn addition to the data erasure, your account will also be deactivated, which means that all your ' +
    'active subscriptions will be cancelled, all your currently active accesses to assets will be revoked and all your active login sessions ' +
    'including this one will be terminated. You will no longer be able to authenticate and purchase assets on this website. Your account ' +
    'information will no longer be accessible in any list to our staff.\n' +
    '\nThis action might take 1-2 days to complete after which a ' +
    'confirmation email will be sent to your email address. The email will be erased from our system after successful delivery.',
    ok_proceed: 'OK, proceed...',
    erase_account_action_requires_password: 'Erase account action requires your account\'s password',
    placeholder_your_password: 'Your Password',
    erase_button: 'Erase',
    export_data_for_your_account: 'Export data for your account...',
    account_data_export: 'Account Data Export',
    export_account_explanation: 'Account Data Export action will export all personal data for your account. This includes the following:\n' +
    '\n- Your personal information' +
    '\n- List of your logins records' +
    '\n- List of your subscriptions' +
    '\n- List of your payments' +
    '\n- List of your accesses to premium content\n' +
    '\nThe export will be sent to your email address as attachment.',
    this_action_requires_password: 'This action requires your account\'s password',
    export_button: 'Export',
    your_account_data_is_being_exported: 'Your account data is being exported. It will be sent to your email address.',
    your_account_has_been_successfully_erased: 'Your account has been erased successfully.',
    used_trial_period: 'It looks like you have already used your free-trial period for this asset. You are not eligible for the free-trial period again.'
};