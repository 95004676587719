module.exports = {
	console_element_doesnt_exist: 'L\'élément avec id "%s" n\'existe pas.',
	console_saved_cards_not_implemented: 'Le paiement avec des cartes enregistrées n\’est pas encore opérationnel.',
	console_form_element_not_found: 'Elément formulaire non trouvé, id: %s',
	console_body_element_not_found: 'Elément contenu non trouvé, id: %s',
	console_method_doesnt_exist: 'La méthode %s n\'existe pas.',
	console_asset_no_access_fees: 'Cet article n\'a pas de frais d\'accès.',
	global_ise_pls_try_again: 'Erreur interne serveur. Veuillez essayer à nouveau.',
	message_full_name: 'Le nom complet ne devrait pas être vide.',
	message_email: 'L\'email ne devrait pas être vide.',
    message_username: 'L\'email ne devrait pas être vide.',
	message_password: 'Le mot de passe ne devrait pas être vide.',
	message_password_confirmation: 'La confirmation mot de passe doit correspondre avec la saisie du mot de passe.',
	message_number: 'Le numéro de carte n\'est pas valide.',
	message_card_expiry: 'La date d\'expiration n\'est pas valide.',
	message_cvv: 'Le cvv n\'est pas valide.',
	message_card_name: 'Le nom carte ne devrait pas être vide.',
	message_unauthorized: 'Jeton invalide. Veuillez reconnecter à nouveau.',
	subs_no_active_subscriptions: 'Pas d\'abonnement actif',
	subs_created_on: 'Créé le',
	subs_next_billing_on: 'Prochaine facturation le',
	subs_status: 'Statut',
	paywall_free_trial_text: 'Payer pour cet article créera un abonnement avec %s d\'essai gratuit. Vous serez facturé %s %s après l\'expiration de la période d\'essai. Vous pouvez toujours annuler votre abonnement avant que la période d\'essai gratuite se termine.',
	placeholder_email_address: 'Adresse email',
	placeholder_password: 'Mot de passe',
	placeholder_token: 'Jeton',
	placeholder_repeat_password: 'Répéter Mot de passe',
	placeholder_name_on_card: 'Nom sur la carte',
	placeholder_voucher_code: 'code coupon',
	placeholder_full_name: 'Nom complet',
    placeholder_first_name: 'Prénom',
    placeholder_surname: 'Nom de famille',
	placeholder_mm_yyyy: 'MM / AAAA',
	tooltip_forgot_password: 'mot de passe oublié',
	tooltip_cancel_subscription: 'Annuler abonnement',
	modal_enter_your_email: 'Entrez votre adresse email.',
	modal_submit: 'Soumettre',
	modal_back: 'retour',
	modal_login: 's\'identifier',
	modal_sign_up: 's\'inscrire',
	modal_register_capital: 'ENREGISTRER',
	modal_login_capital: 'S\'IDENTIFIER',
	modal_choose_price: 'CHOISIR PRIX',
	modal_payment_details: 'DETAILS PAIEMENT',
	modal_login_to_your_account: 'Se connecter à votre compte <%brand_name%>',
	modal_dont_have_one: 'Pas de compte?',
	modal_register_here: 'S\'inscrire ici',
	modal_enter_your_new_password: 'Entrez votre nouveau mot de passe.',
	modal_please_check_your_email: 'Veuillez vérifier votre email et copier le jeton de mot de passe oublié.',
	modal_resend_token: 'renvoyer jeton',
	modal_all_rights_reserved: 'Tous droits réservés.',
	modal_terms: 'Termes',
	ladda_log_in: 'S\’identifier',
	ladda_apply: 'Appliquer',
	ladda_next: 'Suivant',
	ladda_create_account: 'créer compte',
	account_your_subscriptions: 'Vos Abonnements',
	payment_pls_enter_your_card: 'Veuillez entrer votre carte',
	payment_have_a_promotional_code: 'Avez-vous un code promotionnel?',
	payment_im_paying_for: 'Je paye pour',
	payment_price: 'Prix',
	payment_pay: 'Payer',
	payment_add: 'Ajouter',
	preview_protected_by_inplayer: 'Protégé par InPlayer',
	preview_this_premium_content: 'Ce contenu premium requiert un compte pour y accéder.',
	preview_already_have_access: 'Avez vous déjà accès?',
	preview_login_here: 'S\'identifier ici avec votre compte <%brand_name%>',
	prices_pls_select_price: 'Veuillez sélectionner l\'option prix',
	register_register_new_account: 'Enregistrer un nouveau compte <%brand_name%>',
	register_by_clicking_agree: 'En cliquant sur le bouton "Créer Compte" ci-dessous j\'accepte le <%register_terms_of_service%> et <%register_privacy_policy%>.',
	register_terms_of_service: 'Conditions de Service',
	register_privacy_policy: 'Politique de Confidentialité',
	register_already_have_account: 'Avez vous déjà un compte?',
	register_login_here: 'S\'identifier ici',
    complete_registration_with_social_login: '',
	saved_cards_pay_with_saved: 'Payer avec une de vos cartes déjà enregistrées?',
	saved_cards_or_enter_new_card: 'Ou entrer une nouvelle carte ici',
	saved_cards_card_number: 'Numéro de carte',
	saved_cards_item_ordered: 'Article commandé',
	saved_cards_go_back: 'retourner en Arrière',
	user_menu_use_different: 'Utiliser un compte différent',
	user_menu_my_account: 'Abonnements...',
	user_menu_log_out: 'Déconnecter',
	dlc_download: 'Télécharger',
	code: 'fr',
	lang_macedonian: 'Macédonienne',
	lang_english: 'Anglaise',
	lang_spanish: 'L\'Espagnol',
	lang_polish: 'Polonaise',
	lang_french: 'Française',
	lang_swedish: 'Suédoise',
	select_country: 'Sélectionner le pays.',
	card_payment: 'Paiement par carte',
	paypal_payment: 'Paiement PayPal',
	card_management: 'Cartes',
	subscriptions: 'Abonnements',
	sales_currently_not_available: 'Les ventes actuellement indisponibles',
	account_management: 'Gestion de compte',
	access_code: 'Code d\'accès',
    enter_your_access_code: 'Entrez votre code d\'accès'
};