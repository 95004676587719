var $ = require('jquery'),
    unescape = require('lodash/unescape'),
    templates = require('../../templates.js'),
    utils = require('../../utils.js');

module.exports = {
    inject: function(element, asset, success){
        try {
            var content = JSON.parse(unescape(asset.content));

            content.width = content.width || 560;
            content.height = content.height || 315;

            utils.loadScripts([
                { src: 'https://cdnjs.cloudflare.com/ajax/libs/postscribe/2.0.8/postscribe.min.js', type: 'script' }
            ], function () {
                element.empty();
                postscribe(element, '<script src="https://cdnapisec.kaltura.com/p/'+ content.partner_id +'/sp/'+ content.partner_id +'00/embedIframeJs/uiconf_id/'+ content.uiconfig_id +'/partner_id/'+ content.partner_id +'?autoembed=true&entry_id='+ content.entry_id +'&playerId=kaltura_player_'+ content.partner_id + content.entry_id +'&cache_st='+ content.partner_id + content.entry_id +'&width='+ content.width +'&height='+ content.height +'&flashvars[streamerType]=auto"></script>');
                $.isFunction(success) && success();
            });
        } catch(e) {
            throw new Error(e);
        }
    }
};