'use strict';

var $ = require('jquery'),
    Mustache = require('mustache'),
    qs = require('qs'),
    lang = require('./language.js'),
    error = lang.error;

var self;

module.exports = self = {
    htmlDecode: function (input) {
        var e = $('<div></div>').html(input);
        return e[0].childNodes.length === 0 ? "" : e[0].childNodes[0].nodeValue;
    },
    loadScripts: function (array, callback) {
        var self = this;
        var loader = function(obj, handler) {
            if (!obj || self.isLoaded(obj)){
                handler();
                return;
            }

            if (obj.type === 'script'){
                $.getScript(obj.src).then(handler);
            } else if (obj.type === 'link'){
                var elem = $('<link>', {
                    type: 'text/css',
                    rel: 'stylesheet',
                    href: obj.src
                });

                var e = elem[0];
                e.onload = e.onreadystatechange = function () {
                    e.onreadystatechange = e.onload = null;
                    handler();
                };

                elem.appendTo('head');
            }
        };

        (function load() {
            if(array.length > 0) {
                loader(array.shift(), load);
            } else {
                callback && callback();
            }
        })();
    },
    isLoaded: function(obj){
        var type = obj.type;
        var src = obj.src;
        var prop = type === 'link' ? 'href' : 'src';
        var jqSelector = type + '[' + prop + '$="' + src + '"]';
        var found = $(jqSelector);

        return found.length > 0;
    },
    extract: function(from, props, spinalCase){
        var out = {},
            self = this;

        $.each(props, function(i, key, newKey){
            newKey = spinalCase ? self.toSpinalCase(key) : key;
            if (typeof i === 'string'){
                key = i;
            }
            
            if (from.hasOwnProperty(key)){
                var val = self.timestampToDate(from[key]) || from[key];
                out[newKey] = val;
            }
        });

        return out;
    },
    toSpinalCase: function(str){
        return str.replace(/_/g, '-');
    },
    dataSelector: function(name, val){
        return '[data-' + this.toSpinalCase(name) + '="' + val + '"]';
    },
    timestampToDate: function(timestamp){
        if (typeof timestamp === 'number'){
            var date = new Date(timestamp * 1000);

            if (date.getTime() > 0){
                return date.toLocaleDateString();
            }
        }
    },
    getElement: function(assetId){
        var element = $('#inplayer-' + assetId);
        if (!element.length){
            var selector = '[inplayer-id="' + assetId + '"]';
            element = $(selector);

            if (!element.length){
                error('console_element_doesnt_exist_2', [assetId, assetId]);
            }

            return element;
        }

        return element;
    },
    cloneObject: function(obj){
        return JSON.parse(JSON.stringify(obj));
    },
    isFreemium: function(fees){
        return fees.length === 1 && fees[0].access_type && fees[0].access_type.name === 'freemium';
    },
    parseHash: function(options, cb) {
        var parsedQs;
        var err = {};

        if (!cb && typeof options === 'function') {
            cb = options;
            options = {};
        } else {
            options = options || {};
        }

        var _window = global.window;

        var hashStr = options.hash === undefined ? _window.location.hash : options.hash;
        hashStr = hashStr.replace(/^#?\/?/, '');

        parsedQs = qs.parse(hashStr);

        if (parsedQs.hasOwnProperty('reset-password')){
            return cb(null, {
                'reset-password': ''
            });
        }

        if (parsedQs.hasOwnProperty('error')) {
            err.state = parsedQs.state || parsedQs.error;

            return cb(err);
        }

        if (!parsedQs.hasOwnProperty('token')) {
            return cb(null, null);
        }

        return cb(null, {
            token: parsedQs.token,
            expires: parsedQs.expires,
            refresh_token: parsedQs.refresh_token
        });
    },
    removeHash: function(paramsToRemove) {
        var hashParams = window.location.hash.substr(1).split('&');

        var filtered = hashParams.filter(function(el){
            var key = el.split('=')[0];

            return paramsToRemove.indexOf(key) === -1;
        });

        if (hashParams.length === filtered.length){
            return;
        }

        var remainingHash = filtered.length ? '#' + filtered.join('&') : '';
        var queryParams = location.search;

        history.pushState('', document.title, location.pathname + queryParams + remainingHash);
    },
    randomString: function (length) {
        var bytes = new Uint8Array(length);
        var random = window.crypto.getRandomValues(bytes);
        var result = [];
        var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
        random.forEach(function (c) {
            result.push(charset[c % charset.length]);
        });
        return result.join('');
    },
    getQueryParamsObject: function(locat, paramsToClear) {
        paramsToClear = paramsToClear || [];

        var vars = {},
            remainingSearch = [],
            queryString = locat.search.substr(1),
            pairs = queryString ? queryString.split('&') : null;

        if (!pairs){
            return null;
        }

        $.each(pairs, function(i, pair){
            var split = pair.split('='),
                key = split[0],
                val = split[1];

            vars[key] = val;

            if (paramsToClear.indexOf(key) === -1){
                remainingSearch.push(pair);
            }
        });

        remainingSearch = remainingSearch.length ? '?' + remainingSearch.join('&') : '';

        history.pushState('', document.title, location.pathname + remainingSearch + location.hash);

        return vars;
    },
    buildUrlQuery: function(locat, params){
        var url = locat.href.split('#')[0],
            params = (locat.search ? '&' : '?') + $.param(params),
            result = url + params + locat.hash;

        return result;
    },
    is_iOS: function() {
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)){
            return true;
        }

        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream){
            return true;
        }

        return false;
    },
    getTimestamp: function(data){
        var now = Math.round(new Date().getTime() / 1000);

        if (!data){
            return now;
        }

        var days = data.days || 0,
            hours = data.hours || 0,
            minutes = data.minutes || 0,
            seconds = data.seconds || 0,

            expiry = now + (days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60) + seconds;

        return expiry;
    },
    getReferrer: function(){
        var loc = window.top.location,
            url = loc.protocol + '//' + loc.host + loc.pathname;

        return url.substring(0, 249);
    },
    lightenColor: function(colorCode, amount) {
        var usePound = false;

        if (colorCode[0] == "#") {
            colorCode = colorCode.slice(1);
            usePound = true;
        }

        var num = parseInt(colorCode, 16);

        var r = (num >> 16) + amount;

        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }

        var b = ((num >> 8) & 0x00FF) + amount;

        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
        }

        var g = (num & 0x0000FF) + amount;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    }
};
