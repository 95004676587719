'use strict';

// require('whatwg-fetch');

var Cookies = require('js-cookie')
    , $ = require('jquery')
    , localStorage = require('./local-storage-wrap')
    , config = require('./config')
    // , Promise = require('promise-polyfill')
    , lang = require('./language.js')
    , events = require('./global-events.js')
    , utils = require('./utils')
    , fees = {}
    , savedCards = []
    , watchers = []
    , saved = {}
    , INPLAYER_TOKEN_NAME = 'inplayer_token'
    , INPLAYER_TOKEN_HEADER = 'X-Inplayer-Token'
    , TOKEN_VALIDITY_DAYS = 60
    , NOTIF_PREFIX_LOCAL = 'inplayer_notif_'
    , state = require('./state')
    , instances = require('./instances')
    , merchantUuid = null
    , erasureInitiated = false
    , sso = (function(){
        var _sso;
        return function(){
            _sso = _sso || require('./sso');
            return _sso;
        };
    })()
    ;

// To add to window
// if (!window.Promise) {
//     window.Promise = Promise;
// }

var self = module.exports = (function(methods, fnsToWrap){
    methods = methods || {};

    $.each(fnsToWrap, function(name, fn){
        methods[name] = function(){
            var args = arguments,
                onNoToken = null;

            if (args[4] && $.isPlainObject(args[4])){
                merchantUuid = args[4].merchantUuid || null;
                onNoToken = args[4].onNoToken || null;
            }

            return reconfirmSession()
                .then(function(res){
                    if (sso().isEnabled()){
                        if (res && res.access_token){
                            sso().sendToken(res.access_token);
                        }
                    }

                    fn.apply(methods, args);
                })
                .catch(function(res){
                    events.global.one('invalid_auth_token', function(){
                        methods.logout(function(){
                            self.removeTokens();

                            if (onNoToken){
                                onNoToken();
                            }
                            else {
                                events.global.trigger('no_token');
                            }

                            fn.apply(methods, args);
                        });
                    });

                    if (sso().isEnabled()){
                        sso().checkForSession(null, state.options.oauthAppKey || state.merchant_uuid, function(){
                            events.global.off('invalid_auth_token');
                            fn.apply(methods, args);
                        }, function(){
                            events.global.trigger('invalid_auth_token');
                        });
                    }
                    else {
                        events.global.trigger('invalid_auth_token');
                    }
                });
        };
    });

    return methods;
})(
// non-wrapped methods
{
    onLogout: function(cb) {
        watchers.push(cb);
    },
    saveTokens: function(res){
        res.expires = res.expires || utils.getTimestamp({
            days: TOKEN_VALIDITY_DAYS
        });

        localStorage.setItem('access_token', res.access_token || res.token);
        localStorage.setItem('expires', res.expires);

        if (res.hasOwnProperty('refresh_token')) {
            localStorage.setItem('refresh_token', res.refresh_token);
        }
    },
    removeTokens: function(tokenNames){
        if (!tokenNames) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('expires');
            localStorage.removeItem('refresh_token');

            return;
        }

        $.each(tokenNames, function(i, name){
            localStorage.removeItem(name);
        });
    },
    token: function() {
        if (Cookies.get(INPLAYER_TOKEN_NAME)){
            if (!localStorage.getItem('access_token')){
                this.saveTokens({
                    access_token: Cookies.get(INPLAYER_TOKEN_NAME)
                });
            }

            Cookies.remove(INPLAYER_TOKEN_NAME);
        }

        return localStorage.getItem('access_token');
    },
    isLoggedIn: function() {
        return !!this.token();
    },
    findOneAsset: function(id, merchantUUID, success, fail) {
        return fetch(config.api_url + '/items/' + merchantUUID + '/' + id)
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    findFromExternalId: function(assetType, externalId, merchantUuid, success, fail, ignoreSaved){
        var cache = saved.findFromExternalId = saved.findFromExternalId || {},
            byAssetType = cache[assetType] = cache[assetType] || {};

        if (ignoreSaved || !byAssetType[externalId]) {
            return fetch(config.api_url + '/items/assets/external/' + assetType + '/' + externalId + '?' + $.param({
                merchant_uuid: merchantUuid
            }))
                .then(checkStatus)
                .then(function(data){
                    handleSuccess(function(res){
                        byAssetType[externalId] = res;
                        success(res);
                    })(data);
                })
                .catch(handleFail(fail));
        }
        else {
            success(byAssetType[externalId]);
        }
    },
    findOnePackage: function(id, success, fail) {
        return fetch(config.api_url + '/items/packages/' + id)
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    findAccessFeesForAsset: function(id, success, fail) {
        fetch(config.api_url + '/items/' + id + '/access-fees')
            .then(checkStatus)
            .then(function(response) {
                return response.json();
            })
            .then(function(accessFees) {
                fees[id] = accessFees;
                success(accessFees);
            })
            .catch(handleFail(fail));
    },
    getSocialLoginUrls: function (state, success, ignoreSaved) {
        var cache = saved.getSocialLoginUrls = saved.getSocialLoginUrls || {};

        if (ignoreSaved || !cache[state]) {
            fetch(config.api_url + '/accounts/social?state=' + state)
                .then(checkStatus)
                .then(function(data){
                    handleSuccess(function(res){
                        cache[state] = res;
                        success(res);
                    })(data);
                })
                .catch(function(data){
                    handleFail(function(){
                        cache[state] = [];
                    })(data);
                });
        }
        else {
            if (cache[state].social_urls && cache[state].social_urls.length){
                success(cache[state]);
            }
        }
    },
    signIn: function(data, success, fail) {
        fetch(config.api_url + '/accounts/login', {
            method: 'POST',
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .then(function(e){
                events.global.trigger('login', e);
            })
            .catch(handleFail(fail));
    },
    authenticate: function(data, grantType, success, fail){
        var fail = fail || $.noop();

        return fetch(config.api_url + '/accounts/authenticate', {
            method: 'POST',
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(function(data){
                if (grantType === 'password') {
                    events.global.one('account', function(){
                        events.global.trigger('login', data);
                    });
                }

                success(data);
            }))
            .catch(handleFail(fail));
    },
    sendSSOtoken: function(ssoDomain, data){
        return fetch(ssoDomain + '/sso/cookie', {
            method: 'POST',
            body: data,
            credentials: 'include'
        });
    },
    signUp: function(data, success, fail) {
        fetch(config.api_url + '/accounts', {
            method: 'POST',
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    requestForgotPassword: function(data, success, fail) {
        fetch(config.api_url + '/accounts/forgot-password', {
            method: 'POST',
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    logout: function(callback) {
        var token = localStorage.getItem('access_token');
        this.removeTokens();

        removeNotificationKeys();

        saved.account = {
            busy: false,
            token: null,
            data: {}
        };

        fetch(config.api_url + '/accounts/logout', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        executeWatchers(true);

        if (!this.isLoggedIn()) {
            if (token && sso().isEnabled()) {
                sso().retireToken(token);
            }
            
            $.isFunction(callback) && callback(token);

            events.global.trigger('logout');
        }
    },
    getRegisterFields: function(merchantUuid, successOrFail, ignoreSaved){
        var cache = saved.getRegisterFields = saved.getRegisterFields || {};

        if (ignoreSaved || !cache[merchantUuid]) {
            fetch(config.api_url + '/accounts/register-fields/' + merchantUuid)
                .then(function(data) {
                    handleSuccess(function(res) {
                        cache[merchantUuid] = res;
                        successOrFail(res);
                    })(data);
                })
                .catch(handleFail(successOrFail));
        }
        else {
            successOrFail(cache[merchantUuid]);
        }
    },
    getPaymentMethod: function(merchantUuid, success, fail) {
        fetch(config.api_url + '/payments/method/' + merchantUuid)
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    setNewPassword: function(data, token, success, fail) {
        fetch(config.api_url + '/accounts/forgot-password/' + token, {
            method: 'PUT',
            body: $.param(data),
            headers: {
                'Content-Type': 'x-www-form-urlencoded'
            }
        })
            .then(checkStatus)
            .then(success.bind(this, {message: 'Password successfully entered.'}))
            .catch(handleFail(fail));
    },
    getBranding: function(merchantUuid, brandingId, success, fail, ignoreSaved){
        var cache = saved.getBranding = saved.getBranding || {};
        cache[merchantUuid] = cache[merchantUuid] || {};

        if (ignoreSaved || !cache[merchantUuid] || !cache[merchantUuid][brandingId]) {
            fetch(config.api_url + '/branding/paywall/' + merchantUuid + '/' + (brandingId || 'default'))
                .then(checkStatus)
                .then(function(data){
                    handleSuccess(function(res){
                        cache[merchantUuid][brandingId] = res;
                        success({
                            isDefaultBrand: !brandingId,
                            brand: res
                        });
                    })(data);
                })
                .catch(function (data) {
                    if (brandingId) {
                        self.getBranding(merchantUuid, undefined, success, fail, ignoreSaved);
                    }
                    else {
                        handleFail(fail)(data);
                    }
                });
        }
        else {
            success({
                isDefaultBrand: !brandingId,
                brand: cache[merchantUuid][brandingId]
            });
        }
    },
    account: function(token, success, fail, instructions, ignoreSaved) {
        var args = arguments,
            cache = saved.account = saved.account || {
                busy: false,
                token: null,
                data: {}
            };

        token = self.token();

        if (cache.busy){
            events.global.one('unbusy', function(){
                self.account.apply(self, args);
            });

            return;
        }

        if (ignoreSaved || cache.token !== token) {
            cache.busy = true;

            fetch(config.api_url + '/accounts', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(function (res) {
                    if (instructions && instructions.dontCheckStatus) {
                        return res;
                    }

                    return checkStatus(res);
                })
                .then(function(data) {
                    handleSuccess(function(res) {
                        cache.token = token;
                        cache.data = res;
                        cache.busy = false;

                        success(res);
                        events.global.trigger('unbusy');
                    })(data);
                })
                .catch(function(data) {
                    handleFail(function(res) {
                        cache.token = null;
                        cache.data = {};
                        cache.busy = false;

                        fail(res);
                        events.global.trigger('unbusy');
                    })(data);
                });
        }
        else {
            success(cache.data);
        }
    },
    sendAccessCode: function(data, success, fail){
        fetch(config.api_url + '/items/access/codes', {
            method: 'POST',
            body: data
        })
            .then(checkStatus({
                dontExecuteWatchers: true
            }))
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    cancelAccessCode: function(code, formdata, success, fail){
        fetch(config.api_url + '/items/access/codes/' + code, {
            method: 'DELETE',
            body: formdata
        })
            .then(checkStatus({
                dontExecuteWatchers: true
            }))
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    }
},

// wrapped methods
{

    checkForUsedTrial: function(assetId, success, fail){
        var token = this.token();

        fetch(config.api_url + '/items/used-trial-period/' + assetId, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    eraseAccount: function(data, success, fail) {
        var token = this.token();

        this.erasureInitiated = true;

        fetch(config.api_url + '/accounts/erase', {
            method: 'DELETE',
            body: data,
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(success)
            .catch(handleFail(fail));
    },
    exportAccountData: function(data, success, fail){
        var token = this.token();
        
        fetch(config.api_url + '/accounts/export', {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(success)
            .catch(handleFail(fail));
    },
    getNotificationCreds: function(token, success, fail){
        var local = getNotificationKeys();

        if (local){
            return success(local);
        }

        fetch(config.aws_notif, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(handleSuccess(saveNotificationKeys))
            .then(success)
            .catch(handleFail(fail));
    },
    updateAccount: function(data, token, success, fail){
        token = self.token();

        fetch(config.api_url + '/accounts', {
            method: 'PUT',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'x-www-form-urlencoded'
            }
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    changePassword: function(data, token, success, fail) {
        token = self.token();

        fetch(config.api_url + '/accounts/change-password', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(success.bind(this, {message: 'Password successfully entered.'}))
            .catch(handleFail(fail));
    },
    getDlcLinks: function(token, assetId, success, fail) {
        token = self.token();

        return fetch(config.api_url + '/dlc/' + assetId + '/links', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    checkAccessForAsset: function(token, id, success, fail) {
        token = self.token();

        return fetch(config.api_url + '/items/' + id + '/access', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(handleSuccess(function(data){
                success(data);
                events.global.trigger('access', { asset_id: id, external_id: instances.getExternalId(id), hasAccess: true });
            }))
            .catch(handleFail(function(data){
                fail(data);
                events.global.trigger('access', { asset_id: id, external_id: instances.getExternalId(id), hasAccess: false });
            }));
    },
    checkAccessForMultipleAssets: function(token, ids, success, fail) {
        token = self.token();

        return fetch(config.api_url + '/items/access?' + $.param(ids), {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    getPaymentTools: function(token, paymentMethodId, success, fail) {
        token = self.token();

        if (savedCards.length === 0) {
            fetch(config.api_url + '/payments/method/' + paymentMethodId + '/tools', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(checkStatus)
                .then(function(response) {
                    return response.json();
                })
                .then(function(cards) {
                    if ($.isArray(cards) && !cards.length) {
                        fail();
                    }
                    else {
                        savedCards = cards;
                        success(cards);
                    }
                })
                .catch(handleFail(fail));
        }
        else {
            success(savedCards);
        }

    },
    payForAsset: function(token, data, success, fail) {
        token = self.token();

        fetch(config.api_url + '/payments', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    subscribeForAsset: function(token, data, success, fail) {
        token = self.token();

        fetch(config.api_url + '/subscriptions', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail))
            .catch(function() {
                fail({errors: {global: lang.getText('global_ise_pls_try_again')}});
            });
    },
    getPaymentMethods: function(token, success, fail){
        token = self.token();

        fetch(config.api_url + '/payments/methods', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    getPayPalParams: function(token, data, success, fail){
        token = self.token();

        fetch(config.api_url + '/external-payments', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail))
            .catch(function(e){
                console.log(e);
            });
    },
    freemiumAsset: function(token, data, success, fail) {
        token = self.token();

        fetch(config.api_url + '/items/access/unlimited', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail))
            .catch(function() {
                fail({errors: {global: lang.getText('global_ise_pls_try_again')}});
            });
    },
    getDiscount: function(token, data, success, fail) {
        token = self.token();

        fetch(config.api_url + '/vouchers/discount', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: data
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail))
            .catch(function() {
                fail({errors: {global: lang.getText('global_ise_pls_try_again')}});
            });
    },
    getSubscriptions: function(token, success, fail) {
        token = self.token();

        fetch(config.api_url + '/subscriptions', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    cancelSubscription: function(unsubscribe_url, token, success, fail) {
        token = self.token();

        fetch(unsubscribe_url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(handleSuccess(success))
            .catch(handleFail(fail));
    },
    downloadProtectedFile: function(token, assetId, filename, success, fail) {
        token = self.token();

        fetch(config.api_url + '/dlc/' + assetId + '/' + filename, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(checkStatus)
            .then(success)
            .catch(handleFail(fail));
    },
    fetchWpContent: function(url, token, success, fail) {
        token = self.token();

        fetch(url, {
            credentials: 'same-origin'
        })
            .then(checkStatus)
            .then(function(response) {
                return response.text();
            })
            .then(function(body) {
                var err = tryParseJSON(body);
                if (err) {
                    fail(err);
                } else {
                    success(body);
                }
            });
    }
});

function reconfirmSession(fnName){
    return new Promise(function(success, fail){
        var currToken = localStorage.getItem('access_token'),
            refreshToken = localStorage.getItem('refresh_token'),
            expires = localStorage.getItem('expires'),
            now = new Date().getTime() / 1000;

        if (currToken && expires && expires > now) {
            return success();
        }

        if (!refreshToken){
            return fail();
        }

        var data = new FormData();

        data.append('refresh_token', refreshToken);
        data.append('client_id', state.options.oauthAppKey || state.oauth_app_key || state.merchant_uuid || merchantUuid);
        data.append('grant_type', 'refresh_token');

        var brand_id = instances.getBranding(state.current_asset.id).id;
        if (brand_id){
            data.append('branding_id', brand_id);
        }

        self.authenticate(data, 'refresh_token', function(res){
            self.saveTokens(res);
            success(res);
        }, function(){
            self.removeTokens(['refresh_token']);
            fail();
        });
    });
}

function checkStatus(option) {
    var fn = function(response){
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        if (response.status == 401) {
            self.removeTokens();

            if (!option.dontExecuteWatchers) {
                executeWatchers(true);
            }
        }

        var error = new Error(response.statusText);
        error.response = response;
        throw error;
    };

    if (option.dontExecuteWatchers){
        return fn;
    }

    return fn(option);
}

function saveToken(response) {
    var newToken = response.headers.get(INPLAYER_TOKEN_HEADER);
    if (newToken !== null) {
        Cookies.set(INPLAYER_TOKEN_NAME, newToken, {
            expires: 60
        });
        executeWatchers(false);
    }

    return response;
}

function handleSuccess(success) {
    return function(res) {
        return res.json().then(success);
    };
}

function handleFail(fail) {
    return function(err) {
        if (err.response && err.response.json && $.isFunction(err.response.json)){
            return err.response.json().then(fail);
        }

        console.trace(err);

        fail(err);
    };
}

function executeWatchers(flag) {
    $.each(watchers, function(i, cb) {
        cb(flag);
    });
}

function getNotificationKeys(){
    var expiry = localStorage.getItem(NOTIF_PREFIX_LOCAL + 'expires') || 0;

    if (expiry <= utils.getTimestamp()){
        return false;
    }

    var keys = ['accessKey', 'iotEndpoint', 'region', 'secretKey', 'sessionToken'],
        res = {};

    $.each(keys, function(i, key){
        var val = localStorage.getItem(NOTIF_PREFIX_LOCAL + key);

        if (!val){
            res = false;
            return false;
        }

        res[key] = val;
    });

    return res;
}

function removeNotificationKeys(){
    $.each(['accessKey', 'iotEndpoint', 'region', 'secretKey', 'sessionToken', 'expires'], function(i, key){
        localStorage.removeItem(NOTIF_PREFIX_LOCAL + key);
    });
}

function saveNotificationKeys(data){
    $.each(data, function(key, val){
        localStorage.setItem(NOTIF_PREFIX_LOCAL + key, val);
    });

    localStorage.setItem(NOTIF_PREFIX_LOCAL + 'expires', utils.getTimestamp({
        minutes: 59
    }));

    return data;
}

function tryParseJSON(jsonString) {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns 'null', and typeof null === "object",
        // so we must check for that, too.
        if (o && typeof o === "object" && o !== null) {
            return o;
        }
    }
    catch (e) {
    }

    return false;
}