'use strict';

var $ = require('jquery')
    , templates = require('./templates.js')
    , resources = require('./inplayer-api.js')
    , Forms = require('./forms.js')
    , utils = require('./utils')
    , Mustache = require('mustache')
    , modalIsShown = false
    , lang = require('./language.js')
    , error = lang.error
    , events = require('./global-events')
    , instances = require('./instances')
    , state = require('./state')
    ;

var classes = {
    close_modal: 'inplayer-close-modal',
    steps: 'inplayer-steps',
    firstname_surname: 'inplayer-firstname_surname',
    custom_fields: 'inplayer-custom-fields',
    paypal: 'inplayer-paypal'
};

var ids = {
    user_menu_wrapper: 'inplayer-menu-modal',
    modal: 'inplayer-modal',
    modal_wrapper: 'inplayer-modal-wrapper',
    modal_body: 'inplayer-modal-body',
    form: 'inplayer-form'
};

var self, scrollPos;

module.exports = self = (function(){
    var self = {
        open: function (name, state, callback, hideProgressBar, moreView) {
            Forms.stopLoading();

            var view = $.extend({
                user_menu: Mustache.render(templates.user_menu, lang.getText()),
                language_menu: Mustache.render(templates.language_menu, lang.getText()),
                register_fields: Mustache.render(templates.register_fields, lang.getText()),
                code_access: state.current_asset.access_control_type && state.current_asset.access_control_type.name === 'Code',
                disable_vouchers: function(){
                    return state.access_fee.setup_fee && state.access_fee.trial_period;
                }
            }, instances.getBranding(state.current_asset.id), lang.getText());

            if (moreView){
                $.extend(view, moreView);
            }

            if (!modalIsShown) {
                self._getRootElement().html(Mustache.render(templates.modal, view));
                modalIsShown = true;
            }

            var progressBar = self._getRootElement().find('.inplayer-progressbar-wrapper');
            progressBar[!!hideProgressBar ? 'hide' : 'show']();

            self._getBodyElement().html(Mustache.render(templates[name], view));

            if (utils.is_iOS()){
                scrollPos = $('html').first().scrollTop();
                self._getRootElement().find('.inplayer-overlay').addClass('inplayer-ios');
            }

            $('body').addClass('inplayer-custom-body');

            if (['register', 'complete_registration', 'account_details'].indexOf(name) >= 0) {
                var regFields = !$.isEmptyObject(state.registerFields) ? state.registerFields : !$.isEmptyObject(state.options.customDataFields) ? state.options.customDataFields : null;

                if (regFields) {
                    addCustomDataFields(regFields);
                }
            }

            var modalWrapper = $('#' + ids.modal_wrapper);
            modalWrapper.removeClass().addClass(ids.modal + ' inplayer-' + name);

            self.handleDataHTML(modalWrapper.find('[data-html]'));
            lang.init();

            self.getForm().on('submit', function(e){
                e.preventDefault();

                if (Forms.isLoading()) {
                    return;
                }

                var submitBtn = $(e.target).find('button[type="submit"]');
                if (submitBtn.hasClass(classes.paypal)){
                    if (state.paypal.endpoint) {
                        window.open(state.paypal.endpoint, '_blank');
                    }
                }else{
                    Forms.startLoading(Forms.buttons[name]);
                    Forms.dispatch(name, e, state);
                }
            });

            callback && callback();

            addCloseModalEvent(self);
            self.updateProgressBar(state.step);

            events.global.trigger('modal_' + name);
        },

        close: function () {
            Forms.stopLoading();
            state.current_asset = {};

            self._getRootElement().children().first().remove();
            $('body').removeClass('inplayer-custom-body');

            if (scrollPos) {
                $('html').first().scrollTop(scrollPos);
            }

            modalIsShown = false;
        },

        updateProgressBar: function (step) {
            if (!step){
                return;
            }

            $('.' + classes.steps).first().attr({
                id: 'inplayer-step-' + step
            });
        },

        isShown: function () {
            return modalIsShown;
        },

        getForm: function () {
            var form = $('#' + ids.form);

            if (!form.length) {
                error('console_form_element_not_found', ids.form);
            }

            return form;
        },

        _getBodyElement: function () {
            var body = $('#' + ids.modal_body);
            if (!body.length) {
                error('console_body_element_not_found', ids.modal_body);
            }

            return body;
        },

        _getRootElement: function () {
            var el = $('#' + ids.modal);
            if (!el.length) {
                el = $('<div></div>', {
                    id: ids.modal
                })
                    .addClass('inplayer-always-visible')
                    .appendTo('body');
            }

            return el;
        },

        handleDataHTML: function(els){
            if (!els.length){
                return;
            }

            lang.dataHtml(els);
        }
    };

    return self;
})();

var addInput = {
    text: function(props){
        props.placeholder = props.placeholder || props.label || '';

        var firstOrSurName = props.name === 'first_name' || props.name === 'surname',
            contClass = firstOrSurName ? classes.firstname_surname : classes.custom_fields,
            $body = self._getBodyElement(),
            cont = $body.find('.' + contClass),
            input = $('<input>').prop({
                type: "text",
                name: props.meta_name,
                placeholder: props.placeholder,
                required: props.required,
                value: props.default_value || ''
            }).appendTo(cont);

        if (firstOrSurName) {
            var full_name_box = $body.find('div[name="full_name"]');
            full_name_box.hide();

            var dataPlaceholder = 'placeholder_' + props.name;
            input.attr('data-placeholder', dataPlaceholder);
            input.attr('placeholder', lang.getText(dataPlaceholder));

            input.on('input propertychange paste', function(e, first_name, surname){
                var first_name_box = $body.find('input[name="metadata[first_name]"]');
                var surname_box = $body.find('input[name="metadata[surname]"]');

                first_name = first_name_box.val ? first_name_box.val() || '' : '';
                surname = surname_box.val ? surname_box.val() || '' : '';
                full_name_box.children('input').val((first_name + ' ' + surname).trim());
            });
        }
    },
    checkbox: function(props){
        var cont = self._getBodyElement().find('.' + classes.custom_fields);

        props.label = props.label || props.placeholder || '';

        var id = 'inplayer-custom-' + props.name;
        var div = $('<div></div>').css({
            padding: '15px 0px 0px 0px'
        });
        var checked = props.default_value === true || props.default_value === 'true';
        var checkbox = $('<input>').prop({
            type: 'checkbox',
            id: id,
            name: props.meta_name,
            checked: checked
        });

        if (props.required){
            checkbox.prop('required', true);
        }

        var label = $('<label>' + props.label + '</label>').prop('for', id);

        div.append(checkbox).append(label).appendTo(cont);
    },
    country: function(props){
        var cont = self._getBodyElement().find('.' + classes.custom_fields);
        var opts = $.extend({
            required: props.required
        }, lang.getText());
        cont.append(Mustache.render(templates.countries, opts));
    },
    dropdown: function(props){
        var cont = self._getBodyElement().find('.' + classes.custom_fields);
        var id = 'inplayer-custom-' + props.name;
        var meta_name = props.meta_name;
        var defaultValue = props.default || props.default_value;
        
        var select = $('<select>')
            .addClass('inplayer-custom-dropdown')
            .prop({
                name: meta_name,
                required: props.required === true
            });

        var label = props.label || props.placeholder;

        if (label){
            $('<option></option>')
                .val('')
                .prop({
                    selected: !props.default,
                    disabled: true
                })
                .text(label)
                .appendTo(select);
        }

        $.each(props.options, function(i, option){
            $('<option></option>')
                .val(option.value || i)
                .prop('selected', defaultValue === i)
                .text(option.label || option)
                .appendTo(select);
        });

        select.appendTo(cont);
    },
    radio: function(props){
        var cont = self._getBodyElement().find('.' + classes.custom_fields);
        var id = 'inplayer-custom-' + props.name;
        var meta_name = props.meta_name;

        var groupHolder = $('<div></div>').prop({ id: id }).css({
            padding: '15px 0px'
        });

        if (props.label) {
            groupHolder.append($('<label></label>').html(props.label + '<br>'));
        }

        var index = 0;
        $.each(props.options, function(i, radio){
            var btn_id = id + '-' + i,
                value = radio.value || i,
                label = radio.label || radio;

            var btn = $('<input>').prop({
                type: 'radio',
                id: btn_id,
                name: meta_name,
                value: value,
                required: props.required,
                checked: value === props.default_value
            });

            if (index++ === 0){
                btn.css({
                    marginTop: '10px'
                });
            }

            var label = $('<label></label>')
                .prop({
                    for: btn_id
                })
                .html(label)
                .css({
                    paddingRight: '12px'
                });

            groupHolder
                .append(btn)
                .append(label);
        });

        groupHolder.appendTo(cont);
    }
};

function addCustomDataFields(registerFields){
    var fields = utils.cloneObject(registerFields);

    $.each(fields, function(i, field, $el){
        if ($.isEmptyObject(field) || !field.name){
            return;
        }

        field.type = field.type === 'input' ? 'text' : field.type === 'select' ? 'dropdown' : field.type || 'text';
        field.meta_name = 'metadata[' + field.name + ']';

        if (!addInput[field.type]){
            return;
        }
        
        addInput[field.type](field);
    });
}

function addCloseModalEvent(modal) {
    self._getRootElement().find('.' + classes.close_modal).on('click', modal.close);
}
