module.exports = {
	console_element_doesnt_exist: 'El elemento con NI "%s" no existe.',
	console_saved_cards_not_implemented: 'Aún no se ha implementado el pago con tarjetas guardadas.',
	console_form_element_not_found: 'Formulario no encontrado, NI: %s',
	console_body_element_not_found: 'Cuerpo no encontrado, NI: %s',
	console_method_doesnt_exist: 'Método %s inexistente.',
	console_asset_no_access_fees: 'Este activo no tiene tarifa de acceso.',
	global_ise_pls_try_again: 'Error interno del servidor. Inténtelo de nuevo.',
	message_full_name: 'El campo de nombre completo no se puede dejar vacío.',
	message_email: 'El campo de correo electrónico no se puede dejar vacío.',
    message_username: 'El campo de correo electrónico no se puede dejar vacío.',
	message_password: 'El campo de la contraseña no se puede dejar vacío.',
	message_password_confirmation: 'La contraseña de confirmación debe coincidir con la contraseña inicial.',
	message_number: 'El número de la tarjeta no es válido.',
	message_card_expiry: 'La fecha de caducidad de la tarjeta no es válida.',
	message_cvv: 'El CVV no es válido.',
	message_card_name: 'El campo de número de la tarjeta no se puede dejar vacío.',
	message_unauthorized: 'Token no válido. Inicie sesión de nuevo.',
	subs_no_active_subscriptions: 'No hay suscripciones activas',
	subs_created_on: 'Creada el',
	subs_next_billing_on: 'Próxima facturación el',
	subs_status: 'Estado',
	paywall_free_trial_text: 'Pagar por este activo creará una suscripción al periodo de prueba de %s. Al finalizar el periodo de prueba se le cobrará %s %s. Siempre puede cancelar su suscripción antes de que termine el periodo de prueba.',
	paywall_full_discount: '100% de descuento',
	placeholder_email_address: 'Dirección de correo electrónico',
	placeholder_password: 'Contraseña',
	placeholder_repeat_password: 'Repetir contraseña',
	placeholder_name_on_card: 'Nombre del titular de la tarjeta',
	placeholder_voucher_code: 'Código promocional',
	placeholder_full_name: 'Nombre completo',
    placeholder_first_name: 'Nombre',
    placeholder_surname: 'Apellido',
	placeholder_mm_yyyy: 'MM / AAAA',
	tooltip_forgot_password: 'Olvidé la contraseña',
	tooltip_cancel_subscription: 'Cancelar suscripción',
	modal_enter_your_email: 'Introduzca su correo electrónico.',
	modal_submit: 'Enviar',
	modal_back: 'Volver',
	modal_login: 'Iniciar sesión',
	modal_sign_up: 'Inscribirse',
	modal_register_capital: 'REGISTRARSE',
	modal_login_capital: 'INICIAR SESIÓN',
	modal_choose_price: 'ELEGIR PRECIO',
	modal_payment_details: 'DETALLES DEL PAGO',
	modal_login_to_your_account: 'Iniciar sesión en su cuenta de <%brand_name%>',
	modal_dont_have_one: '¿No tiene una?',
	modal_register_here: 'Regístrese aquí',
	modal_enter_your_new_password: 'Introduzca su nueva contraseña.',
	modal_please_check_your_email: 'Compruebe su correo electrónico y copie el token de contraseña olvidada.',
	modal_resend_token: 'Reenviar token',
	modal_all_rights_reserved: 'Todos los derechos reservados.',
	modal_terms: 'Términos',
	modal_faq: 'Preguntas frecuentes',
	ladda_log_in: 'Iniciar sesión',
	ladda_apply: 'Aplicar',
	ladda_next: 'Siguiente',
	ladda_create_account: 'Crear una cuenta',
	account_your_subscriptions: 'Sus suscripciones',
	payment_pls_enter_your_card: 'Introduzca los datos de su tarjeta',
	payment_have_a_promotional_code: '¿Tiene un código promocional?',
	payment_im_paying_for: 'Pago por',
	payment_price: 'Precio',
	payment_pay: 'Pagar',
	preview_protected_by_inplayer: 'Protegido por InPlayer',
	preview_this_premium_content: 'Para acceder al contenido premium se necesita tener una cuenta.',
	preview_already_have_access: '¿Ya tiene acceso?',
	preview_login_here: 'Inicie sesión con su cuenta de <%brand_name%> aquí',
	prices_pls_select_price: 'Seleccione el precio que prefiere',
	register_register_new_account: 'Registrar una nueva cuenta de <%brand_name%> ',
	register_by_clicking_agree: 'Al pinchar en el botón de "Crear cuenta" que aparece a continuación acepto los <%register_terms_of_service%> y la <%register_privacy_policy%>.',
	register_terms_of_service: 'Términos del servicio',
	register_privacy_policy: 'Política de privacidad',
	register_already_have_account: '¿Ya tiene cuenta?',
	register_login_here: 'Inicie sesión aquí',
    complete_registration_with_social_login: '',
	saved_cards_pay_with_saved: '¿Quiere pagar con una de las tarjetas guardadas?',
	saved_cards_or_enter_new_card: 'O introduzca una nueva aquí',
	saved_cards_card_number: 'Número de tarjeta',
	saved_cards_item_ordered: 'Artículo pedido',
	saved_cards_go_back: 'Volver',
	user_menu_use_different: 'Usar una cuenta diferente',
	user_menu_my_account: 'Suscripciones...',
	user_menu_log_out: 'Cerrar sesión',
	dlc_download: 'Descargar',
	code: 'es',
	lang_macedonian: 'Macedonio',
	lang_english: 'Inglés',
	lang_spanish: 'Español',
	lang_polish: 'Polaco',
	lang_french: 'Francés',
	lang_swedish: 'Sueco',
	select_country: 'Seleccionar país.',
	card_payment: 'Pago con tarjeta',
	paypal_payment: 'Pago PayPal',
	card_management: 'Divertido Tarjetas',
	subscriptions: 'Suscripciones',
	sales_currently_not_available: 'Ventas actualmente no disponibles',
	account_management: 'Administración de cuentas'
};