'use strict';

var utils = require('../../utils.js');

module.exports = {
	inject: function (element, asset, success) {
		element.empty();
		utils.loadScripts([{
			src: 'https://player.piksel.com/p/' + asset.content + '?embed=js', type: 'script'
		}]);
		success();
	}
};