module.exports = {
	console_element_doesnt_exist: 'L\’elemento con id "%s" non esiste.',
	console_element_doesnt_exist_2: 'L\’elemento con id "inplayer-%s" o attributo "inplayer-id" di valore "%s" non esiste.',
	console_saved_cards_not_implemented: 'Il pagmaneto con la carta salvata non è ancora stato implementato.',
	console_form_element_not_found: 'Form non trovato, id: %s',
	console_body_element_not_found: 'Body non trovato, id: %s',
	console_method_doesnt_exist: 'Il medoto %s non esiste.',
	console_asset_no_access_fees: 'Questa attività non ha costi di accesso.',
	global_ise_pls_try_again: 'Errore nel server. La preghiamo di riprovare di nuovo.',
	message_full_name: 'Il campo del nome non deve essere vuoto.',
	message_email: 'Il campo dell\’email non deve essere vuoto.',
    message_username: 'Il campo dell\’email non deve essere vuoto.',
	message_password: 'Il campo della password non deve essere vuota.',
	message_password_confirmation: 'La password di conferma deve essere uguale a quella inserita.',
	message_number: 'Il numero della carta non è valido.',
	message_card_expiry: 'La data di scadenza della carta non è valida.',
	message_cvv: 'Il cvv non è valido.',
	message_card_name: 'Il campo del nome della carda non deve essere vuoto.',
	message_unauthorized: 'Token non valido. Fai il login di nuovo.',
	subs_no_active_subscriptions: 'Nessun abbonamento attivo',
	subs_created_on: 'Creato il',
	subs_next_billing_on: 'Il prossimo accredito avverà il',
	paywall_free_trial_text: 'Pagando per questa configurazione creerai un abbonamento con un periodo di prova di %s . Ti verrà addebitato %s %s al termine di questo periodo. Puoi sempre cancellare la tua iscrizione prima che termini il periodo di prova gratuito.',
	paywall_full_discount: 'sconto del 100%',
	placeholder_email_address: 'Indirizzo email',
	placeholder_repeat_password: 'Ripeti la Password',
	placeholder_name_on_card: 'Nome e cognom',
	placeholder_voucher_code: 'codice del voucher',
	placeholder_full_name: 'Nome completo',
    placeholder_first_name: 'Nome',
    placeholder_surname: 'Cognome',
	placeholder_mm_yyyy: 'MM / AAAA',
	tooltip_forgot_password: 'password dimenticata',
	tooltip_cancel_subscription: 'Cancella l\’iscrizione',
	modal_enter_your_email: 'Inserisci il tuo indirizzo email.',
	modal_submit: 'Invia',
	modal_back: 'indietro',
	modal_sign_up: 'registrati',
    modal_forgot_password: 'Password dimenticata?',
	modal_register_capital: 'REGISTRATI',
	modal_choose_price: 'SCEGLI IL PREZZO',
	modal_payment_details: 'DETTAGLI DI PAGAMENTO',
	modal_login_to_your_account: 'Accedi al tuo account <%brand_name%>',
	modal_dont_have_one: 'Non hai un account?',
	modal_register_here: 'Registrati qui',
	modal_enter_your_new_password: 'Inserisci una nuova password.',
	modal_please_check_your_email: ' Controlla la tua email e copia il token della password dimenticata.',
	modal_resend_token: 'reinvia il token',
	modal_all_rights_reserved: 'Tutti i diritti riservati',
	modal_terms: 'Termini',
	ladda_apply: 'Applica',
	ladda_next: 'Successivo',
	ladda_create_account: 'crea account',
	account_your_subscriptions: 'la tua iscrizione',
	payment_pls_enter_your_card: 'inserisci la tua carta',
	payment_have_a_promotional_code: 'Hai un codice promozionale?',
	payment_im_paying_for: 'sto pagando per',
	payment_price: 'Prezzo',
	payment_pay: 'Paga',
	payment_add: 'Aggiungi',
	preview_protected_by_inplayer: 'Protetto da InPlayer',
	preview_this_premium_content: 'Questo contenuto premium richiede un account per accedervi.',
	preview_already_have_access: 'Hai già effettuato l\’accesso?',
	preview_login_here: 'Fai il Login qui con il tuo  <%brand_name%> account.',
	prices_pls_select_price: 'Seleziona l’opzione di prezzo',
	register_register_new_account: 'registra un nuovo <%brand_name%> account',
	register_by_clicking_agree: 'cliccando il bottone "Crea account" qui sotto  acconsenti al<%register_terms_of_service%> e <%register_privacy_policy%>.',
	register_terms_of_service: 'Termini di servizio',
	register_privacy_policy: 'Privacy',
	register_already_have_account: 'Hai già un account?',
	register_login_here: 'Fai log in qui',
    complete_registration_with_social_login: '',
	saved_cards_pay_with_saved: ' Paghi con una carta già registrata?',
	saved_cards_or_enter_new_card: 'Oppure inserisci una nuova carta',
	saved_cards_card_number: 'Numero di carta',
	saved_cards_item_ordered: 'Articolo ordinato',
	saved_cards_go_back: 'Torna indietro',
	user_menu_use_different: 'Usa un account diverso',
	user_menu_my_account: 'Abbonamenti...',
	code: 'it',
	lang_macedonian: 'Macedone',
	lang_english: 'Inglese',
	lang_spanish: 'Spagnolo',
	lang_polish: 'Polacco',
	lang_french: 'Francese',
	lang_swedish: 'Svedese',
	select_country: 'Seleziona il paese',
	card_payment: 'Pagamento con Carta',
	paypal_payment: 'pagamento con PayPal',
	card_management: 'Gestione carte',
	subscriptions: 'Abbonamenti',
	subscribed_for: 'Abbonati per',
	manage_your_cards: 'Gestisci le tue carte',
	select_card: 'Seleziona carta',
	edit_card: 'modifica…',
	add_new_card: 'Aggiungi una nuova carta',
	sales_currently_not_available: 'Vendita al momento non disponibile',
	account_management: 'Gestione dell\’account',
	modal_payment_method: 'metodo di pagamento',
	pls_select_payment_method: 'Inserisci un metodo di pagamento',
	card_method: 'Carta'
};