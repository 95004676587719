'use strict';

var $ = require('jquery')
	, unescape = require('lodash/unescape');

module.exports = {
	inject: function (element, asset, success) {
		if (!asset.content || asset.content === ''){
			throw 'Asset content not provided';
		}

		element.empty();

		try {
			var content = JSON.parse(unescape(asset.content));
            $('<div></div>').prop('id', 'wowza_player').appendTo(element);
            $('<script></script>').prop({
                id: 'player_embed',
                src: '//player.cloud.wowza.com/hosted/' + content.video_id + '/wowza.js',
                onload: function(){
                    if (document.readyState.toLowerCase() === 'complete'){
                        // unfortunately a necessary workaround because of the way Wowza's scripts work
                        setTimeout(function(){
                            if (window.dispatchEvent){
                                window.dispatchEvent(new Event('load'));
                            }
                        }, 1000);
                    }
                }
            }).appendTo(element);

            success();
		} catch(e) {
			throw new Error(e);
		}
	}
};