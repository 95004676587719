var $ = require('jquery'),
    resources = require('./inplayer-api'),
    domain = '';

module.exports = {
    checkForSession: function(ssoDomain, merchantUuid, success, fail){
        domain = ssoDomain || domain;

        return new Promise(function(resolve, reject){
            var id = 'inplayer-sso-iframe-' + merchantUuid,
                $iframe = $('#' + id);

            if (!$iframe.length){
                var src = ssoDomain + '/sso/login#' + merchantUuid;

                $iframe = $('<iframe>', {
                    id: id,
                    src: src,
                    style: 'display: none;'
                });

                $iframe.on('load', function(){
                    handleMessage(resolve, reject);
                    $iframe[0].contentWindow.postMessage('', domain);
                });

                $iframe.appendTo('body');
            }
            else {
                handleMessage(resolve, reject);
                $iframe[0].contentWindow.postMessage('', domain);
            }
        })
            .then(success)
            .catch(fail);
    },
    sendToken: function(token){
        reportToken(token, false);
    },
    retireToken: function(token){
        reportToken(token, true);
    },
    isEnabled: function(){
        return !!domain;
    }
};

function reportToken(token, retire){
    var data = new FormData();
    data.append('token', token);
    data.append('delete', retire ? 1 : 0);

    resources.sendSSOtoken(domain, data);
}

function handleMessage(success, fail){
    $(window).one('message', function(e){
        var event = e.originalEvent;

        if (event.origin !== domain || !event.data){
            resources.removeTokens();
            fail();
        }
        else {
            var data = JSON.parse(event.data);
            resources.saveTokens(data);
            success();
        }
    });
}