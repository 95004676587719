var $ = require('jquery'),
    deprecated = {
        clientId: 'oauthAppKey'
    };

module.exports = {
    organize: function(options){
        var organized = {};

        $.each(options, function(propName, value){
            if (deprecated.hasOwnProperty(propName)){
                var newName = deprecated[propName];
                console.warn('Option property "' + propName + '" is deprecated. Please start using "' + newName + '" instead.');

                organized[newName] = value;
            }
            else {
                organized[propName] = value;
            }
        });

        return organized;
    }
};