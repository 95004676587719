module.exports = {
    console_element_doesnt_exist: 'العنصر صاحب المعرف "%s"  غير موجود',
	console_saved_cards_not_implemented: ' الدفع من خلال البطاقات المحفوظة لم يتم تطبيقه بعد',
	console_form_element_not_found: 'عنصر النموذج "Form” غير موجود، المعرف: %s ',
	console_body_element_not_found: 'عنصر جسم الموقع "Body" غير موجود، المعرف: %s ',
	console_method_doesnt_exist: 'الطريقة %s غير موجودة',
	console_asset_no_access_fees: 'هذا الشيء لا يوجد عليه أي رسوم دخول',
	global_ise_pls_try_again: 'حدث خطأ في الخادم المحلي. حاول مرة أخرى',
	message_full_name: 'الحقل المخصص للاسم الكامل لا يجب أن يترك فارغاً.',
	message_email: 'الحقل المخصص للبريد الالكتروني لا يجب أن يترك فارغاً.',
    message_username: 'الحقل المخصص للبريد الالكتروني لا يجب أن يترك فارغاً.',
	message_password: 'الحقل المخصص لكلمة المرور لا يجب أن يترك فارغاً.',
	message_password_confirmation: 'يجب أن تتطابق كلمة المرور مع كلمة المرور المدخلة. ',
	message_number: 'رقم البطاقة غير صحيح',
	message_card_expiry: 'تاريخ صلاحية البطاقة غير صحيح',
	message_cvv: 'الرمز الأمني غير صحيح',
	message_card_name: 'الحقل المخصص لاسم البطاقة لا يجب أن يترك فارغاً.',
	message_unauthorized: 'الرمز غير صحيح. يرجى تسجيل الدخول مجدداً',
	subs_no_active_subscriptions: 'لا توجد اشتراكات نشطة',
	subs_created_on: 'تم إنشاؤه في',
	subs_next_billing_on: 'الدفع التالي في',
	subs_status: 'الحالة',
	paywall_free_trial_text: 'الدفع لهذا الشيء سينشئ اشتراكاً من خلال الإصدار التجريبي المجاني %s. سيتم تحصيل رسوم %s %s بعد انتهاء الفترة التجريبية المجانية. يمكنك دائماً إلغاء اشتراكك قبل انتهاء الفترة التجريبية المجانية.',
	paywall_full_discount: 'خصم 100%',
	placeholder_email_address: 'عنوان البريد الالكتروني',
	placeholder_password: 'كلمة المرور',
	placeholder_token: 'الرمز',
	placeholder_repeat_password: 'كرر كلمة المرور',
	placeholder_name_on_card: 'الاسم على البطاقة',
	placeholder_voucher_code: 'رمز القسيمة',
	placeholder_full_name: 'الاسم الكامل',
    placeholder_first_name: 'اسم',
    placeholder_surname: 'لقب',
	tooltip_forgot_password: 'نسيت كلمة المرور',
	tooltip_cancel_subscription: 'إلغاء الاشتراك',
	modal_enter_your_email: 'أدخل عنوان بريدك الالكتروني.',
	modal_submit: 'تقديم',
	modal_back: 'رجوع',
	modal_login: 'دخول',
	modal_sign_up: 'اشتراك',
	modal_forgot_password: 'نسيت كلمة المرور',
	modal_register_capital: 'اشتراك',
	modal_login_capital: 'دخول',
	modal_choose_price: 'اختر السعر',
	modal_payment_details: 'تفاصيل الدفع',
	modal_login_to_your_account: 'الدخول إلى حسابك <%brand_name%> ',
	modal_dont_have_one: 'ليس لديك حساب؟',
	modal_register_here: 'اشترك من هنا',
	modal_enter_your_new_password: 'أدخل كلمة المرور الجديدة',
	modal_please_check_your_email: 'يرجى التحقق من بريدك الإلكتروني ونسخ رمز نسيان كلمة المرور.',
	modal_resend_token: 'إعادة إرسال الرمز',
	modal_terms: 'الشروط',
	modal_faq: 'الأسئلة الشائعة',
	ladda_log_in: 'الدخول',
	ladda_apply: 'تقديم',
	ladda_next: 'التالي',
	ladda_create_account: 'إنشاء حساب',
	account_your_subscriptions: 'الاشتراكات الخاصة بك',
	account_no_active_subscriptions: 'لا توجد اشتراكات نشطة',
	payment_pls_enter_your_card: 'يرجى إدخال بطاقتك',
	payment_have_a_promotional_code: 'هل تملك رمز ترويجي؟',
	payment_im_paying_for: 'أنا أدفع ثمن',
	payment_price: 'السعر',
	payment_pay: 'الدفع',
	preview_protected_by_inplayer: 'تم حفظه بواسطة InPlayer ',
	preview_this_premium_content: 'هذا المحتوى المميز يتطلب الدخول إلى الحساب لتتمكن من الوصول إليه',
	preview_already_have_access: 'يمكنك الوصول بالفعل؟',
	preview_login_here: 'الدخول هنا من خلال حسابك <%brand_name%>',
	prices_pls_select_price: 'يرجى تحديد خيار السعر',
	register_register_new_account: 'تسجيل اشتراك حساب <%brand_name%> جديد',
	register_by_clicking_agree: 'بالنقر على زر "إنشاء حساب" أدناه فأنا أوافق على <%register_terms_of_service%> و <%register_privacy_policy%>.',
	register_terms_of_service: 'شروط الخدمة',
	register_privacy_policy: 'سياسة الخصوصية',
	register_already_have_account: 'لديك حساب بالفعل؟',
	register_login_here: ' الدخول من هنا',
	saved_cards_pay_with_saved: 'الدفع من خلال واحدة من بطاقاتك المحفوظة مسبقاً؟',
	saved_cards_or_enter_new_card: 'أو أدخل بطاقة جديدة من هنا',
	saved_cards_card_number: 'رقم البطاقة',
	saved_cards_item_ordered: 'عنصر مطلوب',
	saved_cards_go_back: 'رجوع',
	user_menu_use_different: 'استخدام حساب مختلف',
	user_menu_my_account: 'الاشتراكات...',
	user_menu_log_out: 'تسجيل الخروج',
	card_management: 'إدارة البطاقة”',
	subscriptions: 'الاشتراكات',
	manage_your_cards: 'إدارة البطاقات الخاصة بك',
	select_card: 'اختر البطاقة',
	edit_card: 'تحرير...',
	add_new_card: 'إضافة بطاقة جديدة',
    code: 'ar'
};