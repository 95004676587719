module.exports = {
    modal: require('../assets/templates/modal.html'),
    user_menu: require('../assets/templates/user-menu.html'),
    preview: require('../assets/templates/preview.html'),
    restrictedPreview: require('../assets/templates/restricted-preview.html'),
    price_opts: require('../assets/templates/price-options.html'),
    login: require('../assets/templates/login.html'),
    register: require('../assets/templates/register.html'),
    register_fields: require('../assets/templates/register-fields.html'),
    payment: require('../assets/templates/payment.html'),
    saved_cards: require('../assets/templates/saved-cards.html'),
    forgot_pass: require('../assets/templates/forgot-pass.html'),
    new_password: require('../assets/templates/new-password.html'),
    change_password: require('../assets/templates/change-password.html'),
    complete_registration: require('../assets/templates/complete-registration.html'),
    my_account: require('../assets/templates/my-account.html'),
    account_details: require('../assets/templates/account-details.html'),
    subscription: require('../assets/templates/subscription.html'),
    wistia: require('../assets/templates/wistia.html'),
    livestream: require('../assets/templates/livestream.html'),
    brightcove: require('../assets/templates/brightcove.html'),
    jwplayer: require('../assets/templates/jwplayer.html'),
    language_menu: require('../assets/templates/language-menu.html'),
    special_case: require('../assets/templates/special-case.html'),
    countries: require('../assets/templates/countries.html'),
    payment_method: require('../assets/templates/payment-method.html'),
    payment_method_button: require('../assets/templates/payment-method-button.html'),
    paypal: require('../assets/templates/paypal.html'),
    access_code: require('../assets/templates/access-code.html'),
    panopto: require('../assets/templates/panopto.html'),
    confirm_erase_account: require('../assets/templates/confirm-erase-account.html'),
    erase_account: require('../assets/templates/erase-account-password.html'),
    confirm_export_account: require('../assets/templates/confirm-export-account-data.html'),
    export_account: require('../assets/templates/export-account.html'),
    restricted_modal: require('../assets/templates/restricted-modal.html')
};