var $ = require('jquery'),
    config = require('../../config');

module.exports = {
    inject: function(element, asset, success){
        if ($.type(asset.content) !== 'string'){
            return;
        }

        var url = config.api_url + '/rss/' + asset.content + '/feed';

        var wrapper = $('<div class="inplayer-rss-wrapper"></div>');

        $('<div class="inplayer-rss-asset"></div>')
            .append('<div class="inplayer-rss-info">' + asset.title + '</div>')
            .append('<br>')
            .append('<a class="" target="_blank" href="' + url + '">' + url + '</a>')
            .appendTo(wrapper);

        element.html(wrapper);

        success && success();
    }
};