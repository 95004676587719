module.exports = {
	console_element_doesnt_exist: 'Element mit der ID "%s" existiert nicht.',
	console_saved_cards_not_implemented: 'Zahlung mit gespeicherten Karten noch nicht implementiert.',
	console_form_element_not_found: 'Formular-Element nicht gefunden, ID: %s',
	console_body_element_not_found: 'Body-Element nicht gefunden, id: %s',
	console_method_doesnt_exist: 'Methode %s existiert nicht.',
	console_asset_no_access_fees: 'Dieser Asset hat keine Zugangsgebühren.',
	global_ise_pls_try_again: 'Interner Server-Fehler. Bitte probieren Sie es erneut.',
	message_full_name: 'Der vollständige Name darf nicht leer sein.',
	message_email: 'Die E-Mail darf nicht leer sein.',
    message_username: 'Die E-Mail darf nicht leer sein.',
	message_password: 'Das Passwort darf nicht leer sein.',
	message_password_confirmation: 'Das bestätigte Passwort sollte dem eingebenen Passwort entsprechen.',
	message_number: 'Die Karten-Nummer ist nicht gültig.',
	message_card_expiry: 'Das Ablaufdatum der Karte ist nicht gültig.',
	message_cvv: 'CVV ist nicht gültig.',
	message_card_name: 'Der Karten-Name darf nicht leer sein.',
	message_unauthorized: 'Ungültiges Token. Bitte erneut anmelden.',
	subs_no_active_subscriptions: 'Keine aktiven Abonnements',
	subs_created_on: 'Erzeugt am',
	subs_next_billing_on: 'Nächste Zahlung am',
	paywall_free_trial_text: 'Eine Zahlung für diesen Asset wird ein Abonnement mit einem kostenlosen Probezeitraum von %s erzeugen. Ihnen werden %s %s berechnet, nachdem der kostenlose Probezeitraum abläuft. Sie können jederzeit Ihr Abonnement kündigen, bevor der kostenlose Probezeitraum endet.',
	paywall_full_discount: '100% Rabatt' ,
	placeholder_email_address: 'E-Mail-Adresse',
	placeholder_password: 'Passwort',
	placeholder_repeat_password: 'Passwort wiederholen',
	placeholder_name_on_card: 'Name auf der Karte',
	placeholder_voucher_code: 'Gutschein-Code',
	placeholder_full_name: 'Vollständiger Name',
    placeholder_first_name: 'Vorname',
    placeholder_surname: 'Nachname',
	tooltip_forgot_password: 'Passwort vergessen',
	tooltip_cancel_subscription: 'Abonnement kündigen',
	modal_enter_your_email: 'Geben Sie Ihre E-Mail-Adresse ein.',
	modal_submit: 'Senden',
	modal_back: 'zurück',
	modal_login: 'Login',
    modal_forgot_password: 'Passwort vergessen?',
	modal_sign_up: 'Registrieren',
	modal_register_capital: 'REGISTRIEREN',
	modal_choose_price: 'PREISE WÄHLEN',
	modal_payment_details: 'ZAHLUNGS-DETAILS',
	modal_login_to_your_account: 'In Ihrem <%brand_name%> Konto anmelden',
	modal_dont_have_one: 'Haben Sie keines?',
	modal_register_here: 'Hier registrieren',
	modal_enter_your_new_password: 'Neues Passwort eingeben.',
	modal_please_check_your_email: 'Bitte überprüfen Sie Ihre E-Mails und kopieren das Vergessenes-Passwort-Token.',
	modal_resend_token: 'Token erneut senden',
	modal_copyright: 'Copyright ©%s Inplayer LLC, Alle Rechte vorbehalten.',
	modal_terms: 'Geschäftsbedingen',
	ladda_log_in: 'Anmelden',
	ladda_apply: 'Anwenden',
	ladda_next: 'Weiter',
	ladda_create_account: 'Konto erstellen',
	account_your_subscriptions: 'Ihre Abonnements',
	account_no_active_subscriptions: 'Keine aktiven Abonnements',
	payment_pls_enter_your_card: 'Bitte geben Sie Ihre Zahlungsinformationen ein',
	payment_have_a_promotional_code: 'Haben Sie einen Promo-Code?',
	payment_im_paying_for: 'Ich bezahle für',
	payment_price: 'Preis',
	payment_pay: 'Bezahlen',
	payment_subscribe: 'Abonnieren',
	preview_protected_by_inplayer: 'Geschützt von InPlayer',
	preview_this_premium_content: 'Dieser Premium-Inhalt benötigt ein Konto für den Zugang.',
	preview_already_have_access: 'Haben Sie bereits einen Zugang?',
	preview_login_here: 'Hier mit Ihrem <%brand_name%> Konto anmelden',
	prices_pls_select_price: 'Bitte wählen Sie eine Preis-Option',
	register_register_new_account: 'Ein neues <%brand_name%> registrieren',
	register_by_clicking_agree: 'Durch Klicken auf den "Konto erstellen"-Button unten stimme ich den <%register_terms_of_service%> und <%register_privacy_policy%> zu.',
	register_terms_of_service: 'AGB',
	register_privacy_policy: 'Datenschutzbestimmungen',
	register_already_have_account: 'Konto bereits vorhanden?',
	register_login_here: 'Hier anmelden',
    complete_registration_with_social_login: '',
	saved_cards_pay_with_saved: 'Bezahlen mit einer Ihrer bereits gespeicherten Karten?',
	saved_cards_or_enter_new_card: 'Oder hier neue Karte eingeben',
	saved_cards_card_number: 'Karten-Nummer',
	saved_cards_item_ordered: 'Artikel bestellt',
	saved_cards_go_back: 'gehe zurück',
	user_menu_use_different: 'Benutze anderes Konto',
	user_menu_my_account: 'Abonnements...',
	user_menu_log_out: 'Abmelden',
	code: 'de',
    select_country: 'Land auswählen',
	card_management: 'Kartenverwaltung',
	subscriptions: 'Abonnements',
    subscribed_for: 'Abonnement für',
	manage_your_cards: 'Verwalten Sie Ihre Karten',
	select_card: 'Karte auswählen',
	edit_card: 'Bearbeite…',
	add_new_card: 'Neue Karte hinzufügen',
    modal_payment_method: 'Zahlungsmethode',
    pls_select_payment_method: 'Zahlungsmethode'
};