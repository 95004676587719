module.exports = {
	console_element_doesnt_exist: 'Element med id "%s" existerar ej.',
	console_saved_cards_not_implemented: 'Betalning med sparade kort har inte införts än.',
	console_form_element_not_found: 'Form element ej hittat, id: %s',
	console_body_element_not_found: 'Body element ej hittat, id: %s',
	console_method_doesnt_exist: 'Metod %s existerar ej.',
	console_asset_no_access_fees: 'Denna resurs har avgiftsfri tillgång.',
	global_ise_pls_try_again: 'Internt serverfel. Vänligen försök igen.',
	message_full_name: 'Fullständigt namn ska fyllas i.',
	message_email: 'E-post ska fyllas i.',
    message_username: 'E-post ska fyllas i.',
	message_password: 'Lösenord ska fyllas i.',
	message_password_confirmation: 'Lösenordsbekräftelsen ska matcha det inmatade lösenordet.',
	message_number: 'Kortnumret är ej giltigt.',
	message_card_expiry: 'Kortets utgångsdatum är ej giltigt.',
	message_cvv: 'CVV är ej giltigt.',
	message_card_name: 'Kortnamnet ska fyllas i.',
	message_unauthorized: 'Ogiltig kod. Var vänlig logga in igen.',
	subs_no_active_subscriptions: 'Ingen aktiv prenumeration',
	subs_created_on: 'Skapad den',
	subs_next_billing_on: 'Nästa faktura den',
	paywall_free_trial_text: 'Betalning av denna resurs skapar en prenumeration med en %s kostnadsfri provperiod. Du debiteras %s %s  efter att den kostnadsfria provperioden löper ut. Du kan alltid avsluta din prenumeration innan den kostnadsfria provperioden tar slut.',
	paywall_full_discount: '100% rabatt',
	placeholder_email_address: 'E-postadress',
	placeholder_password: 'Lösenord',
	placeholder_token: 'Kod',
	placeholder_repeat_password: 'Upprepa lösenord',
	placeholder_name_on_card: 'Namn på kort',
	placeholder_voucher_code: 'rabattkod',
	placeholder_full_name: 'Fullständigt namn',
    placeholder_first_name: 'Förnamn',
    placeholder_surname: 'Efternamn',
	placeholder_mm_yyyy: 'MM / ÅÅÅÅ',
	tooltip_forgot_password: 'glömt lösenord',
	tooltip_cancel_subscription: 'Avsluta prenumeration',
	modal_enter_your_email: 'Ange din e-postadress.',
	modal_submit: 'Skicka',
	modal_back: 'tillbaka',
	modal_login: 'logga in',
	modal_sign_up: 'registrera',
	modal_register_capital: 'REGISTRERA',
	modal_login_capital: 'LOGGA IN',
	modal_choose_price: 'VÄLJ PRIS',
	modal_payment_details: 'BETALNINGSDETALJER',
	modal_login_to_your_account: 'Logga in på ditt <%brand_name%> konto',
	modal_dont_have_one: 'Har du inget?',
	modal_register_here: 'Registrera dig här',
	modal_enter_your_new_password: 'Ange ditt nya lösenord.',
	modal_please_check_your_email: 'Var vänlig kontrollera din e-post och kopiera lösenordskoden.',
	modal_resend_token: 'skicka kod igen',
	modal_all_rights_reserved: 'Med ensamrätt.',
	modal_terms: 'Villkor',
	ladda_log_in: 'Logga in',
	ladda_apply: 'Ansök',
	ladda_next: 'Nästa',
	ladda_create_account: 'skapa konto',
	account_your_subscriptions: 'Dina prenumerationer',
	payment_pls_enter_your_card: 'Var vänlig ange din kortinformation',
	payment_have_a_promotional_code: 'Har du en kampanjkod?',
	payment_im_paying_for: 'Jag betalar för',
	payment_price: 'Pris',
	payment_pay: 'Betala',
	payment_add: 'Lägg till',
	preview_protected_by_inplayer: 'Skyddat av InPlayer',
	preview_this_premium_content: 'Detta premiuminnehåll kräver ett konto för åtkomst.',
	preview_already_have_access: 'Har du redan tillgång?',
	preview_login_here: 'Logga in här med ditt <%brand_name%> konto',
	prices_pls_select_price: 'Vänligen väl ett prisalternativ',
	register_register_new_account: 'Registrera ett nytt <%brand_name%> konto',
	register_by_clicking_agree: 'Genom att klicka på "Skapa Konto" nedan accepterar jag <%register_terms_of_service%> och <%register_privacy_policy%>.',
	register_terms_of_service: 'Användarvillkor',
	register_privacy_policy: 'Säkerhetspolicy',
	register_already_have_account: 'Har du redan ett konto?',
	register_login_here: 'Logga in här',
    complete_registration_with_social_login: '',
	saved_cards_pay_with_saved: 'Betala med ett av dina redan sparade kort?',
	saved_cards_or_enter_new_card: 'Eller ange nytt kort här',
	saved_cards_card_number: 'Kortnummer',
	saved_cards_item_ordered: 'Produkt beställd',
	saved_cards_go_back: 'gå tillbaka',
	user_menu_use_different: 'Använd ett annat konto',
	user_menu_my_account: 'Prenumerationer',
	user_menu_log_out: 'Logga Ut',
	dlc_download: 'Ladda ner',
	code: 'se',
	lang_macedonian: 'Makedonska',
	lang_english: 'Engelska',
	lang_spanish: 'Spanska',
	lang_polish: 'Polska',
	lang_french: 'Franska',
	lang_swedish: 'Svenska',
	select_country: 'Välj land.',
	card_payment: 'Kortbetalning',
	paypal_payment: 'PayPal betalning',
	card_management: 'Kort',
	subscriptions: 'Prenumerationer',
	sales_currently_not_available: 'Försäljningen är för närvarande inte tillgänglig',
	account_management: 'Kontostyrning'
};