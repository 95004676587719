var $ = require('jquery'),
	utils = require('./utils.js'),
	api = require('./inplayer-api.js'),
	templates = require('./templates.js'),
	Mustache = require('mustache'),
	lang = require('./language.js'),
	consoleError = lang.error,
	consoleInfo = lang.info,
	instances = require('./instances.js'),
	Forms = require('./forms'),
	Validation = require('./forms-validation')
	;

var doc = $(document),
	ids = {
		holder: '#inplayer-myaccount'
	},
	classes = {
		tab_radio: '.inplayer-tab-radio',
		tab: '.inplayer-tab',
		content: '.inplayer-tab-content',
		sub_expand_btn: '.inplayer-subscription-holder',
		sub_delete_btn: '.inplayer-delete-sub',
		description_holder: '.inplayer-description-holder',
		removable: '.inplayer-removable',
		empty: '.inplayer-empty',
		progressive_wrapper: '.inplayer-progressbar-wrapper',
		cancel_subscription_button: '.inplayer-delete-sub'
	},
	data_entries = {
		// account_management: utils.dataSelector('entry', 'account_management'),
		subscriptions: utils.dataSelector('entry', 'subscriptions')
	},
	clickables = [
		classes.tab_radio,
		classes.sub_expand_btn,
		classes.sub_delete_btn
	].join(',')
	;

var subscriptions = {};

// var tabs = ['account_management', 'subscriptions', 'card_management'];
var tabs = ['subscriptions'];

module.exports = (function(priv){
	var self;

	var res = {
		init: function(initialTab){
			initialTab = tabs[initialTab || 0];

			if (!self) {
				self = this;

				doc.on('click', clickables, function (e) {
					var me = $(this);

					// open tab
					if (me.hasClass(classes.tab_radio.substr(1))) {
						var entry = $(this).data('entry');

						if ($.isFunction(priv.getData[entry])) {
							priv.getData[entry](self.goto[entry], self.fail[entry]);
						} else {
							consoleInfo('console_method_doesnt_exist', entry);
						}
					}
					// cancel subscription
					else if (me.hasClass(classes.sub_delete_btn.substr(1))){
						priv.cancelSubscription(me);
					}
					// expand subscription
					else if (me.hasClass(classes.sub_expand_btn.substr(1))){
						me.siblings(classes.description_holder).toggle();

						var trashBtn = me.siblings(classes.cancel_subscription_button);

						// if (trashBtn.css('visibility') === 'visible'){
						// 	trashBtn.css('visibility', 'hidden');
						// }else{
						// 	trashBtn.css('visibility', 'visible');
						// }
					}
				});
			}

			if (typeof initialTab === 'string'){
				if (data_entries[initialTab]){
					var tab = $(classes.tab_radio + data_entries[initialTab]);
					tab.click();
				}
			}
		},
		goto: {
			account_management: function(){

			},
			subscriptions: function(res) {
			    var subs = res.collection;
			    
				// for staging change asset id to 724
				if (!subs.length && instances.find('207')){
					specialCase('207');
					return;
				}

				var container = $(classes.tab + data_entries.subscriptions).find(classes.content);
				if(subs.length > 0){
					container.empty();
				}
				$.each(subs, function(i, sub){
					if (['canceled', 'ended', 'pending'].indexOf(sub.status) >= 0){
						return;
					}
					// container.empty();

					var combinedId = 'inplayer-sub-' + sub.merchant_id + '-' + sub.asset_id;

					subscriptions[combinedId] = sub;

					$.each(['created_at', 'updated_at', 'next_billing_date'], function(i, key){
						sub[key] = utils.timestampToDate(sub[key]);
					});
					
					var cont = $('<div></div>')
						.addClass(classes.removable.substr(1))
						.prop('id', combinedId)
						.appendTo(container);

					$.extend(sub, lang.getText());

					cont.html(Mustache.render(templates.subscription, sub));
				});
			},
			card_management: function(){
				
			},
			other: function(){
				priv.hideAllTabs();
			}
		},
		fail: {
			account_management: function(data){
				console.log(data);
			},
			subscriptions: function(data){
                Validation.showErrors(null, Forms.getErrorsFromResponse(data));
			},
			card_management: function(data){
				console.log(data);
			}
		}
	};

	return res;
})({
	getData: {
		account_management: function(callback, fail){
			//console.log('get data account');
		},
		subscriptions: function(callback, fail){
			api.getSubscriptions(api.token(), callback, fail);
		},
		card_management: function(callback, fail){
			
		},
		other: function(callback){
			callback();
		}
	},
	hideAllTabs: function(){
		$(ids.holder).find(classes.tab).hide();
	},
	cancelSubscription: function(cancelBtn, removable, parent, id, self){
		self = this;

		removable = cancelBtn.parents(classes.removable);
		parent = removable.parent();
		id = removable.prop('id');

		var unsubscribe_url = subscriptions[id].unsubscribe_url,
			cancel_token = subscriptions[id].cancel_token;

		api.cancelSubscription(unsubscribe_url, cancel_token, function(data){
			subscriptions[id] = undefined;
			removable.remove();

			if (parent.is(':empty')){
				self.addEmptyIndicator(parent);
			}
		}, function(res){
      Validation.showErrors(null, Forms.getErrorsFromResponse(res));
		});
	},
	addEmptyIndicator: function(parent){
		$('<div></div>')
			.addClass(classes.empty)
			.html('<h5><strong>' + lang.getText('subs_no_active_subscriptions') + '</strong></h5>')
			.appendTo(parent);
	}
});

function specialCase(assetId){
	var token = api.token();
	api.checkAccessForAsset(token, assetId, function(asset) {
		var container = $(classes.tab + data_entries.subscriptions).find(classes.content);
		container.empty();
		container.html(templates.special_case);
	});
}