var staging = require('../config/conf.staging.json');
var sandbox = require('../config/conf.sandbox.json');
var prod = require('../config/conf.prod.json');

var config = staging;

if (process.env.NODE_ENV === 'production') {
  config = prod;
} else if (process.env.NODE_ENV === 'sandbox') {
  config = sandbox;
}

module.exports = config;