var settings = require('./global-settings.js'),
	inplayerBrand = require('./inplayer-brand'),
  state = require('./state'),
	$ = require('jquery');

module.exports = (function(){
	var instances = {};
	return {
		getAll: function(){
			return instances;
		},
		getExternalId: function(id){
			if (!instances[id]){
				return;
			}

			if (instances[id].external){
				return instances[id].external.id;
			}
		},
		add: function(assetId, merchantUuid, options, external, restrictionMsg) {
		    if (instances[assetId]){
		        return;
            }

			instances[assetId] = {
				merchantUuid: merchantUuid,
				options: options,
				external: external,
				branding: $.extend({}, inplayerBrand),
                restriction: restrictionMsg || null
			};

			if (options && options.global){
				settings.setup(options.global);
			}
		},
		find: function(assetId){
			return instances.hasOwnProperty(assetId);
		},
		setBranding: function(assetId, branding){
			if (!this.find(assetId)){
				return;
			}

			$.extend(instances[assetId].branding, branding);
		},
		getBranding: function(assetId){
            if (!assetId || !this.find(assetId)){
                return state.brand;
            }

            return instances[assetId].branding;
		}
	};
})();