'use strict';

var Mustache = require('mustache')
    , templates = require('../../templates.js')
    , utils = require('../../utils.js')
    , unescape = require('lodash/unescape');

module.exports = {
    inject: function (element, asset, success) {
        try {
            var content = JSON.parse(unescape(asset.content));
            element.html(Mustache.render(templates.wistia, {video_id: content.video_id}));

            utils.loadScripts([
                {src: '//fast.wistia.com/embed/medias/' + content.video_id + '.jsonp', type: 'script'},
                {src: '//fast.wistia.com/assets/external/E-v1.js', type: 'script'}
            ]);

            success();
        } catch (e) {
            throw new Error(e);
        }
    }
};