'use strict';

var $ = require('jquery')
    , payform = require('payform')
    , messages = require('./messages.js')
    , lang = require('./language.js');


var INPUT_ERR_CLASS = 'inplayer-input-error';
var GENERAL_ERR_ID = 'inplayer-form-general-error';
var GENERAL_INFO_ID = 'inplayer-form-general-info';
var LABEL_ERR_CLASS = 'inplayer-form-error';

var errors = {};

module.exports = (function () {
    var self = {
        validateRegister: function (form) {
            self.clearForm(form);

            var inputs = {};

            $.each(['full_name', 'username', 'password', 'password_confirmation'], function(i, name){
                var input = inputs[name] = form.find(':input[name="' + name + '"]');
                if (input.val().trim() === ''){
                    errors[name] = lang.getText('message_' + name);
                }
            });

            if (inputs.password.val() !== inputs.password_confirmation.val()){
                errors.password_confirmation = lang.getText('message_password_confirmation');
            }

            return errors;
        },

        validateLogin: function (form) {
            self.clearForm(form);

            $.each(['username', 'password'], function(i, name){
                var input = form.find(':input[name="' + name + '"]');
                if (input.val().trim() === ''){
                    errors[name] = lang.getText('message_' + name);
                }
            });

            return errors;
        },

        validatePayment: function (form) {
            self.clearForm(form);

            if (!payform.validateCardNumber(form.find(':input[name="number"]').val())) {
                errors['number'] = lang.getText('message_number');
            }
            if (!payform.validateCardCVC(form.find(':input[name="cvv"]').val())) {
                errors['cvv'] = lang.getText('message_cvv');
            }
            if (form.find(':input[name="cvv"]').val().trim() === '') {
                errors['card_name'] = lang.getText('message_card_name');
            }

            var expiry = payform.parseCardExpiry(form.find(':input[name="card_expiry"]').val());
            if (!payform.validateCardExpiry(expiry.month, expiry.year)) {
                errors['card_expiry'] = lang.getText('message_card_expiry');
            }

            return errors;
        },

        clearForm: function (form) {
            errors = {};

            self.setGlobalErrorMessage('');
            self.setGlobalInfoMessage('');

            if (form){
                self.removeInputErrors(form);
            }
        },

        showErrors: function (form, errors) {
            if (!$.isEmptyObject(errors)) {
                if ($.isArray(errors)) {
                    self.setGlobalErrorMessage(errors[0]);
                } else {
                    $.each(errors, function (key, value) {
                        var selector = ':input[name="' + key + '"]';
                        if (form && form.find(selector).length){
                            form.find(selector).addClass(INPUT_ERR_CLASS);
                            $('#inplayer-' + key.replace(/_/g, '-') + '-input-error').html(value);
                        } else {
                            self.setGlobalErrorMessage(value);
                        }
                    });
                }
            }
        },

        isValid: function () {
            return $.isEmptyObject(errors);
        },

        setGlobalErrorMessage: function (message) {
            $('#' + GENERAL_ERR_ID).html(message);
        },

        setGlobalInfoMessage: function(message) {
            $('#' + GENERAL_INFO_ID).html(message);
        },

        removeInputErrors: function (form) {
            $.each(form.get(0), function(i, input){
                $(input).removeClass(INPUT_ERR_CLASS);
            });
            $('.' + LABEL_ERR_CLASS).html('');
        }
    };

    return self;
})();