module.exports = {
	step: null,
	account: null,
	account_type: 'consumer',
	assets: {},
	current_asset: {},
    access_fees: [],
	access_fee: {},
	merchant_uuid: '',
    oauth_app_key: '',
	options: {},
	application_id: 1,
	payment_method: 1,
	payment_methods: [],
	new_card_payment: true,
	payment_tool: null,
	brand: require('./inplayer-brand.js'),
	standalone: false,
	paypal: {
		endpoint: null,
		message: null
	},
	returned_from_social: false,
	global: {
		hideUserMenu: false
    },
    current_error: null,
	query_params: {},
	non_lang_texts: {},
	registerFields: []
};
