var $ = require('jquery'),
    _params = {},
    _scripts = {};

module.exports = {
    setupScripts: function(options){
        if (!options.player_scripts){
            return;
        }

        $.each(options.player_scripts, function(i, player){
            $.each(player, function(j, item){
                var extension = item.substr(item.lastIndexOf('.') + 1);

                if (extension === 'js'){
                    options.player_scripts[i][j] = {
                        src: item,
                        type: 'script'
                    };
                }else if (extension === 'css'){
                    options.player_scripts[i][j] = {
                        src: item,
                        type: 'link'
                    };
                }else {
                    options.player_scripts[i][j] = undefined;
                }
            });
        });

        $.extend(_scripts, options.player_scripts);
    },
    setupParams: function(options){
        if (!options.player_params){
            return;
        }
        $.extend(_params, options.player_params);
    },
    getScripts: function(playerName){
        if (playerName){
            return _scripts[playerName] || null;
        }else{
            return _scripts;
        }
    },
    getParams: function(playerName){
        if (playerName){
            return _params[playerName] || {};
        }else{
            return _params;
        }
    }
};