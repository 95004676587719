var $ = require('jquery'),
	templates = require('../../templates.js'),
	Mustache = require('mustache'),
	unescape = require('lodash/unescape');

module.exports = {
	inject: function(element, asset, success){
		try {
			var content = JSON.parse(unescape(asset.content));
			element.html(Mustache.render(templates.jwplayer, content));
			$.isFunction(success) && success();
		} catch(e) {
			throw new Error(e);
		}
	}
};