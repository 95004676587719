'use strict';

var $ = require('jquery')
    , Modal = require('./modal.js')
    , Injector = require('./content-injection.js')
    , Validation = require('./forms-validation.js')
    , Forms = require('./forms.js')
    , resources = require('./inplayer-api.js')
    , events = require('./global-events.js')
    , has = require('lodash/has')
    , instances = require('./instances.js')
    , userMenu = require('./user-menu.js')
    , utils = require('./utils.js')
    , lang = require('./language')
    , awsIot = require('aws-iot-device-sdk')
    , config = require('./config')
    , client, topic, connected = false, busy = false
    ;

var MAX_INTERVAL = 600000,
    MAX_INITIAL_INTERVAL = 5;

module.exports = {
    notify: function(state) {
        if (connected || busy){
            return;
        }

        busy = true;

        topic = state.account.uuid;

        resources.getNotificationCreds(resources.token(), function(res){
            client = awsIot.device({
                region: res.region,
                protocol: 'wss',
                accessKeyId: res.accessKey,
                secretKey: res.secretKey,
                sessionToken: res.sessionToken,
                port: 443,
                host: config.iot_endpoint
            });

            client.on('connect', function(){
                console.log('Connection open');

                client.subscribe(topic);

                connected = true;
                busy = false;
            });

            client.on('close', function(){
                console.log('Connection ended or failed');

                connected = false;
                busy = false;
            });

            client.on('error', function(e){
                console.log(e);
            });

            client.on('message', function(topic, message){
                var body = JSON.parse(message.toString());

                console.log('Notification: ', body);

                if (body.type === 'account.logout' || body.type === 'account.erased') {
                    resources.account(resources.token(), function(){/*do nothing*/}, function() {
                        var asset_id = state.current_asset.id || Object.keys(state.assets)[0];

                        resources.logout();

                        $.each(instances.getAll(), function(assetId, instance){
                            var element = utils.getElement(assetId);
                            element.empty();
                            userMenu.getReinjector()(assetId, instance.merchantUuid, instance.options);
                        });

                        events.global.one('modal_login', function(){
                            setTimeout(function(){
                                Validation.clearForm();

                                if (body.type === 'account.erased' && resources.erasureInitiated){
                                    resources.erasureInitiated = false;
                                    Validation.setGlobalInfoMessage(lang.getText('your_account_has_been_successfully_erased'));
                                }
                                else {
                                    Validation.setGlobalErrorMessage(lang.getText('you_have_been_logged_out'));
                                }
                            }, 100);
                        });
                        events.global.trigger('invoke_modal_' + asset_id);
                    }, {}, true);
                }

                if (body.type === 'subscribe.requires.action') {
                  window.location.href = body.resource.redirect_to_url;
                }

                if (body.type === 'access.revoked'){
                    events.global.trigger(body.type, parseInt(body.resource.item_id));
                }

                if (body.type === 'access.granted'){
                    resources.checkAccessForAsset(resources.token(), state.current_asset.id, function(asset) {
                        Forms.stopLoading();
                        Injector.inject(asset.item, function() { //Success
                            Modal.close();
                        }, function (err) { //Error
                            showErrors(Forms.getErrorsFromResponse(err));
                        });
                    }, function (err) {
                        Forms.stopLoading();
                        showErrors(Forms.getErrorsFromResponse(err));
                    });

                    $.each(state.assets, function (i, a) {
                        if (a.id !== state.current_asset.id) {
                            resources.checkAccessForAsset(resources.token(), a.id, function (asset) {
                                Forms.stopLoading();
                                Injector.inject(asset.item, function () {

                                }, function () {});
                            }, function () {
                                Forms.stopLoading();
                            });
                        }
                    });
                }
                else if (body.type === 'payment.card.success' || body.type === 'payment.paypal.success' || body.type === 'subscribe.success') {
                    body.resource.asset = {
                        id: state.current_asset.id
                    };

                    events.global.trigger('payment', body);
                }
                else if (body.type === 'payment.card.failed' || body.type === 'subscribe.failed'){
                    Forms.stopLoading();
                    Validation.setGlobalErrorMessage(body.resource.message);
                }
                else {
                    var errors = Forms.getErrorsFromResponse(body);
                    if (!$.isEmptyObject(errors)) {
                        showErrors(errors);
                    }
                }
            });
        }, function(res){
            console.log('error', res);
            busy = false;
        });
    },
    unsubscribe: function () {
        if (!connected || !client){
            return;
        }

        client.end(false, function(){
            connected = false;
            busy = false;
        });
    }
};

function showErrors(err) {
    if (!Modal.isShown()) {
        //@todo - show error modal
    }
    else {
        Forms.stopLoading();
        Validation.showErrors(null, err);
    }
}
