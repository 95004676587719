'use strict';

var $ = require('jquery')
    , resources = require('../../inplayer-api')
    , config = require('../../config')
    , unescape = require('lodash/unescape')
    , lang = require('../../language');

module.exports = {
    inject: function(element, asset, success) {
        var content = JSON.parse(unescape(asset.content));

        if (!$.isArray(content)) {
            throw new Error('Content must be an array.');
        }

        var container = $('<div class="inplayer-dlc-wrapper"></div>');

        resources.getDlcLinks(resources.token(), asset.id, function(assetData) {
            for (var i = 0; i < assetData.length; i++) {
                container.append(createRow(assetData[i]));
            }
        });

        element.html(container);

        success();
    }
};

function createRow(item) {
    var url = config.api_url + '/dlc/' + item.token;
    var thumb = item.thumbnail !== undefined ? '<img class="inplayer-dlc-thumb" src="' + item.thumbnail + '" alt="thumb">' : '';
    var size = item.filesize !== undefined ? ' (' + Math.round(item.filesize / 1024) + ' kb)' : '';
    var downloadTextTag = 'dlc_download';

    return $('<div class="inplayer-dlc-file">')
        .append($(thumb))
        .append($('<div class="inplayer-dlc-title">' + item.title + size + '</div>'))
        .append($('<a class="inplayer-download-btn" href="' + url + '"><img title="' + lang.getText(downloadTextTag) + '" src="https://assets.inplayer.com/images/download-btn.png"/></a>'))
        .append($('<div class="inplayer-dlc-description">' + item.file_description + '</div>'));
}