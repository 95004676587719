var $ = require('jquery'),
	unescape = require('lodash/unescape'),
	utils = require('../../utils.js'),
	templates = require('../../templates.js'),
	Mustache = require('mustache');

module.exports = {
	inject: function(element, asset, success){
		try {
			var content = JSON.parse(unescape(asset.content));

			content.width = content.width || 750;
			content.height = content.height || 420;
			content.embed_id = asset.id;

			element.html(Mustache.render(templates.livestream, content));

			var src = '//livestream.com/assets/plugins/referrer_tracking.js';
			if (!utils.isLoaded({ src: src, type: 'script' })) {
				$('<script></script>', {
					'src': src,
					'data-embed-id': 'ls_embed_' + content.embed_id
				}).appendTo('body');
			}

			$.isFunction(success) && success();
		} catch(e) {
			throw new Error(e);
		}
	}
};