'use strict';
var $ = require('jquery')
    , payform = require('payform')
    , Ladda = require('ladda')
    , resources = require('./inplayer-api.js')
    , Validation = require('./forms-validation.js')
    , isLoading = false
    , error = require('./language.js').error
    , utils = require('./utils.js')
    , events = require('./global-events')
    , instances = require('./instances')
    , Mustache = require('mustache')
    , sso = require('./sso')
    , fingerprint = require('./browser-fingerprint')
    ;

var watchers = [];
var functions = {
    price_opts: priceOptsSubmit,
    login: signInSubmit,
    register: signUpSubmit,
    payment: paymentSubmit,
    paypal: paypalSubmit,
    saved_cards: savedCardsSubmit,
    forgot_pass: forgotPasswordSubmit,
    new_password: newPasswordSubmit,
    change_password: changePasswordSubmit,
    complete_registration: completeRegistrationSubmit,
    account_details: accountDetailsSubmit,
    access_code: accessCodeSubmit,
    confirm_erase_account: confirmEraseAccount,
    erase_account: eraseAccountSubmit,
    confirm_export_account: confirmExportAccount,
    export_account: exportAccountData
};

var self;

module.exports = self = (function () {
    var self = {
        price_opts: 'price_opts',
        restricted_modal: 'restricted_modal',
        login: 'login',
        register: 'register',
        complete_registration: 'complete_registration',
        payment_method: 'payment_method',
        payment: 'payment',
        paypal: 'paypal',
        saved_cards: 'saved_cards',
        forgot_pass: 'forgot_pass',
        new_password: 'new_password',
        change_password: 'change_password',
        account_details: 'account_details',
        confirm_erase_account: 'confirm_erase_account',
        erase_account: 'erase_account',
        confirm_export_account_data: 'confirm_export_account',
        export_account: 'export_account',
        my_account: 'my_account',
        access_code: 'access_code',
        buttons: {
            price_opts: 'inplayer-price-submit',
            login: 'inplayer-login-submit',
            register: 'inplayer-register-submit',
            payment: 'inplayer-pay-submit',
            paypal: 'inplayer-pay-submit',
            forgot_pass: 'inplayer-forgot-pass-submit',
            new_password: 'inplayer-new-password-submit',
            change_password: 'inplayer-change-password-submit',
            freemium: 'inplayer-freemium-submit',
            complete_registration: 'inplayer-complete-registration-submit',
            account_details: 'inplayer-account-details-submit',
            access_code: 'inplayer-access-code-submit',
            confirm_erase_account: 'inplayer-confirm-erase-account',
            erase_account: 'inplayer-erase-account-submit',
            confirm_export_account: 'inplayer-confirm-export-account',
            export_account: 'inplayer-export-account-submit'
        },
        registerListener: function (cb) {
            watchers.push(cb);
        },
        dispatch: function (name, e, state) {
            functions[name](e, state, self);
        },
        getErrorsFromResponse: function (res) {
            if (res.errors && !$.isEmptyObject(res.errors)) {
                return res.errors;
            }
            if (res.message) {
                return {global: res.message};
            }
            if (res.explain && !$.isEmptyObject(res.explain)) {
                return res.explain;
            }
            return {};
        },
        isLoading: function () {
            return isLoading;
        },
        startLoading: function (button) {
            isLoading = true;
            Ladda.create($('#' + button).get(0)).start();
        },
        stopLoading: function () {
            isLoading = false;
            Ladda.stopAll();
        }
    };
    return self;
})();

function signInSubmit(e, state, self) {
    e.preventDefault();

    var form = $(e.target);
    var errors = Validation.validateLogin(form);

    if (!Validation.isValid()) {
        executeWatchers({name: self.login, response: errors, success: false});
        Validation.showErrors(form, errors);
        return;
    }

    var data = new FormData(form.get(0));
    data.append('merchant_uuid', state.merchant_uuid);
    data.append('referrer', utils.getReferrer());
    data.append('client_id', state.options.oauthAppKey || state.merchant_uuid);
    data.append('grant_type', 'password');

    var brand_id = instances.getBranding(state.current_asset.id).id;
    if (brand_id){
        data.append('branding_id', brand_id);
    }

    resources.authenticate(data, 'password', function (res) {
        resources.saveTokens(res);

        if (sso.isEnabled()) {
            sso.sendToken(res.access_token);
        }

        executeWatchers({name: self.login, response: res, success: true});
    }, function (res) {
        Validation.showErrors(form, self.getErrorsFromResponse(res));
        executeWatchers({name: self.login, response: res, success: false});
    });
}

function signUpSubmit(e, state, self) {
    e.preventDefault();

    var form = $(e.target);
    var errors = Validation.validateRegister(form);
    if (!Validation.isValid()) {
        executeWatchers({name: self.register, response: errors, success: false});
        Validation.showErrors(form, errors);
        return;
    }

    var data = new FormData(form.get(0));
    data.append('client_id', state.options.oauthAppKey || state.merchant_uuid);
    data.append('type', state.account_type);
    data.append('referrer', utils.getReferrer());
    data.append('grant_type', 'password');

    var brand_id = instances.getBranding(state.current_asset.id).id;
    if (brand_id){
        data.append('branding_id', brand_id);
    }

    resources.signUp(data, function (res) {
        resources.saveTokens(res);

        if (sso.isEnabled()) {
            sso.sendToken(res.access_token);
        }

        executeWatchers({name: self.register, response: res, success: true});
    }, function (res) {
        Validation.showErrors(form, self.getErrorsFromResponse(res));
        executeWatchers({name: self.register, response: res, success: false});
    });
}

function completeRegistrationSubmit(e, state, self, screenName){
    e.preventDefault();

    var form = $(e.target);

    Validation.clearForm(form);

    var data = {},
        params = '';

    $.each(form.serializeArray(), function(key, val){
        data[val.name] = val.value;
    });

    var brand_id = instances.getBranding(state.current_asset.id).id;
    if (brand_id){
        data.branding_id = brand_id;
    }

    params = $.param(data);

    screenName = screenName || self.complete_registration;

    resources.updateAccount(params, resources.token(), function(res){
        $.each(data, function(key, val){
            if (/^metadata\[(.+)]$/g.test(key)){
                var metaKey = key.replace(/^metadata\[(.+)]$/g, '$1');
                state.account.metadata[metaKey] = val;
            }
            else {
                state.account[key] = val;
            }
        });

        executeWatchers({name: self[screenName], response: res, success: true});
    }, function(res){
        executeWatchers({name: self[screenName], response: res, success: false});
        Validation.showErrors(form, self.getErrorsFromResponse(res));
    });
}

function accountDetailsSubmit(e, state, self){
    completeRegistrationSubmit(e, state, self, self.account_details);
}

function accessCodeSubmit(e, state, self){
    var code, print, assetId;

    if (e && !e.browser_fingerprint && !e.access_code && !e.asset_id) {
        e.preventDefault();

        var form = $(e.target),
            jsonData = {},
            codePattern = '';

        Validation.clearForm(form);

        $.each(form.serializeArray(), function (i, el) {
            if (el.name === 'codePattern') {
                codePattern = el.value;
                return;
            }

            if (el.name !== 'access_code') {
                jsonData[el.name] = el.value
                    .toLowerCase()
                    .replace(/[^a-zA-Z0-9]/g, '');
            }
            else {
                jsonData[el.name] = el.value;
            }
        });

        code = codePattern ? Mustache.render(codePattern, jsonData) : jsonData.access_code;
        assetId = state.current_asset.id;

        fingerprint.generatePrint(function(result){
            print = result;
            events.global.trigger('browser_fingerprint_complete');
        });
    }
    else {
        code = e.access_code;
        print = e.browser_fingerprint;
        assetId = e.asset_id;
    }

    events.global.one('browser_fingerprint_complete', function(){
        var data = new FormData();
        data.append('code', code);
        data.append('id', assetId);
        data.append('browser_fingerprint', print);

        resources.sendAccessCode(data, function (res) {
            fingerprint.savePrintAndCode(print, code);
            events.global.trigger('access_code_response', true);
            executeWatchers({name: self.access_code, response: res, success: true});
        }, function (res) {
            if (fingerprint.localPrintFound()){
                fingerprint.removeSaved();
            }

            events.global.trigger('access_code_response', false);

            Validation.showErrors(form, self.getErrorsFromResponse(res));
            executeWatchers({name: self.access_code, response: res, success: false});
        });
    });

    if (print){
        events.global.trigger('browser_fingerprint_complete');
    }
}

function paymentSubmit(e, state, self) {
    e.preventDefault();

    var form = $(e.target);
    var data = new FormData();

    var expiry = payform.parseCardExpiry(form.find(':input[name="card_expiry"]').val());
    $.each({
        number: form.find(':input[name="number"]').val(),
        card_name: form.find(':input[name="card_name"]').val(),
        exp_month: expiry.month,
        exp_year: expiry.year,
        cvv: form.find(':input[name="cvv"]').val(),
        access_fee: state.access_fee.id,
        payment_method: state.payment_method,
        referrer: utils.getReferrer(),
        return_url: window.location.href
    }, function(key, val){
        data.append(key, val);
    });

    if (form.find(':input[name="price"]').val() !== '0' || state.access_fee.access_type.name === 'subscription') {
        var errors = Validation.validatePayment(form);
        if (!Validation.isValid()) {
            executeWatchers({name: self.payment, response: errors, success: false});
            Validation.showErrors(form, errors);
            return;
        }
    }

    var voucher_vode = form.find(':input[name="voucher_code"]').val().trim();
    if (voucher_vode !== '') {
        data.append('voucher_code', voucher_vode);
    }

    var brand_id = instances.getBranding(state.current_asset.id).id;
    if (brand_id){
        data.append('branding_id', brand_id);
    }

    if (state.access_fee.access_type.name !== 'subscription') {
        resources.payForAsset(resources.token(), data, noop, function (res) {
            executeWatchers({name: self.payment, response: res, success: false});
            Validation.showErrors(form, self.getErrorsFromResponse(res));
        });
    }
    else {
        resources.subscribeForAsset(resources.token(), data, noop, function (res) {
            executeWatchers({name: self.payment, response: res, success: false});
            Validation.showErrors(form, self.getErrorsFromResponse(res));
        });
    }
}

function paypalSubmit(e, state, self){
    e.preventDefault();

    var form = $(e.target);

    if (state.paypal.message) {
        Validation.showErrors(form, self.getErrorsFromResponse(state.paypal));
        self.stopLoading();
    }
}

function priceOptsSubmit(e, state, self) {
    e && e.preventDefault();

    var selectedFee;

    if (e) {
        var form = $(e.target);
        var prices = form.find(':input[name="prices"]');
        var f = prices.find('option:selected');
        selectedFee = f.val();
    }else if (utils.isFreemium(state.access_fees)){
        selectedFee = state.access_fees[0].id;
    }

    if (selectedFee) {
        $.each(state.access_fees, function (i, fee) {
            if (fee.id == selectedFee) {
                if (fee.access_type.name === 'freemium') {
                    var data = new FormData();
                    data.append('access_fee', fee.id);

                    var brand_id = instances.getBranding(state.current_asset.id).id;
                    if (brand_id){
                        data.append('branding_id', brand_id);
                    }

                    resources.freemiumAsset(resources.token(), data, function (res) {
                        if ($.isEmptyObject(state.current_asset) || state.standalone){
                            executeWatchers({name: self.price_opts, response: {}, success: true, hasAccess: true});
                            return;
                        }
                        resources.checkAccessForAsset(resources.token(), state.current_asset.id, function(asset){
                            executeWatchers({name: self.price_opts, response: asset, success: true, hasAccess: true});
                        },
                        function(){
                            executeWatchers({name: self.price_opts, response: res, success: false, hasAccess: false});
                            Validation.showErrors(form, self.getErrorsFromResponse(res));
                        });
                    }, function (res) {
                        executeWatchers({name: self.price_opts, response: res, success: false, hasAccess: false});
                        Validation.showErrors(form, self.getErrorsFromResponse(res));
                    });
                }
                else {
                    executeWatchers({name: self.price_opts, response: fee, success: true, hasAccess: false});
                }
                return false;
            }
        });
        return;
    }

    var res = {global: 'No price selected'};
    Validation.showErrors(form, res);
    executeWatchers({name: self.price_opts, response: res, success: false});
}

function forgotPasswordSubmit(e, state, self) {
    e.preventDefault();

    var form = $(e.target);
    var data = new FormData(form.get(0));
    data.append('merchant_uuid', state.merchant_uuid);

    var brand_id = instances.getBranding(state.current_asset.id).id;
    if (brand_id){
        data.append('branding_id', brand_id);
    }

    resources.requestForgotPassword(data, function (res) {
        executeWatchers({name: self.forgot_pass, response: res, success: true});
    }, function (res) {
        Validation.showErrors(form, self.getErrorsFromResponse(res));
        executeWatchers({name: self.forgot_pass, response: res, success: false});
    });
}

function newPasswordSubmit(e, state, self) {
    e.preventDefault();
    var form = e.target;
    var data = {
        password: form['password'].value,
        password_confirmation: form['password_confirmation'].value
    };

    var brand_id = instances.getBranding(state.current_asset.id).id;
    if (brand_id){
        data.branding_id = brand_id;
    }

    resources.setNewPassword(data, form['token'].value, function (res) {
        executeWatchers({name: self.new_password, response: res, success: true});
    }, function (res) {
        Validation.showErrors($(form), self.getErrorsFromResponse(res));
        executeWatchers({name: self.new_password, response: res, success: false});
    });
}

function changePasswordSubmit(e, state, self) {
    e.preventDefault();

    var form = $(e.target);
    var data = new FormData(form.get(0));

    var brand_id = instances.getBranding(state.current_asset.id).id;
    if (brand_id){
        data.append('branding_id', brand_id);
    }

    resources.changePassword(data, resources.token(), function (res) {
        executeWatchers({name: self.change_password, response: res, success: true});
    }, function (res) {
        Validation.showErrors($(form), self.getErrorsFromResponse(res));
        executeWatchers({name: self.change_password, response: res, success: false});
    });
}

function eraseAccountSubmit(e, state, self) {
    e.preventDefault();

    var form = $(e.target),
        data = $(form.get(0)).serialize();

    Validation.clearForm(form);

    resources.eraseAccount(data, function(res) {
        executeWatchers({name: self.erase_account, response: res, success: true});
    }, function(res){
        resources.erasureInitiated = false;

        Validation.showErrors($(form), self.getErrorsFromResponse(res));
        executeWatchers({name: self.erase_account, response: res, success: false});
    });
}

function exportAccountData(e, state, self) {
    e.preventDefault();

    var form = $(e.target),
        data = $(form.get(0)).serialize();

    Validation.clearForm(form);

    resources.exportAccountData(data, function(res) {
        executeWatchers({name: self.export_account, response: res, success: true});
    }, function(res){
        Validation.showErrors($(form), self.getErrorsFromResponse(res));
        executeWatchers({name: self.export_account, response: res, success: false});
    });
}

function confirmEraseAccount() {
    executeWatchers({name: self.confirm_erase_account, response: {}, success: true});
}

function confirmExportAccount() {
    executeWatchers({name: self.confirm_export_account_data, response: {}, success: true});
}

function savedCardsSubmit(e, state, self) {
    error('saved_cards_not_implemented');
}

function executeWatchers(res) {
    $.each(watchers, function(i, cb) {
        cb(res);
    });
}

function noop() {
}