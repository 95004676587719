'use strict';
var resources = require('../inplayer-api.js');

module.exports = {
    inject: function(element, asset, success, fail) {
        resources.fetchWpContent(asset.item.content, resources.token(), function (content) {
            element.html(content);
            success();
        }, function(err) {
            fail(err);
        });
    }
};