'use strict';

var unescape = require('lodash/unescape'),
    entities = require('entities');

module.exports = {
    inject: function (element, asset, success) {
        var content = entities.decodeXML(unescape(asset.content));
        element.html(content);

        success && success();
    }
};