'use strict';

var $ = require('jquery')
    , _ = require('underscore')
    , Mustache = require('mustache')
    , Ladda = require('ladda')
    , payform = require('payform')
    , Notif = require('./notifications.js')
    , resources = require('./inplayer-api.js')
    , Modal = require('./modal.js')
    , Injector = require('./content-injection.js')
    , Forms = require('./forms.js')
    , Validation = require('./forms-validation.js')
    , templates = require('./templates.js')
    , userMenu = require('./user-menu.js')
    , myAccount = require('./my-account.js')
    , utils = require('./utils.js')
    , lang = require('./language.js')
    , events = require('./global-events.js')
    , error = lang.error
    , state = require('./state.js')
    , unescape = require('lodash/unescape')
    , inplayerBrand = state.brand
    , entities = require('entities')
    , instances = require('./instances')
    , fingerprint = require('./browser-fingerprint')
    ;

// Custom mustache tags
Mustache.tags = [ '<%', '%>' ];

var steps = {
    login_register: 1,
    price_opt: 2,
    payment_method: 3,
    pay: 4
};
state.step = steps.login_register;

var ids = {
    back_btn: 'inplayer-back',
    discount_btn: 'inplayer-get-discount-submit',
    price_submit: 'inplayer-price-submit',
    item_ordered: 'inplayer-item-ordered',
    item_price: 'inplayer-item-price',
    additional_fee: 'inplayer-item-additional-fee',
    access_fees: 'inplayer-access-fees',
    saved_cards: 'inplayer-saved-cards',
    saved_card_input: 'inplayer-saved-card-input',
    use_new_card_link: 'inplayer-use-new-card',
    username: 'inplayer-username',
    user_menu_in_content: 'inplayer-menu-content',
    payment_method_btn_holder: 'inplayer-payment-method-buttons',
    payment_method_btns: {
        card: 'inplayer-paymethod-card',
        paypal: 'inplayer-paymethod-paypal'
    }
};

var classes = {
    user_menu_wrapper: 'inplayer-menu-preview',
    logged_out: 'inplayer-loggedout',
    preview_footer: 'inplayer-preview-footer',
    logged_in: 'inplayer-loggedin',
    register_link: 'ip-registerlink',
    login_link: 'ip-loginlink',
    paywall_btn: 'inplayer-paywall',
    email: 'inplayer-email',
    standalone_btn: 'inplayer-standalone-btn',
    login_btn: 'inplayer-login-btn',
    logout_btn: 'inplayer-logout-btn',
    myaccount_btn: 'inplayer-myaccount-btn'
};

var coverPhoto = 'https://inplayer-paywall-v2.s3.amazonaws.com/images/ip-preview-premium.jpg';

function updateState(newState) {
    state = $.extend(state, newState);
}

var self;
module.exports = self = (function () {
    var self = {
        standalone: function(uuid, options){
            updateState({merchant_uuid: uuid, options: options});

            events.setHandlers(options);

            self.getBranding(uuid, options.brandingId, null, function(){
                updateUserMenu(self);

                $(document).on('click', '.' + classes.standalone_btn + '.' + classes.login_btn, function(e){
                    e.preventDefault();

                    updateState({current_asset: {}, standalone: true});

                    resources.getRegisterFields(uuid, function(response){
                        if (response && response.collection && response.collection.length){
                            updateState({
                                registerFields: response.collection
                            });
                        }

                        options.registerFirst ? self.signUp() : self.login();
                    });
                });

                events.global.trigger('paywall_init');
            });
        },
        init: function (asset, uuid, options, cb) {
            var assets = state.assets;
            var firstInit = !assets[asset.id];

            assets[asset.id] = asset;
            
            updateState({assets: assets, merchant_uuid: uuid, options: options});

            if (firstInit){
                var openModal = function(e) {
                    updateState({current_asset: asset, standalone: false});

                    var restriction = instances.getAll()[asset.id].restriction;

                    if (restriction){
                        self.restrictedModal(restriction);
                        return;
                    }

                    var accessType = asset.access_control_type;
                    if (accessType && !accessType.auth && accessType.name.toLowerCase() === 'code'){
                        self.accessCode();
                        return;
                    }

                    if (resources.isLoggedIn()) {
                        if (!state.account.completed){
                            resources.getRegisterFields(state.merchant_uuid, function(response){
                                if (response && response.collection && response.collection.length){
                                    updateState({
                                        registerFields: response.collection
                                    });
                                }

                                self.completeRegistration();
                            });
                        } else {
                            self.priceOptions();
                        }
                    }
                    else {
                        resources.getRegisterFields(uuid, function(response){
                            if (response && response.collection && response.collection.length){
                                updateState({
                                    registerFields: response.collection
                                });
                            }

                            options.registerFirst ? self.signUp() : self.login();
                        });
                    }
                };

                var clickSelectors = [ '.' + classes.paywall_btn + '-' + asset.id ];

                var externalId = instances.getExternalId(asset.id);
                if (externalId){
                    clickSelectors.push('.inplayer-paywall-external-' + externalId);
                }

                var checkSSO = function(e){
                    e.preventDefault();

                    if (!options.ssoDomain || resources.isLoggedIn()){
                        openModal(e);
                        return;
                    }

                    sso.checkForSession(options.ssoDomain, options.oauthAppKey || uuid, function () {
                        self.notifyAccount({}, function(){
                            openModal(e);
                        });
                        updateUserMenu();
                    }, function(){
                        openModal(e);
                    });
                };

                $(document).on('click', clickSelectors.join(','), checkSSO);
                $(document).on('click', '.inplayer-acc-btn', function(e){
                    var $target = $(e.target);

                    if ($target.hasClass('inplayer-deactivate-acc')){
                        self.eraseAccountConfirmPrompt();
                        return;
                    }

                    if ($target.hasClass('inplayer-export-acc-data')){
                        self.exportAccountConfirmPrompt();
                        return;
                    }
                });

                events.global.on('invoke_modal_' + asset.id, checkSSO);
            }

            self.getBranding(uuid, options.brandingId, asset, function(){
                updateUserMenu(self);
                $.isFunction(cb) && cb();
            });

            if (resources.isLoggedIn()) {
                if (asset.id === parseInt(state.query_params.asset_id)){
                    updateState({current_asset: asset});
                    if (!state.account.completed){
                        resources.getRegisterFields(state.merchant_uuid, function(response){
                            if (response && response.collection && response.collection.length){
                                updateState({
                                    registerFields: response.collection
                                });
                            }

                            self.completeRegistration();
                        });
                    }else if (state.returned_from_social) {
                        if (!asset.item) {
                            self.priceOptions();
                        }
                    }
                }

                self.hideOrShowChangePasswordButton();
            }else if (state.current_error) {
                if (state.current_error === 'missing-information' && asset.id === parseInt(state.query_params.asset_id)){
                    updateState({current_asset: asset});

                    self.login();
                    Validation.setGlobalErrorMessage(lang.getText('social_platform_auth_could_not'));

                    state.current_error = null;
                    state.query_params = {};
                }
            }

            events.global.trigger('paywall_init');
        },

        restrictedPreview: function(el, asset, noPreview) {
            if (noPreview){
                return;
            }

            var view = $.extend({}, inplayerBrand, instances.getBranding(asset.id), asset, lang.getText());

            view.preview_button_label = 'View';

            if (asset.paywall_cover_photo && asset.paywall_cover_photo === coverPhoto && instances.getBranding(asset.id).paywall_cover_photo){
                view.paywall_cover_photo = instances.getBranding(asset.id).paywall_cover_photo;
            }

            el.html(Mustache.render(templates.restrictedPreview, view));

            handleDataHTML(utils.getElement(asset.id).find('[data-html]'));

            updateUserMenu(self);

            events.global.trigger('preview_' + asset.id);
        },

        preview: function (el, asset, noPreview) {
            var accessType = asset.access_control_type;
            if (accessType && !accessType.auth && accessType.name.toLowerCase() === 'code'){
                if (noPreview && !el.is(':empty')){
                    el.empty();
                }

                fingerprint.onCancelAccess({
                    element: el,
                    assetView: asset,
                    noPreview: noPreview
                }, function(element, view, noPreview){
                    self.preview(element, view, noPreview);
                });

                if (fingerprint.localPrintFound()){
                    events.global.one('access_code_response', function(e, wasSuccessful){
                        if (!wasSuccessful){
                            self.preview(el, asset, noPreview);
                        }
                    });

                    Forms.dispatch('access_code', {
                        browser_fingerprint: fingerprint.getSavedPrint(),
                        access_code: fingerprint.getSavedCode(),
                        asset_id: asset.id
                    }, state);

                    return;
                }
            }

            if (noPreview){
                return;
            }

            if (asset['preview_title']){
                asset['preview_title'] = asset['preview_title'].replace(/\\/g, '');
            }

            var assets = state.assets;
            assets[asset.id].checked = false;
            updateState({assets: assets});

            var view = $.extend({}, inplayerBrand, instances.getBranding(asset.id), asset, lang.getText(), {
                code_access: asset.access_control_type && asset.access_control_type.name === 'Code'
            });

            if (
                asset.paywall_cover_photo 
                && asset.paywall_cover_photo === coverPhoto
                && instances.getBranding(asset.id).paywall_cover_photo
            ) {
                    view.paywall_cover_photo = instances.getBranding(asset.id).paywall_cover_photo;
                }
            
            el.html(Mustache.render(templates.preview, view));

            handleDataHTML(utils.getElement(asset.id).find('[data-html]'));

            updateUserMenu(self);

            events.global.trigger('preview_' + asset.id);
        },

        restrictedModal: function(restrictionMsg){
            Modal.open(Forms.restricted_modal, state, function(){
                userMenu.hideInModal();
                $('#inplayer-language-menu').hide();
            }, true, {
                message: restrictionMsg
            });
        },

        login: function () {
            updateState({step: steps.login_register});

            Modal.open(Forms.login, state, function () {
                $('.' + classes.register_link + ' a,a.' + classes.register_link).on('click', function(e){
                    e.preventDefault();
                    self.signUp();
                });

                $('#inplayer-forgot-password').on('click', function(e){
                    e.preventDefault();
                    self.forgotPass();
                });

                var social_state = btoa(JSON.stringify({
                    client_id: state.options.oauthAppKey || state.merchant_uuid,
                    redirect: utils.buildUrlQuery(window.location, {
                        asset_id: state.current_asset.id
                    }),
                    referrer: utils.getReferrer()
                }));

                resources.getSocialLoginUrls(social_state,
                    function (socialProviders) {
                        renderSocialLoginBtns(socialProviders.social_urls);
                    }
                );
            });
        },

        signUp: function () {
            updateState({step: steps.login_register});
            Modal.open(Forms.register, state, function () {
                $('.' + classes.login_link + ' a,a.' + classes.login_link).on('click', function(e){
                    e.preventDefault();
                    self.login();
                });

                var social_state = btoa(JSON.stringify({
                    client_id: state.options.oauthAppKey || state.merchant_uuid,
                    redirect: utils.buildUrlQuery(window.location, {
                        asset_id: state.current_asset.id
                    }),
                    referrer: utils.getReferrer()
                }));

                resources.getSocialLoginUrls(social_state,
                    function (socialProviders) {
                        renderSocialLoginBtns(socialProviders.social_urls);
                    }
                );
            });
        },

        completeRegistration: function(){
            Modal.open(Forms.complete_registration, state, function(){
                updateState({step: steps.login_register});
                showAccountUsername(state.account);

                var form = $(Modal.getForm()),
                    inputs = $.extend({
                        password: '',
                        password_confirmation: ''
                    }, state.account),
                    firstnameInput = form.find(':input[name="metadata[first_name]"]'),
                    surnameInput = form.find(':input[name="metadata[surname]"]');

                if (firstnameInput.length && surnameInput.length){
                    var namesArr = (state.account.full_name || '').split(' '),
                        firstName = namesArr.shift() || '',
                        surname = namesArr.join(' ');

                    firstnameInput.val(firstName);
                    surnameInput.val(surname);
                }

                $.each(inputs, function(key, val){
                    var el = form.find('[name="' + key + '"]');
                    el.remove();
                });

                self.hideOrShowChangePasswordButton();
            });
        },

        accessCode: function(){
            var options = instances.getAll()[state.current_asset.id].options,
                params = options.codeAccess,
                view = {
                    codeAccess: {
                        inputs: [],
                        codePattern: params && params.codePattern ?
                            params.codePattern
                                .replace(/{{/g, '<%')
                                .replace(/}}/g, '%>')
                            :
                            '<%access_code%>'
                    }
                };

            if (params){
                $.each(params.inputs, function(name, placeholder){
                    view.codeAccess.inputs.push({
                        name: name,
                        placeholder: placeholder
                    });
                });
            }

            if (!params || !params.inputs.access_code){
                view.codeAccess.inputs.push({
                    name: 'access_code',
                    placeholder: lang.getText('access_code'),
                    dataPlaceholder: 'access_code'
                });
            }

            Modal.open(Forms.access_code, state, $.noop, true, view);
        },

        hideOrShowChangePasswordButton: function(){
            var changePasswordBtns = $('.inplayer-changePassword-btn'),
                register_source = state.account && state.account.metadata ? state.account.metadata.register_source : null;

            if (register_source && register_source !== 'inplayer'){
                changePasswordBtns.hide();
            }else{
                changePasswordBtns.show();
            }
        },

        forgotPass: function () {
            updateState({step: steps.login_register});
            Modal.open(Forms.forgot_pass, state, function () {
                addGoBackEvent(steps.login_register, function () {
                    self.login();
                });
            });
        },

        newPassword: function (params) {
            updateState({step: steps.login_register});
            Modal.open(Forms.new_password, state, function () {
                if (params && params.resetToken) {
                    Modal.getForm().find(':input[name="token"]').val(params.resetToken);
                }

                addGoBackEvent(steps.login_register, function () {
                    self.forgotPass();
                });
            });
        },

        changePassword: function () {
            updateState({step: steps.login_register});

            var hideBackBtn = $.isEmptyObject(state.current_asset);

            Modal.open(Forms.change_password, state, function () {
                showAccountUsername(state.account);

                if (hideBackBtn){
                    $('#' + ids.back_btn).hide();
                }
                else {
                    addGoBackEvent(steps.login_register, function () {
                        self.priceOptions();
                    });
                }
            }, true);
        },

        accountDetails: function(){
            updateState({step: steps.login_register});

            Modal.open(Forms.account_details, state, function(){
                self.hideOrShowChangePasswordButton();
                showAccountUsername(state.account);

                var form = $(Modal.getForm()),
                    fullNameInput = form.find(':input[name="full_name"]'),
                    metadataWrapper = $('.inplayer-custom-holder');

                fullNameInput.val(entities.decodeXML(state.account.full_name));

                $.each(state.account.metadata, function(name, val){
                    var field = metadataWrapper.find('[name="metadata[' + name + ']"]');

                    if (!field.length){
                        return;
                    }

                    if (field.is(':text')){
                        field.val(entities.decodeXML(val));
                    }
                    else if (field.is(':checkbox')){
                        field.prop('checked', val === 'on');
                    }
                    else if (field.is(':radio')){
                        field.filter('[value="' + val + '"]').prop('checked', true);
                    }
                    else if (field.is('select')){
                        field.val(val);
                    }
                });
            }, true);
        },

        eraseAccountConfirmPrompt: function(){
            Modal.open(Forms.confirm_erase_account, state, function(){
                addGoBackEvent(null, function(){
                    self.accountDetails();
                });
            }, true);
        },

        exportAccountConfirmPrompt: function(){
            Modal.open(Forms.confirm_export_account_data, state, function(){
                addGoBackEvent(null, function(){
                    self.accountDetails();
                });
            }, true);
        },

        eraseAccountPassword: function(){
            Modal.open(Forms.erase_account, state, function(){
                addGoBackEvent(null, function(){
                    self.accountDetails();
                });
            }, true);
        },

        exportAccountData: function(){
            Modal.open(Forms.export_account, state, function(){
                addGoBackEvent(null, function(){
                    self.accountDetails();
                });
            }, true);
        },

        priceOptions: function () {
            if (!resources.isLoggedIn()) {
                self.login();
                return;
            }

            updateState({step: steps.price_opt});

            resources.findAccessFeesForAsset(state.current_asset.id, function (accessFees) {
                var accessFeesIds = _.pluck(accessFees, 'id');

                if (state.access_fee.id && accessFeesIds.indexOf(state.access_fee.id) === -1){
                    updateState({access_fee: {}});
                }

                updateState({access_fees: accessFees});

                if (utils.isFreemium(accessFees)){
                    Forms.dispatch(Forms.price_opts, null, state);
                    return;
                }

                Modal.open(Forms.price_opts, state, function () {
                    showAccountUsername(state.account);

                    $('.inplayer-price-desc').first().html(state.current_asset.title);
                    
                    $('.inplayer-custom-text-' + state.current_asset.merchant_id).show();

                    var options = $('#' + ids.access_fees);

                    appendPriceOptions(options, accessFees.filter(function(accessFee){
                        if (accessFee.hasOwnProperty('expires_at')){
                            var now = utils.getTimestamp();
                            return now < accessFee.expires_at;
                        }

                        return true;
                    }));

                    var accessFee = state.access_fee;

                    if (!$.isEmptyObject(accessFee)){
                        selectOptionByValue(options, accessFee.id);
                        
                        if(accessFee.item_type === 'package') {
                            $('.inplayer-price-desc').first().html(accessFee.item_description);
                        }
                    }

                    options.on('change', function () {
                        var id = this.value;
                        $('.inplayer-price-desc').first().html(state.current_asset.title);

                        $.each(accessFees, function(i, fee) {
                            if(Number(id)  === fee.id && fee.item_type === 'package') {
                                $('.inplayer-price-desc').first().html(fee.item_description);
                                return false;
                            }
                        });
                    });

                    self.hideOrShowChangePasswordButton();
                });
            }, function () {
                error('console_asset_no_access_fees');
            });
        },

        paymentMethod: function(backFromPayment){
            if (backFromPayment && state.original_access_fee){
                state.original_access_fee.payment_method = '';
                updateState({access_fee: state.original_access_fee});
                state.original_access_fee = undefined;
            }

            updateState({step: steps.payment_method});
            Modal.open(Forms.payment_method, state, function () {
                var btnHolder = $('#' + ids.payment_method_btn_holder);

                $.each(state.payment_methods, function(i, item){
                    var name = item.method_name.toLowerCase(),
                        html = Mustache.render(templates.payment_method_button, {
                            method_name: name,
                            data_text_method_name: lang.getText(name + '_method')
                        });

                    btnHolder.append(html);
                });

                addGoBackEvent(steps.price_opt, function () {
                    self.priceOptions();
                });

                $.each(ids.payment_method_btns, function(key, id){
                    $('#' + id).on('click', function(e){
                        e.preventDefault();

                        var btnId = $(e.currentTarget).prop('id');
                        Validation.clearForm();

                        if (key === 'paypal'){
                            if (state.access_fee.setup_fee){
                                Validation.setGlobalErrorMessage(lang.getText('paypal_not_supported_with_setup_fee'));
                            }
                            else {
                                getPayPalParams(btnId, function(){
                                    self.payment(key);
                                });
                            }
                        }else{
                            self.payment(key);
                        }
                    });
                });
            });
        },

        myAccount: function(){
            if (!resources.isLoggedIn()) {
                self.login();
                return;
            }

            Modal.open(Forms.my_account, state, function(){
                myAccount.init();
                updateUserMenu(self);
                self.hideOrShowChangePasswordButton();
            }, true);
        },

        getBranding: function(uuid, brandingId, asset, cb){
            resources.getBranding(uuid, brandingId, function (res) {
                var brand = res.brand;

                brand.paywall_buttons_hover_bg_color = utils.lightenColor(brand.paywall_buttons_bg_color, 40);
                brand.paywall_buttons_disabled_bg_color = utils.lightenColor(brand.paywall_buttons_hover_bg_color, 90);

                if (asset){
                    instances.setBranding(asset.id, brand);
                }

                if (res.isDefaultBrand) {
                    updateState({brand: brand});
                }

                if (!brand.preview_top_border) {
                    $('.inplayer-preview-box').removeClass('inplayer-branding-top');
                }else{
                    $('.inplayer-preview-box').addClass('inplayer-branding-top');
                }

                $.isFunction(cb) && cb();
            }, function () {
                $.isFunction(cb) && cb();
            });
        },

        payment: function (method) {
            if (!resources.isLoggedIn()) {
                self.login();
                return;
            }

            showAccountUsername(state.account);
            updateState({step: steps.pay});

            if (method === 'paypal'){
                self.paypal();
            }else {
                if (!state.new_card_payment) {
                    resources.getPaymentTools(resources.token(), state.payment_method, function (savedCards) {
                        self._savedCardsPayment(savedCards);
                    }, function () {
                        self.card();
                    });
                }
                else {
                    self.card();
                }
            }
        },

        card: function(){
            Modal.open(Forms.payment, state, function(){
                self._newPayment('payment');
                $('.inplayer-custom-text-' + state.current_asset.merchant_id).show();
                var form = $(Modal.getForm());
                payform.cardNumberInput(form.find(':input[name="number"]')[0]);
                payform.cvcInput(form.find(':input[name="cvv"]')[0]);
                payform.expiryInput(form.find(':input[name="card_expiry"]')[0]);
            });
        },

        paypal: function(){
            Modal.open(Forms.paypal, state, function(){
                state.access_fee.payment_method = 'paypal';
                self._newPayment('paypal');
            });
        },

        revokeAccess: function(assetId, reinject){
            var element = utils.getElement(assetId),
                instance = instances.getAll()[assetId];

            element.empty();

            events.global.trigger('invoke_modal_' + assetId);
            setTimeout(function(){
                Validation.setGlobalErrorMessage(lang.getText('access_expired_or_revoked'));
            }, 100);

            reinject(assetId, instance.merchantUuid, instance.options, instance.external);
        },

        _newPayment: function(method){
            var form = $(Modal.getForm());

            form.find(':input[name="price"]').val(state.access_fee.amount);

            var btnTextEntry = state.access_fee.access_type.name === 'subscription' ? 'payment_subscribe' : 'payment_pay';
            $('#inplayer-pay-submit').find('.ladda-label').html(lang.getText(btnTextEntry)).attr('data-text', btnTextEntry);

            if (state.access_fee.access_type.name !== 'subscription') {
                $('#' + 'inplayer-subscription').hide();
            }

            if (state.access_fee.trial_period){
                if (state.access_fee.trial_period.used) {
                    appendUsedTrialText();
                }
                else if (state.access_fee.trial_period.quantity > 0) {
                    appendFreeTrialText(state.access_fee);
                }
            }

            if (state.access_fee.setup_fee){
                var fee = state.access_fee;

                $.extend(state.non_lang_texts, {
                    setup_fee_description: '<b>' + fee.setup_fee.description + '</b>',
                    setup_fee_amount: fee.setup_fee.fee_amount,
                    payment_fee_amount: fee.amount,
                    fee_currency: fee.currency
                });

                $('#' + ids.additional_fee).siblings('span').html(fee.setup_fee.description);
                $('#inplayer-additional-fee').html(lang.getText('additional_fee_text'));
            }
            else {
                $('#inplayer-additional-fee').removeAttr('data-htmltext');
            }

            var methods = state.payment_methods;
            if (methods.length === 1 && methods[0].method_name.toLowerCase() === 'card'){
                addGoBackEvent(steps.price_opt, function () {
                    self.priceOptions();
                });
            }else {
                addGoBackEvent(steps.payment_method, function () {
                    var fromBackBtn = true;
                    self.paymentMethod(fromBackBtn);
                });
            }

            $('.inplayer-discount-holder input[type="checkbox"]').on('click', function(e){
                var me = $(e.target);
                if (!me.is(':checked')){
                    Validation.setGlobalErrorMessage('');
                    form.find(':input[name="voucher_code"]').val('');

                    updateState({
                        access_fee: state.original_access_fee
                    });

                    state.original_access_fee = undefined;
                    setItemDetails(state.current_asset, state.access_fee);

                    togglePaymentform(form, false);
                }
            });

            $('#' + ids.discount_btn).on('click', function(e){
                e.preventDefault();

                Validation.setGlobalErrorMessage('');
                var voucherCode = form.find(':input[name="voucher_code"]').val();

                var data = new FormData();
                data.append('voucher_code', voucherCode);
                data.append('merchant_id', state.current_asset.merchant_id);
                data.append('access_fee_id', state.access_fee.id);

                var brand_id = instances.getBranding(state.current_asset.id).id;
                if (brand_id){
                    data.append('branding_id', brand_id);
                }

                state.original_access_fee = state.original_access_fee || $.extend({}, state.access_fee);

                Forms.startLoading(Forms.buttons[method]);
                resources.getDiscount(resources.token(), data, function (res) {
                    var newFee = state.access_fee;
                    newFee.amount = res.amount;
                    newFee.voucher_code = voucherCode;
                    setItemDetails(state.current_asset, newFee);

                    form.find(':input[name="price"]').val(res.amount);

                    togglePaymentform(
                        form,
                        res.amount === 0 && state.access_fee.access_type.name !== 'subscription'
                    );

                    if (state.access_fee.payment_method === 'paypal'){
                        getPayPalParams(null, function(){
                            Forms.stopLoading();
                        });
                    }else{
                        Forms.stopLoading();
                    }
                }, function (err) {
                    Forms.stopLoading();

                    Validation.showErrors(null, Forms.getErrorsFromResponse(err));
                    togglePaymentform(form, false);

                    state.access_fee.voucher_code = '';
                });
            });

            setItemDetails(state.current_asset, state.access_fee);
        },

        _newCardPayment: function () {
            Modal.open(Forms.payment, state, function () {
                payform.cardNumberInput(form.find(':input[name="number"]')[0]);
                payform.cvcInput(form.find(':input[name="cvv"]')[0]);
                payform.expiryInput(form.find(':input[name="card_expiry"]')[0]);
            });
        },

        _savedCardsPayment: function(savedCards) {
            Modal.open(Forms.saved_cards, state, function () {
                var cards = $('#' + ids.saved_cards);

                appendSavedCardsOptions(cards, savedCards);
                appendStarsAndMetaToInput(savedCards[0].meta);

                var paymentTool = state.payment_tool;
                if (paymentTool) {
                    selectOptionByValue(cards, paymentTool);
                }

                var methods = state.payment_methods;
                if (methods.length === 1 && methods[0].method_name.toLowerCase() === 'card'){
                    addGoBackEvent(steps.price_opt, function () {
                        self.priceOptions();
                    });
                }else {
                    addGoBackEvent(steps.payment_method, function () {
                        var fromBackBtn = true;
                        self.paymentMethod(fromBackBtn);
                    });
                }

                $('#' + ids.use_new_card_link).on('click', function(e){
                    e.preventDefault();
                    updateState({new_card_payment: true, payment_tool: null});
                    self.payment();
                });

                cards.on('change', function(e){
                    var options = e.target;
                    updateState({payment_tool: options.value});
                    appendStarsAndMetaToInput(options.options[options.selectedIndex].dataset.meta);
                });
            });
        },

        getButtonById: function (id) {
            var btn = $('#' + id);
            return {
                loader: Ladda.create(btn),
                button: btn
            };
        },

        notifyAccount: function (instructions, cb) {
            resources.account(resources.token(), function (account) {
                updateState({account: account});
                showAccountUsername(account);
                Notif.notify(state);

                events.global.trigger('account', account);

                cb && cb();
            }, function () {
                resources.logout(cb);
            }, instructions);
        },

        isAccessCodeAsset: function(){
            var currAsset = state.current_asset;

            if (!currAsset.access_control_type){
                return false;
            }

            var controlType = currAsset.access_control_type,
                typeName = controlType.name || '';

            return controlType.auth !== true && typeName.toLowerCase() === 'code';
        }
    };

    return self;
})();

//Watch forms on submit
Forms.registerListener(function (res) {
    handleFormSubmit(res, self);
});

resources.onLogout(function (invalid) {
    if (invalid) {
        Notif.unsubscribe();

        if (Modal.isShown() && !self.isAccessCodeAsset()) {
            resources.getRegisterFields(state.merchant_uuid, function(response){
                if (response && response.collection && response.collection.length){
                    updateState({
                        registerFields: response.collection
                    });
                }

                self.login();
            });
        }
    }

    updateUserMenu();
});

function updateUserMenu(self){
    userMenu.init(self);

    var user_menu_wrapper = $('.' + classes.user_menu_wrapper);
    var user_menu_in_content = $('#' + ids.user_menu_in_content);
    var preview_footer = $('.' + classes.preview_footer);
    var standalone_login_btn = $('.' + classes.standalone_btn + '.' + classes.login_btn);
    var standalone_logout_btn = $('.' + classes.standalone_btn + '.' + classes.logout_btn);
    var standalone_myaccount_btn = $('.' + classes.standalone_btn + '.' + classes.myaccount_btn);

    if (resources.isLoggedIn()){
        user_menu_wrapper.removeClass(classes.logged_out);
        user_menu_in_content.removeClass(classes.logged_out);
        preview_footer.addClass(classes.logged_in);
        standalone_login_btn.addClass(classes.logged_in);
        standalone_logout_btn.removeClass(classes.logged_out);
        standalone_myaccount_btn.removeClass(classes.logged_out);
        showAccountUsername(state.account);
    }else{
        user_menu_wrapper.addClass(classes.logged_out);
        user_menu_in_content.addClass(classes.logged_out);
        preview_footer.removeClass(classes.logged_in);
        standalone_login_btn.removeClass(classes.logged_in);
        standalone_logout_btn.addClass(classes.logged_out);
        standalone_myaccount_btn.addClass(classes.logged_out);
    }
}

function handleFormSubmit(res, self) {
    switch (res.name) {
        case Forms.login:
            if (res.success) {
                var response = res.response;
                var assets = state.assets;

                if (!$.isEmptyObject(state.current_asset)) {
                    assets[state.current_asset.id].checked = true;
                    updateState({assets: assets});
                }
                
                if (!state.standalone) {
                    resources.checkAccessForAsset(response.access_token, state.current_asset.id, function (assetContent) {
                        Injector.inject(assetContent.item, function () {
                            Modal.close();
                        }, noop);
                    }, function () {
                        self.priceOptions();
                    });
                }else{
                    Modal.close();
                }

                self.notifyAccount();
                updateUserMenu();

                checkForAccessForAllAssets(response.access_token);
            }
            break;
        case Forms.register:
            if (res.success) {
                if (!state.standalone) {
                    var data = res.response;
                    data.asset = state.current_asset;

                    events.global.trigger('login', data);
                    events.global.trigger('register', data);

                    self.priceOptions();
                    self.notifyAccount();
                }else{
                    events.global.trigger('login', res.response);
                    events.global.trigger('register', res.response);
                    if (state.options.global && state.options.global.freeAccess){
                        updateState({access_fees: [{
                            id: state.options.global.freeAccess,
                            access_type: {
                                name: 'freemium'
                            }
                        }]});
                        Forms.dispatch(Forms.price_opts, null, state);
                    }

                    self.notifyAccount();

                    Modal.close();
                }

                updateUserMenu();
            }
            break;
        case Forms.complete_registration:
            if (res.success){
                self.priceOptions();
                self.notifyAccount();
            }
            break;
        case Forms.account_details:
            if (res.success){
                Forms.stopLoading();
                Validation.setGlobalInfoMessage(lang.getText('account_updated'));
            }
            break;
        case Forms.access_code:
            if (res.success){
                events.global.trigger('access', {
                    asset_id: res.response.item_id,
                    external_id: undefined,
                    hasAccess: true
                });

                var item = {
                    id: res.response.item_id,
                    merchant_uuid: state.merchant_uuid,
                    merchant_id: state.current_asset.merchant_id,
                    content: res.response.content,
                    access_type: 'code',
                    item_type: {
                        host: 'inplayer',
                        content_type: 'html'
                    }
                };

                Injector.inject(item, function(){
                    Modal.close();
                }, function(){
                    Forms.stopLoading();
                });
            }
            break;
        case Forms.price_opts:
            if (res.success) {
                if (res.hasAccess) {
                    if (!state.standalone) {
                        Injector.inject(res.response.item, function () {
                            Modal.close();
                        }, function(){
                            Forms.stopLoading();
                        });
                    }else {
                        Forms.stopLoading();
                        checkForAccessForAllAssets(resources.token());
                    }
                } else {
                    updateState({access_fee: res.response});

                    var getPaymentMethods = function(){
                        resources.getPaymentMethods(resources.token(), function(methods){
                            updateState({
                                payment_methods: methods
                            });

                            if (methods.length === 1 && methods[0].method_name.toLowerCase() === 'card'){
                                self.payment();
                            }else{
                                self.paymentMethod();
                            }
                        }, function(res){
                            Forms.stopLoading();
                            Validation.showErrors(null, Forms.getErrorsFromResponse(res));
                        });
                    };

                    var r = res.response;
                    if (r.access_type.name === 'subscription' && r.trial_period){
                        resources.checkForUsedTrial(state.current_asset.id, function(e){
                            r.trial_period = {
                                used: true
                            };
                            getPaymentMethods();
                        }, function(e){
                            getPaymentMethods();
                        });
                    }else {
                        getPaymentMethods();
                    }
                }
            }
            break;
        case Forms.forgot_pass:
            if (res.success) {
                self.newPassword();
            }
            break;
        case Forms.new_password:
            if (res.success) {
                self.login();
            }
            break;
        case Forms.change_password:
            if (res.success) {
                Forms.stopLoading();
                Validation.setGlobalInfoMessage(lang.getText('password_updated'));
            }
            break;
        case Forms.paypal:
            Forms.stopLoading();
            break;
        case Forms.payment:
            Forms.stopLoading();
            break;
        case Forms.confirm_erase_account:
            Forms.stopLoading();
            self.eraseAccountPassword();
        case Forms.erase_account:
            Forms.stopLoading();
            break;
        case Forms.confirm_export_account_data:
            Forms.stopLoading();
            self.exportAccountData();
            break;
        case Forms.export_account:
            Forms.stopLoading();

            if (res.success){
                self.accountDetails();
                Validation.setGlobalInfoMessage(lang.getText('your_account_data_is_being_exported'));
            }

            break;
        case Forms.saved_cards:
            Forms.stopLoading();
            error('console_saved_cards_not_implemented');
            break;
        default:
            Forms.stopLoading();
    }

    if (!res.success){
        Forms.stopLoading();
    }
}

function showAccountUsername(account) {
    if (account) {
        userMenu.updateEmail(account.email);
    }
}

function appendPriceOptions(el, options) {
    if (options.length) {
        $.each(options, function (i, opt) {
            var html = opt.description + (opt.access_type.name !== 'freemium' ? ' - ' + opt.amount + ' ' + opt.currency : '');
            $('<option></option>', {
                value: opt.id
            }).html(html).appendTo(el);
        });
    }else{
        el.attr('disabled', true);
        $('<option value="" disabled selected></option>')
            .html(lang.getText('sales_currently_not_available'))
            .appendTo(el);

        $('#' + ids.price_submit).attr('disabled', 'disabled').addClass('inplayer-price-btn-disabled');
    }
}

function appendFreeTrialText(accessFee) {
    var trialPeriod = accessFee.trial_period;
    var days = trialPeriod.quantity + ' ' + trialPeriod.period;
    var text = lang.getText('paywall_free_trial_text', [days, accessFee.amount, accessFee.currency]);
    var span = $('<span>' + text + '</span>');
    $('#inplayer-free-trial').text(days + 's free: ').append(span);
}

function appendUsedTrialText(){
    var container = $('#inplayer-used-trial-warning');
    container.show();
    container.children('span').text(lang.getText('used_trial_period'));
}

function selectOptionByValue(el, value) {
    el.val(value).change();
}

function appendSavedCardsOptions(el, cards) {
    $.each(cards, function(i, card) {
        $('<option></option>', {
            'data-meta': card.meta
        }).val(card.tool_token).html(card.brand + ' - ' + card.meta).appendTo(el);
    });
}

function appendStarsAndMetaToInput(meta) {
    $('#' + ids.saved_card_input).val('**** **** **** ' + meta);
}

function checkForAccessForAllAssets(token) {
    $.each(state.assets, function(i, a) {
        if (a.id !== state.current_asset.id) {
            resources.checkAccessForAsset(token, a.id, function (asset) {
                Injector.inject(asset.item, noop, noop);
            }, noop);
        }
    });
}

function addGoBackEvent(step, cb) {
    $('#' + ids.back_btn).on('click', function(e){
        e.preventDefault();
        Modal.updateProgressBar(step);

        cb && cb();
    });
}

function setItemDetails(asset, accessFee) {
    var discountText = accessFee.amount === 0 ? ' ' + lang.getText('paywall_full_discount') : '';
    if (accessFee.item_type === 'package') {
        $('#' + ids.item_ordered).html(accessFee.item_description);
    }
    else {
        $('#' + ids.item_ordered).html(asset.preview_title);
    }

    $('#' + ids.item_price).html(accessFee.amount + " " + accessFee.currency + discountText);

    var additionalFeeElement = $('#' + ids.additional_fee).parent();

    if (accessFee.setup_fee){
        if (accessFee.amount === 0){
            $('#' + ids.additional_fee).html(accessFee.amount + " " + accessFee.currency + discountText);
        }
        else {
            $('#' + ids.additional_fee).html(accessFee.setup_fee.fee_amount + ' ' + accessFee.currency);
        }

        additionalFeeElement.show();
    }
    else {
        additionalFeeElement.hide();
    }
}

function togglePaymentform(form, flag) {
    $.each(['card_expiry', 'number', 'cvv', 'card_name'], function(i, name){
        form.find(':input[name="' + name + '"]').prop({
            disabled: flag,
            novalidate: flag
        });
    });
}

function handleDataHTML(els){
    if (!els.length){
        return;
    }

    lang.dataHtml(els);
}

function renderSocialLoginBtns(socialProviders) {
    var loginContainer = document.getElementById('inplayer-social-login');
    if (loginContainer) {
        loginContainer.innerHTML = "";

        $.each(socialProviders, function (i, provider) {
            for (var key in provider) {
                if (provider.hasOwnProperty(key)) {
                    var a = document.createElement('a');
                    var text = document.createTextNode(key);
                    a.href = provider[key];
                    a.title = key;
                    a.className += "inplayer-social-button " + key; 
                    a.appendChild(text);
                    loginContainer.appendChild(a);
                }
            }
        });
    }
}

function getPayPalParams(ownBtn, callback){
    var form = $(Modal.getForm());

    if (ownBtn) {
        Forms.startLoading(ownBtn);
    }

    var data = {
        origin: $(window.top.location).attr('href'),
        access_fee: state.access_fee.id,
        payment_method: 2,
        voucher_code: state.access_fee.voucher_code
    };

    var brand_id = instances.getBranding(state.current_asset.id).id;
    if (brand_id){
        data.branding_id = brand_id;
    }

    var paramForm = new FormData();
    $.each(data, function(key, val){
        if (val) {
            paramForm.append(key, val);
        }
    });

    resources.getPayPalParams(resources.token(), paramForm, function(data){
        updateState({
            paypal: {
                endpoint: data.endpoint
            }
        });

        Forms.stopLoading();

        callback && callback();
    }, function(error){
        var msg = error.errors && error.errors && error.errors.explain ? error.errors.explain : error.message || error;
        updateState({
            paypal: {
                endpoint: null,
                message: null
            },
            message: msg
        });

        Forms.stopLoading();

        Validation.setGlobalErrorMessage(state.message);
    });
}

function noop() {
}