var $ = require('jquery'),
	supported = ['init', 'access', 'inject', 'login', 'register', 'logout', 'payment', 'language'],
	global = $({}),
	allHandlers = [];

$.each(supported, function(i, type){
    global.on(type, function(e, data){
        global.trigger('any', {
            type: type,
            data: data
        });
    });
});

module.exports = (function(){
	return {
		global: global,
		setHandlers: function(handlers){
			if (allHandlers.indexOf(handlers) >= 0){
				return;
			}

			allHandlers.push(handlers);

			var onInject = handlers.onInject || handlers.onComplete;
			if (onInject){
				handlers.onInject = onInject;
			}

			if (handlers.onAny){
			    global.on('any', function(e, data){
			        handlers.onAny(data);
                });
            }

			$.each(supported, function(i, type){
			    var methodName = 'on' + type.charAt(0).toUpperCase() + type.substr(1),
                    handler = handlers[methodName];

					if (handler){
			        global.on(type, function(e, data){
			            handler(data);
                    });
                }
            });
		}
	};
})();