var localStorage = window.localStorage,
    supported = (function(){
        var answer = false;

        if (localStorage && localStorage.setItem) {
            try {
                localStorage.setItem('testKey', '1');
                localStorage.removeItem('testKey');

                answer = true;
            }
            catch (_) {}
        }

        return answer;
    })();

var storageVar = {};

module.exports = {
    setItem: function(key, val){
        if (supported){
            localStorage.setItem(key, val);
        }
        else {
            storageVar[key] = val;
        }
    },
    getItem: function(key){
        if (supported){
            return localStorage.getItem(key);
        }
        else {
            return storageVar[key];
        }
    },
    removeItem: function(key){
        if (supported){
            localStorage.removeItem(key);
        }
        else {
            storageVar[key] = null;
        }
    }
};