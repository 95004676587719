'use strict';

require('./assets/css/app.css');
require('./assets/css/preview.css');
require('./assets/css/progress-wizard.css');

var Paywall = require('./scripts/paywall.js')
    , resources = require('./scripts/inplayer-api.js')
    , Injector = require('./scripts/content-injection.js')
    , $ = require('jquery')
    , utils = require('./scripts/utils.js')
    , instances = require('./scripts/instances.js')
    , lang = require('./scripts/language.js')
    , events = require('./scripts/global-events.js')
    , error = lang.error
    , playerParams = require('./scripts/player-params')
    , state = require('./scripts/state')
    , sso = require('./scripts/sso')
    , Options = require('./scripts/options')
    ;

$(document).ready(function(){
    if (utils.is_iOS()){
        $('body').addClass('inplayer-ios');
    }
});

var initiated = false;

module.exports = {
    inject: function(){
        var merchantUuid = arguments[1],
            options = arguments[2] = Options.organize(arguments[2]);
        
        checkSSO(merchantUuid, options, inject).apply(undefined, arguments);
    },
    standalone: function(){
        var merchantUuid = arguments[0],
            options = arguments[1] = Options.organize(arguments[1]);

        checkSSO(merchantUuid, options, standaloneLogin).apply(undefined, arguments);
    },
    setLang: setLanguage
};

Injector.setReinjector(inject);

events.global.on('access.revoked', function(e, assetId){
    if (!instances.find(assetId)){
        return;
    }

    Paywall.revokeAccess(assetId, inject);
});

function onEstablished(instructions) {
    if (instructions && instructions.removeTokens){
        resources.removeTokens();
    }

    if (!resources.isLoggedIn()) {
        utils.parseHash(window.location.hash, function (err, response) {
            if (err) {
                $.extend(state.query_params, utils.getQueryParamsObject(window.location, ['asset_id']));
                utils.removeHash(['error']);
                state.current_error = err.state;

                return;
            }

            if (!response) {
                return;
            }

            if (response.hasOwnProperty('token')) {
                resources.saveTokens(response);

                Paywall.notifyAccount({}, function(){
                    if (!sso || !sso.isEnabled()){
                        return;
                    }

                    response.access_token = response.access_token || response.token;

                    sso.sendToken(response.access_token);
                });

                $.extend(state.query_params, utils.getQueryParamsObject(window.location, ['asset_id']));
                utils.removeHash(['token', 'expires', 'refresh_token']);

                state.returned_from_social = true;
            }
            else if (response.hasOwnProperty('reset-password')) {
                $.extend(state.query_params, utils.getQueryParamsObject(window.location, ['reset_token']));
                utils.removeHash(['reset-password']);
                events.global.one('paywall_init', function () {
                    resources.getRegisterFields(state.merchant_uuid, function (response) {
                        if (response && response.collection && response.collection.length) {
                            state.registerFields = response.collection;
                        }

                        state.standalone = true;

                        Paywall.newPassword({
                            resetToken: state.query_params['reset_token']
                        });
                    });
                });
            }
        });
    } else {
        Paywall.notifyAccount();
    }
}

if (window && !window.inject){
    window.inject = module.exports.inject;
}

function checkSSO(merchantUuid, options, callback){
    options = options || {};
    state.oauth_app_key = options.oauthAppKey;
    
    return function(){
        var args = arguments;

        if (options.ssoDomain){
            sso.checkForSession(options.ssoDomain, options.oauthAppKey || merchantUuid, function(){
                onEstablished();
                callback.apply(undefined, args);
            }, function(){
                onEstablished({
                    removeTokens: true
                });
                callback.apply(undefined, args);
            });
        }
        else {
            onEstablished();
            callback.apply(undefined, args);
        }
    };
}

function inject(assetId, merchantUuid, options) {
    if (!assetId){
        throw 'Asset id is mandatory';
    }

    if ($.isArray(assetId) || $.isPlainObject(assetId)){
        $.each(assetId, function(i, item){
            if (typeof i === 'number' || i === 'inplayer'){
                inject(item, merchantUuid, options);
            }else if (typeof i === 'string'){
                var items = $.isArray(item) ? item : [ item ];
                $.each(items, function(j, externalId){
                    inject(i + ':' + externalId, merchantUuid, options);
                });
            }
        });

        return;
    }

    options = options || {};
    playerParams.setupScripts(options);
    playerParams.setupParams(options);

    assetId = assetId.toString().split(':');
    if (assetId.length === 2){
        var type = assetId[0];
        var external_id = assetId[1];

        resources.findFromExternalId(type, external_id, merchantUuid, (function(external_id){
            return function(asset){
                var div_id = 'inplayer-' + type + '-' + external_id;
                var element = $('#' + div_id);
                var inplayer_id = asset.id.toString();

                if (!element.length){
                    error('console_element_doesnt_exist', div_id);
                }

                element.attr('inplayer-id', inplayer_id);
                inject(inplayer_id, asset.merchant_uuid, options, {
                    type: type,
                    id: external_id
                });
            };
        })(external_id), function(data){
            if (data.errors && data.errors.explain){
                throw data.errors.explain;
            }
        });

        return;
    }

    assetId = assetId[0];

    events.setHandlers(options);
    lang.setOptions(options);
    state.global = $.extend(state.global, options.global);

    var element = utils.getElement(assetId);

    if (!initiated) {
        initiated = true;
        events.global.trigger('init', {
            isLoggedIn: resources.isLoggedIn(),
            isStandalone: false
        });
    }

    var external = arguments[3];

    if (resources.isLoggedIn()) {
        var token = resources.token();

        if (external){
            instances.add(assetId, merchantUuid, options, external);
        }

        resources.checkAccessForAsset(token, assetId, function (asset) {
            instances.add(assetId, merchantUuid, options, external);
            Paywall.init(asset, merchantUuid, options, function () {
                Injector.inject(asset.item, function () {
                }, function (err) {
                    //@todo - show errors on injection fail
                });
            });
        }, function () {
            injectPreview(element, assetId, merchantUuid, options, external);
        }, {
            merchantUuid: merchantUuid,
            onNoToken: function(){
                injectPreview(element, assetId, merchantUuid, options, external);
            }
        });
    } else {
        events.global.trigger('access', {
            asset_id: assetId,
            external_id: external ? external.id : instances.getExternalId(assetId),
            hasAccess: false
        });

        injectPreview(element, assetId, merchantUuid, options, external);
    }
}

function injectPreview(element, assetId, merchantUuid, options, external) {
    resources.findOneAsset(assetId, merchantUuid, function (asset) {
        instances.add(assetId, merchantUuid, options, external);
        var view = $.extend(createPreviewObj(asset.metadata), asset);
        Paywall.init(view, merchantUuid, options, function () {
            Paywall.preview(element, view, options.noPreview);
        });
    }, function (e) {
        if (e.code === 403){
            instances.add(assetId, merchantUuid, options, external, e.message);

            Paywall.init(e, merchantUuid, options, function () {
                Paywall.restrictedPreview(element, e, options.noPreview);
            });

            return;
        }

        // Try finding a package by that id..
        resources.findOnePackage(assetId, function (pack) {
            instances.add(assetId, merchantUuid, options, external);
            Paywall.init(pack, merchantUuid, options);
        }, function (err) {
            if (err.errors){
                $.each(err.errors, function(code, error){
                    throw 'Error ' + code + ': ' + error + ' (asset id ' + assetId + ')';
                });
            }
        });
    });
}

function createPreviewObj(metadata) {
    var view = {};
    if ($.isArray(metadata) && metadata.length){
        $.each(metadata, function(i, meta){
            view[meta.name] = meta.value;
        });
    }
    
    return view;
}

function standaloneLogin(uuid, options){
    options = options || {};

    state.global = $.extend(state.global, options.global);
    lang.setOptions(options);

    if (!initiated) {
        initiated = true;
        events.global.trigger('init', {
            isLoggedIn: resources.isLoggedIn(),
            isStandalone: true
        });
    }

    Paywall.standalone(uuid, options);
}

function setLanguage(name, silent){
    var langBtn = $('#inplayer-language-menu li[data-entry="' + name + '"]');

    if (langBtn.length){
        langBtn.click();
        return true;
    }

    return lang.select(name, true, silent);
}