module.exports = {
	preview_title: "",
	preview_description: "",
	preview_image: "",
	preview_button_label: "Buy",
	preview_buttons_bg_color: "",
	preview_buttons_text_color: "",
	preview_top_border: true,
	inplayer_protected_label: true,
	paywall_brand_logo: "",
	paywall_buttons_bg_color: "",
	paywall_buttons_text_color: "",
	paywall_cover_photo: "",
	paywall_primary_color: "",
	paywall_secondary_color: "",
	brand_name: "InPlayer",
	paywall_footer: "",
	id: 0
};
