var $ = require('jquery'),
    _ = require('underscore'),
    FingerPrint = require('fingerprintjs2'),
    localStorage = require('./local-storage-wrap'),
    resources = require('./inplayer-api'),
    events = require('./global-events'),

    FINGERPRINT_KEY = 'browser_fingerprint',
    CODE_KEY = 'access_code',
    BTN_CLASS = 'inplayer-cancel-access-btn',

    onCancelHandler = null,
    assets = {};

var self = (function(){
    var actualPrint;

    $(document).on('click', '[class^="' + BTN_CLASS + '-"]', cancelAccessCode);

    return {
        getSavedPrint: function(){
            return localStorage.getItem(FINGERPRINT_KEY);
        },
        getSavedCode: function(){
            return localStorage.getItem(CODE_KEY);
        },
        generatePrint: function(cb){
            new FingerPrint().get(function(result){
                actualPrint = result;
                cb(actualPrint);
            });
        },
        removeSaved: function(){
            localStorage.removeItem(FINGERPRINT_KEY);
            localStorage.removeItem(CODE_KEY);
        },
        savePrintAndCode: function(print, code){
            localStorage.setItem(FINGERPRINT_KEY, print);
            localStorage.setItem(CODE_KEY, code);
        },
        localPrintFound: function(){
            var found = !!this.getSavedPrint() && !!this.getSavedCode();

            if (!found){
                this.removeSaved();
            }

            return found;
        },
        onCancelAccess: function(assetObj, handler){
            var id = assetObj.assetView.id;
            assets[id] = assetObj;

            if (!onCancelHandler){
                onCancelHandler = handler;
            }
        }
    };
})();

function cancelAccessCode(e){
    var $target = $(e.currentTarget),
        assetId = null;

    $.each(assets, function(id){
        if ($target.hasClass(BTN_CLASS + '-' + id)){
            assetId = id;
        }
    });

    if (!assetId){
        return;
    }

    var formdata = new FormData();
    formdata.append('id', assetId);
    formdata.append('browser_fingerprint', self.getSavedPrint());

    resources.cancelAccessCode(self.getSavedCode(), formdata, function(){
        self.removeSaved(assetId);

        var asset = assets[assetId];
        events.global.trigger('access', {
            asset_id: assetId,
            external_id: undefined,
            hasAccess: false
        });

        onCancelHandler(asset.element, asset.assetView, asset.noPreview);
    }, $.noop);
}

module.exports = self;