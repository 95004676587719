'use strict';

var resources = require('./inplayer-api.js')
    , messages = require('./messages.js')
    , factory = require('./injectors/injector-factory.js')
    , templates = require('./templates.js')
    , $ = require('jquery')
    , userMenu = require('./user-menu.js')
    , lang = require('./language.js')
    , error = lang.error
    , utils = require('./utils.js')
    ;

module.exports = {
    inject: function(asset, success, fail, addUserMenuOnly) {
        var element = utils.getElement(asset.id);

        if (resources.isLoggedIn() || asset.access_type === 'code') {
            if (!addUserMenuOnly) {
                factory.createInjector(asset.item_type).inject(element, asset, success, fail);
            }
            userMenu.init();
        } else {
            fail({errors: {message: lang.getText('message_unauthorized')}});
        }
    },
    setReinjector: function(fn){
        userMenu.setReinjector(fn);
    }
};
