var $ = require('jquery'),
	utils = require('../../utils.js'),
	unescape = require('lodash/unescape'),
	customParams = require('../../player-params');

module.exports = {
	inject: function(element, asset, success){
		try{
			var content = JSON.parse(unescape(asset.content));

			if (content && $.isPlainObject(content)){
				element.empty();

				var scripts = customParams.getScripts('laola') || [
					{ src: '//imasdk.googleapis.com/js/sdkloader/ima3.js', type: 'script' },
					{ src: '//cdn.laola1.tv/player/dist/latest/laola1player.min.js', type: 'script' },
					{ src: '//cdn.laola1.tv/player/dist/latest/laola1player.min.css', type: 'link' }
				];
				
				utils.loadScripts(scripts, function(){
					if (typeof L1VideoPlayer !== 'undefined'){
						var playerParams = {
							id: element.attr('id'),
							videoid: content.video_id,
							v5ident: "",
							partnerid: content.partner_id,
							portalid: "int",
							language: "en",
							share: true,
							adPause : "false",
							showTitle: false,
							volume: 100,
							showTimer: true,
							autoplay: true,
							configUrl: "//www.laola1.tv/service/player/videoConfig.php"
						};

						$.extend(playerParams, customParams.getParams('laola'));

						new L1VideoPlayer(playerParams);
					}
				});
				success();
			}
		}catch(e) {
			throw new Error(e);
		}
	}
};