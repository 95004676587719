var $ = require('jquery'),
	Mustache = require('mustache'),
	resources = require('./inplayer-api.js'),
	instances = require('./instances.js'),
	templates = require('./templates.js'),
	settings = require('./global-settings.js'),
	lang = require('./language.js'),
	utils = require('./utils.js'),
	state = require('./state'),
    events = require('./global-events'),
	Paywall = undefined;
	sso = require('./sso');

var classes = {
	toggle_btn: 'inplayer-toggle-menu',
	logout_btn: 'inplayer-logout-btn',
	myaccount_btn: 'inplayer-myaccount-btn',
	account_details_btn: 'inplayer-accountDetails-btn',
	changePassword_btn: 'inplayer-changePassword-btn',
	user_menu: 'inplayer-menu',
	email: 'inplayer-email',
	menu_in_modal: 'inplayer-menu-modal',
	hidden: 'inplayer-hidden'
};

var menu_buttons = [
	'.' + classes.myaccount_btn,
	'.' + classes.logout_btn,
	'.' + classes.changePassword_btn,
	'.' + classes.account_details_btn
].join(',');

var ids = {
	menu_in_content: 'inplayer-menu-content'
};

var reinject = null;

module.exports = (function(handlers){
	var root = $(document);
	var email = '';

	root.on('click', '.' + classes.toggle_btn, function(e){
		e.preventDefault();
		e.stopImmediatePropagation();

		var me = $(this);
		me.siblings('.' + classes.user_menu).toggle();
	});

	root.on('click', menu_buttons, function(e){
		e.preventDefault();
		var target = $(this);
		
		var method = target.attr('class').replace(/inplayer-standalone-btn/, '').trim().replace(/(^inplayer-|-btn$)/g, '');

		$('.' + classes.user_menu).hide();

		if ($.isFunction(handlers[method])){
			handlers[method](e);
		}else{
			console.info('handler ' + method + ' does not exist.');
		}
	});

	// handling clicking outside logout button
	root.on('click', function(e){
		var me = $(e.target);
		var menuContainers = $('.' + classes.menu_in_modal + ',#' + ids.menu_in_content);
		$.each(menuContainers, function(i, el){
			el = $(el);
			if (!el.has(e.target).length){
				el.find('.' + classes.user_menu).hide();
			}
		});
	});

	root.on('update_email', '.' + classes.email, function(){
		var me = $(this);
		me.text(email);
	});

	events.global.on('no_token', handlers.logout);

	return {
		updateEmail: function(e_mail){
			email = e_mail;
			$('.' + classes.email).trigger('update_email');
		},
		init: function(pw){
            var menu = $('#' + ids.menu_in_content);

			if (!state.global.hideUserMenu && !menu.length){
				menu = $('<div></div>', {
					id: ids.menu_in_content
				})
					.addClass('inplayer-always-visible')
					.html(Mustache.render(templates.user_menu, $.extend({}, lang.getText(), state.brand))).appendTo('body');
			}

			if (menu.length && settings.getAll().hideUserMenu){
				menu.addClass(classes.hidden);
			}

            $('.' + classes.email).trigger('update_email');

            if (pw){
                Paywall = pw;
            }
		},
		setReinjector: function(fn){
			reinject = fn;
		},
		getReinjector: function () {
			return reinject;
		},
        hideInModal: function() {
		    $('.inplayer-modal').find('.' + classes.menu_in_modal).hide();
        }
	};
})({
	changePassword: function(e) {
        if (!Paywall){
            return;
        }

        Paywall.changePassword();
	},
    accountDetails: function(e){
		if (!Paywall){
			return;
		}

		resources.getRegisterFields(state.merchant_uuid, function(response){
            if (response && response.collection && response.collection.length){
                state.registerFields = response.collection;
            }

            Paywall.accountDetails();
		});
	},
	myaccount: function(e){
        if (!Paywall){
            return;
        }

        Paywall.myAccount();
	},
	logout: function(e){
		if (!resources.logout) {
			resources = require('./inplayer-api');
		}

		resources.logout(function() {
			$.each(instances.getAll(), function(assetId, instance) {
				var element = utils.getElement(assetId);
				element.empty();

				reinject(assetId, instance.merchantUuid, instance.options, instance.external);
			});
		});
	}
});
