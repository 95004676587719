module.exports = {
	console_element_doesnt_exist: 'Element z tym id "%s" nie istnieje.',
	console_saved_cards_not_implemented: 'Zapłata zapisanymi kartami nie jest jeszcze dostępna.',
	console_form_element_not_found: 'Element formy nieznaleziony, id: %s',
	console_body_element_not_found: 'Element ciała nieznaleziony, id: %s',
	console_method_doesnt_exist: 'Metoda %s nie istnieje.',
	console_asset_no_access_fees: 'Dostęp do tego produktu jest darmowy.',
	global_ise_pls_try_again: 'Wewnętrzny błąd serwera. Prosimy spróbować ponownie.',
	message_full_name: 'Pole z pełnym imieniem nie może być puste.',
	message_email: 'Pole z adresem e-mail nie może być puste.',
    message_username: 'Pole z adresem e-mail nie może być puste.',
	message_password: 'Pole z hasłem nie może być puste.',
	message_password_confirmation: 'Potwierdzenie hasła musi pasować do hasła wyjściowego.',
	message_number: 'Numer karty jest nieprawidłowy',
	message_card_expiry: 'Data wygaśnięcia ważności karty jest nieprawidłowa.',
	message_cvv: 'Numer cvv jest nieprawidłowy.',
	message_card_name: 'Imię właściciela karty nie powinno być puste.',
	message_unauthorized: 'Błędny token. Prosimy o ponowne zalogowanie.',
	subs_no_active_subscriptions: 'Brak aktywnych subskrybcji',
	subs_created_on: 'Założone',
	subs_next_billing_on: 'Następne rozliczenie',
	paywall_free_trial_text: 'Opłacenie tego produktu powoduje subskrypcję %s z darmowym okresem próbnym. Opłata będzie pobrana %s %s po zakończeniu okresu próbnego. Zawsze możesz anulować subskrypcję przed końcem tego okresu.',
	paywall_full_discount: '100% zniżki',
	placeholder_email_address: 'Adres e-mail',
	placeholder_password: 'Hasło',
	placeholder_repeat_password: 'Powtórz hasło',
	placeholder_name_on_card: 'Imię właściciela karty',
	placeholder_voucher_code: 'kod promocyjny',
	placeholder_full_name: 'Pełne imię',
    placeholder_first_name: 'Imię',
    placeholder_surname: 'Nazwisko',
	placeholder_mm_yyyy: 'MM / RRRR',
	tooltip_forgot_password: 'zapomniałem/am hasła',
	tooltip_cancel_subscription: 'Anuluj subskrybcję',
	modal_enter_your_email: 'Wpisz swój adres e-mail.',
	modal_submit: 'Zatwierdź',
	modal_back: 'cofnij',
	modal_login: 'zaloguj się',
	modal_sign_up: 'zapisz się',
	modal_register_capital: 'ZAREJESTRUJ SIĘ',
	modal_login_capital: 'ZALOGUJ SIĘ',
	modal_choose_price: 'WYBIERZ CENĘ',
	modal_payment_details: 'SZCZEGÓŁY PŁATNOŚCI',
	modal_login_to_your_account: 'Zaloguj się na swoim <%brand_name%> koncie',
	modal_dont_have_one: 'Nie masz konta?',
	modal_register_here: 'Zarejestruj się tutaj',
	modal_enter_your_new_password: 'Wpisz nowe hasło.',
	modal_please_check_your_email: 'Prosimy o sprawdzenie konta e-mail i skopiowanie zapomnianego tokenu.',
	modal_resend_token: 'Prześlij  token ponownie',
	modal_copyright: 'Prawa autorskie',
	modal_all_rights_reserved: 'Wszelkie prawa zastrzeżone.',
	modal_terms: 'Warunki',
	ladda_log_in: 'Zaloguj',
	ladda_apply: 'Aplikuj',
	ladda_next: 'Następna',
	ladda_create_account: 'Załóż konto',
	account_your_subscriptions: 'Twoje subskrypcje',
	payment_pls_enter_your_card: 'Prosimy o wpisanie numeru karty',
	payment_have_a_promotional_code: 'Masz kod promocyjny?',
	payment_im_paying_for: 'Chcę zapłacić za',
	payment_price: 'Cena',
	payment_pay: 'Zapłać',
	payment_add: 'Dodaj',
	preview_protected_by_inplayer: 'Chronione przez InPlayer',
	preview_this_premium_content: 'Dostęp do tej treści wymaga zalogowania się.',
	preview_already_have_access: 'Jeśli dostęp został już wykupiony',
	preview_login_here: 'przejdź do logowania za pomocą konta <%brand_name%>',
	prices_pls_select_price: 'Wybierz opcje cenową',
	register_register_new_account: 'Zarejestruj się <%brand_name%>',
	register_by_clicking_agree: 'Klikając na przycisk “Załóż konto” zgadzasz się na <%register_terms_of_service%> i <%register_privacy_policy%>.',
	register_terms_of_service: 'Warunki świadczenia usług',
	register_privacy_policy: 'Polityka prywatności',
	register_already_have_account: 'Masz już konto?',
	register_login_here: 'Zaloguj się tutaj',
    complete_registration_with_social_login: '',
	saved_cards_pay_with_saved: 'Czy chciałbyś zapłacić jedną z zapisanych kart?',
	saved_cards_or_enter_new_card: 'Lub użyj nowej karty tutaj',
	saved_cards_card_number: 'Numer karty',
	saved_cards_item_ordered: 'Zamówiony produkt',
	saved_cards_go_back: 'Cofnij',
	user_menu_use_different: 'Użyj innego konta',
	user_menu_my_account: 'Subskrypcje...',
	user_menu_log_out: 'Wyloguj się',
	dlc_download: 'Pobieranie',
	code: 'pl',
	lang_macedonian: 'Macedoński',
	lang_english: 'Angielski',
	lang_spanish: 'Hiszpański',
	lang_polish: 'Polski',
	lang_french: 'Francuski',
	lang_swedish: 'Szwedzki',
	select_country: 'Wybierz kraj.',
	card_payment: 'Płatność kartą',
	paypal_payment: 'Płatność PayPal',
	card_management: 'Karty',
	subscriptions: 'Subskrypcje',
	sales_currently_not_available: 'Sprzedaż obecnie niedostępna',
	account_management: 'Zarządzanie kontem'
};