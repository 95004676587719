'use strict';
var $ = require('jquery'),
    events = require('../global-events.js'),
    instances = require('../instances.js');

module.exports = {
    createInjector: function (itemType) {
        if (itemType.content_type === '' || itemType.host === ''){
            return;
        }

        var ovp = require('./' + itemType.content_type + '/' + itemType.host + '.js');

        if (!ovp){
            throw 'Invalid content type "' + itemType.content_type + '" or item host "' + itemType.host + '"';
        }

        return {
            inject: function(element, asset, success, fail){
                if (instances.getAll()[asset.id].options.noContent){
                    $.isFunction(success) && success();
                    return;
                }

                ovp.inject(element, asset, function(e){
                    $.isFunction(success) && success(e);
                    asset.external = instances.getAll()[asset.id].external;

                    events.global.trigger('inject', {
                        asset_id: asset.id,
                        merchant_id: asset.merchant_id,
                        merchant_uuid: asset.merchant_uuid,
                        external: asset.external
                    });
                }, fail);
            }
        };
    }
};
