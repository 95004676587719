var $ = require('jquery'),
    unescape = require('lodash/unescape'),
    templates = require('../../templates.js'),
    Mustache = require('mustache'),
	customParams = require('../../player-params');

module.exports = {
    inject: function(element, asset, success){
        try {
            var content = JSON.parse(unescape(asset.content));
            var params = customParams.getParams('brightcove');

            content.width = content.width || 750;
            content.height = content.height || 420;
            content.embed_id = asset.id;
            content.player_id = params.player_id ? params.player_id + '_default' : 'default_default';

            element.html(Mustache.render(templates.brightcove, content));

            $.isFunction(success) && success();
        } catch(e) {
            throw new Error(e);
        }
    }
};