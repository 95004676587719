module.exports = {
	complete_registration: 'Регистрирање',
    update_profile_data: 'Ажурирај',
    you_have_been_logged_out: 'Одјавени сте. Вашата сесија истече или го достигнавте максималниот број на дозволени истовремени сесии.',
    account_details: 'Детали за сметката',
    account_updated: 'Сметката е ажурирана',
    password_updated: 'Лозинката е ажурирана',
	message_full_name: 'Полето за име не е пополнето.',
	message_email: 'Полето за е-пошта не е пополнето.',
    message_username: 'Полето за е-пошта не е пополнето.',
	message_password: 'Лозинка не е внесена.',
	message_password_confirmation: 'Двете лозинки треба да бидат исти.',
	message_number: 'Бројот на картичката не е исправен.',
	message_card_expiry: 'Не е исправен рокот на картичката.',
	message_cvv: 'Внесениот CVV код не е исправен.',
	message_card_name: 'Името од картичката не е внесено.',
	message_unauthorized: 'Неисправен токен. Пријавете се одново.',
	subs_no_active_subscriptions: 'Нема активни претплати',
	subs_created_on: 'Претплатено на',
	subs_next_billing_on: 'Следна наплата на',
	subs_status: 'Статус',
	paywall_free_trial_text: 'Со плаќањето на оваа содржина се отвора претплата со %s бесплатна проба. По истекувањето на пробниот период ќе ви биде наплатено %s %s. Можете да ја откажете претплатата во кое било време пред истекувањето на пробниот период.',
	paywall_full_discount: 'Попуст од 100%',
	placeholder_email_address: 'E-пошта',
	placeholder_password: 'Лозинка',
	placeholder_token: 'Токен',
	placeholder_repeat_password: 'Повторена лозинка',
	placeholder_name_on_card: 'Името на картичката',
	placeholder_voucher_code: 'Код на ваучерот',
	placeholder_full_name: 'Целосно име',
    placeholder_first_name: 'Име',
    placeholder_surname: 'Презиме',
	placeholder_mm_yyyy: 'ММ / ГГГГ',
	tooltip_forgot_password: 'заборавена лозинка',
	tooltip_cancel_subscription: 'Откажи ја оваа претплата',
	modal_enter_your_email: 'Внесете ја својата E-mail адреса.',
	modal_submit: 'Поднеси',
	modal_back: 'назад',
	modal_login: 'пријавување',
	modal_sign_up: 'регистрирање',
	modal_register_capital: 'РЕГИСТРИРАЊЕ',
	modal_login_capital: 'ПРИЈАВУВАЊЕ',
	modal_choose_price: 'ИЗБОР НА ЦЕНА',
	modal_payment_details: 'ДЕТАЛИ ЗА ПЛАЌАЊЕ',
	modal_login_to_your_account: 'Пријавете се со Вашата <%brand_name%> сметка',
	modal_dont_have_one: 'Немате сметка?',
	modal_register_here: 'Регистрирајте се тука',
	modal_enter_your_new_password: 'Внесете нова лозинка.',
	modal_please_check_your_email: 'Проверете ја Вашата е-пошта и копирајте го пратениот токен.',
	modal_resend_token: 'препрати токен',
	modal_copyright: 'Авторски права',
	modal_all_rights_reserved: 'Сите права се заштитени.',
	modal_terms: 'Услови',
	modal_faq: 'Најчести прашања',
	ladda_log_in: 'Пријавување',
	ladda_apply: 'Активирај',
	ladda_next: 'Следно',
	ladda_create_account: 'Отвори сметка',
	account_your_subscriptions: 'Вашите претплати',
	payment_pls_enter_your_card: 'Внесете ја Вашата картичка',
	payment_have_a_promotional_code: 'Имате промотивен код?',
	payment_im_paying_for: 'Плаќам за',
	payment_price: 'Цена',
	payment_pay: 'Плати',
	payment_add: 'Додај',
	preview_protected_by_inplayer: 'Заштитено од InPlayer',
	preview_this_premium_content: 'За пристап до оваа содржина е потребна сметка.',
	preview_already_have_access: 'Веќе имате пристап?',
	preview_login_here: 'Пријавете се тука со Вашата <%brand_name%> сметка.',
	prices_pls_select_price: 'Изберете ценовна опција',
	register_register_new_account: 'Отворете нова <%brand_name%> сметка',
	register_by_clicking_agree: 'Кликнувајќи на копчето "Отвори сметка" се согласувате со <%register_terms_of_service%> и <%register_privacy_policy%>.',
	register_terms_of_service: 'Условите на услугите',
	register_privacy_policy: 'Политиката на приватност',
	register_already_have_account: 'Веќе имате сметка?',
	register_login_here: 'Пријавете се тука',
    complete_registration_with_social_login: '',
	saved_cards_pay_with_saved: 'Плаќање со веќе зачувана картичка?',
	saved_cards_or_enter_new_card: 'Или внесете нова картичка овде',
	saved_cards_card_number: 'Број на картичката',
	saved_cards_item_ordered: 'Предмет за нарачка',
	saved_cards_go_back: 'врати се назад',
	user_menu_use_different: 'Употреби друга сметка',
	user_menu_my_account: 'Претплати...',
	user_menu_log_out: 'Одјави се',
	dlc_download: 'Симни',
	code: 'mk',
	lang_macedonian: 'македонски',
	lang_english: 'англиски',
	lang_spanish: 'шпански',
	lang_polish: 'полски',
	lang_french: 'француски',
	lang_swedish: 'шведски',
	select_country: 'Изберете држава',
	card_payment: 'Плаќање со картичка',
	paypal_payment: 'Плаќање преку PayPal',
	card_management: 'Платежни картички',
	subscriptions: 'Претплати',
	sales_currently_not_available: 'Продажбата е моментно затворена',
	account_management: 'Ажурирање на сметка'
};