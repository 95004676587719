module.exports = {
	console_element_doesnt_exist: 'Element med id "%s" eksisterer ikke.',
	console_saved_cards_not_implemented: 'Betaling med gemte kort er ikke implementerede endnu.',
	console_form_element_not_found: 'Form element ikke fundet, id: %s',
	console_body_element_not_found: 'Krop element ikke fundet, id: %s',
	console_method_doesnt_exist: 'Metode %s eksisterer ikke.',
	console_asset_no_access_fees: 'Dette aktiv har ingen adgangsgebyrer.',
	global_ise_pls_try_again: 'Intern server fejl. Prøv igen.',
	message_full_name: 'Det fulde navn skal ikke være tom.',
	message_email: 'Email en skal ikke være tom.',
    message_username: 'Email en skal ikke være tom.',
	message_password: 'Kodeordet skal ikke være tom.',
	message_password_confirmation: 'Kodeordsbekræftelsen skal matche kodeordsinputtet.',
	message_number: 'Kortnummeret er ikke gyldigt.',
	message_card_expiry: 'Kortets udløbsdato er ikke gyldigt.',
	message_cvv: 'Cvv et er ikke gyldigt.',
	message_card_name: 'Kortnavnet skal ikke være tom.',
	message_unauthorized: 'Ugyldig token. Log ind igen.',
	subs_no_active_subscriptions: 'Ingen aktive abonnementer',
	subs_created_on: 'Lavet på',
	subs_next_billing_on: 'Næste fakturering på',
	paywall_free_trial_text: 'Betaling af dette aktiv vil lave abonnering med %s gratis prøveperiode. Du vil blive opkrævet %s %s efter den gratis prøveperiode udløber. Du kan altid aflyse din abonnering før den gratis prøveperiode slutter.',
	paywall_full_discount: '100% rabat',
	placeholder_email_address: 'Email adresse',
	placeholder_password: 'Kodeord',
	placeholder_repeat_password: 'Gentag Kodeord',
	placeholder_name_on_card: 'Navn på kort',
	placeholder_voucher_code: 'rabatkode',
	placeholder_full_name: 'Fulde navn',
    placeholder_first_name: 'Fornavn',
    placeholder_surname: 'Efternavn',
	tooltip_forgot_password: 'Glemt kodeord',
	tooltip_cancel_subscription: 'Aflys abonnering',
	modal_enter_your_email: 'Skriv din email adresse.',
	modal_submit: 'Indsend',
	modal_back: 'tilbage',
	modal_sign_up: 'Opret bruger',
	modal_forgot_password: 'Glemt kodeord?',
	modal_register_capital: 'TILMELD',
    modal_choose_price: 'VÆLG PRIS',
	modal_payment_details: 'BETALINGSDETALJER',
	modal_login_to_your_account: 'Log ind på din <%brand_name%> konto',
	modal_dont_have_one: 'Har ikke en?',
	modal_register_here: 'Tilmeld her',
	modal_enter_your_new_password: 'Skriv dit nye kodeord.',
	modal_please_check_your_email: 'Tjek din email og kopier det glemte token.',
	modal_resend_token: 'Send token igen',
	modal_copyright: 'Copyright © Inplayer LLC, Alle rettigheder forbeholdes.',
	modal_terms: 'Betingelser',
	ladda_log_in: 'Log ind',
	ladda_apply: 'Ansøg',
	ladda_next: 'Næste',
	ladda_create_account: 'Opret konto',
	account_your_subscriptions: 'Dine Abonnementer',
	account_no_active_subscriptions: 'Ingen aktive abonnementer',
	payment_pls_enter_your_card: 'Indtast dit kort',
	payment_have_a_promotional_code: 'Har en rabatkode?',
	payment_im_paying_for: 'Jeg betaler for',
	payment_price: 'Pris',
	payment_pay: 'Betal',
	payment_subscribe: 'Abonnere',
	preview_protected_by_inplayer: 'Beskyttet af InPlayer',
	preview_this_premium_content: 'Dette premium indhold kræver en konto for at få adgang.',
	preview_already_have_access: 'Har allerede adgang?',
	preview_login_here: 'Log ind her med din <%brand_name%> konto',
	prices_pls_select_price: 'Vælg pris mulighed',
	register_register_new_account: 'Tilmeld en ny <%brand_name%> konto',
	register_by_clicking_agree: 'Ved at klikke "Opret Konto" knappen er jeg enig med <%register_terms_of_service%> og <%register_privacy_policy%>.',
	register_terms_of_service: 'Betingelser for Service',
	register_privacy_policy: 'Fortrolighedspolitik',
	register_already_have_account: 'Har allerede en konto? %s',
	register_login_here: 'Log ind her',
	saved_cards_pay_with_saved: 'Betal med en af dine allerede gemte kort?',
	saved_cards_or_enter_new_card: 'Eller indtast et nyt kort her',
	saved_cards_card_number: 'Kortnummer',
	saved_cards_item_ordered: 'Vare bestilt',
	saved_cards_go_back: 'gå Tilbage',
	user_menu_use_different: 'Brug en anden konto',
	user_menu_my_account: 'Abonnementer...',
	user_menu_log_out: 'Log ud',
	card_management: 'Kortadministration',
	subscriptions: 'Abonnementer',
	manage_your_cards: 'Administrer Dine Kort',
	select_card: 'Vælg Kort',
	edit_card: 'Rediger…',
	add_new_card: 'Tilføj Nyt Kort',
	code: 'da',
    modal_payment_method: 'Betalingsmetode',
    user_menu_account_details: 'Min Profil',
    user_menu_change_password: 'Skift kodeord',
    account_details: 'Kontonavn',
    update_profile_data: 'Opdater',
    placeholder_old_password: 'Nuværende kodeord'
};