var lang = require('./language.js');

module.exports = {
    validation: {
        full_name: 'The full name should not be empty.',
        email: 'The email should not be empty.',
        password: 'The password should not be empty.',
        password_confirmation: 'The password confirmation should match the password input.',
        number: 'The card number is not valid.',
        card_expiry: 'The card expiry is not valid.',
        cvv: 'The cvv is not valid.',
        card_name: 'The card name should not be empty.'
    },
    unauthorized: 'Invalid token. Please login again.'
};