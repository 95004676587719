var $ = require('jquery'),
    templates = require('../../templates.js'),
    Mustache = require('mustache'),
    unescape = require('lodash/unescape');

module.exports = {
    inject: function(element, asset, success){
        try {
            var content = JSON.parse(unescape(asset.content));

            if (content.placement){
                content.placement.url = content.placement.url || 'about:blank';
            }

            var view = (function(){
                var res = $.extend({
                    formdata: [],
                    inplayer_asset_id: asset.id
                }, content.placement);

                $.each(content.formdata, function(name, value){
                    res.formdata.push({
                        name: name,
                        value: value
                    });
                });

                return res;
            })();

            element.html(Mustache.render(templates.panopto, view));
            $('#inplayer-panopto-form-' + asset.id).submit();

            $.isFunction(success) && success();
        }
        catch(err){
            throw new Error(e);
        }
    }
};