var $ = require('jquery'),
	settings = {};
module.exports = (function(){
	return {
		setup: function(obj){
			settings = obj;
			examine();
		},
		getAll: function(){
			return settings;
		}
	};
})();

function examine(){
	if (settings.hideUserMenu){
		$('#inplayer-menu-content').addClass('inplayer-hidden');
	}
}