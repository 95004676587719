var $ = require('jquery'),
	Mustache = require('mustache'),
	Cookie = require('js-cookie'),
	brand = require('./inplayer-brand.js'),
	instances = require('./instances'),
	isEmpty = require('lodash/isEmpty'),
    events = require('./global-events'),
	state = require('./state');

var ids = {
	main: '#inplayer-language-menu'
};

module.exports = (function(initial){
	var main;
	var allLangs = {};
	var current = {
		name: '',
        code: '',
		data: {}
	};

	var self = {
		load: function(){
			var paths = require.context('./languages', false, /.js/).keys();

			allLangs['english'] = require('./languages/english');

			$.each(paths, function(i, path, name, data){
				name = path.split('/').reverse()[0].split('.')[0];

				if (name === 'english' || name === 'macedonian'){
					return;
				}

				data = require('./languages/' + name);
				allLangs[name] = data;
			});

			// uncomment this when you need to check for missing entries in any of the languages against the default language (English)
			// checkForMissingLines(allLangs /*, 'danish'*/);

			// compensating for missing lines (fallback to English)
			$.each(allLangs, function(langName, langData){
				if (langName === 'english'){
					return;
				}

                allLangs[langName] = $.extend({}, allLangs['english'], langData);
			});
		},
		init: function(){
			var menu = $(ids.main),
				list = menu.find('ul');

			if (list.length && list.is(':empty')){
				$.each(allLangs, function(name, data){
					// exclude Macedonian language
					if (data.code === 'mk'){
						return;
					}

					var line = $('<li data-entry="' + name + '"></li>').attr('lang', data.code);
					line.text(data.code.toUpperCase());
					line.appendTo(list);
				});
			}

			if (current.data.code) {
				$(ids.main + ' div').attr('lang', current.data.code).text(current.data.code.toUpperCase());
			}

			if (main){
				return;
			}

			main = $(ids.main);

			$('body').on('mouseover', ids.main, function(e){
				var me = $(this);

				me.addClass('inplayer-cls-border-lang');
				me.children().eq(0).addClass("inplayer-cls-borderbottom-lang");
				$(ids.main + ' ul').stop().slideDown(100);
			});

			$('body').on('mouseout', ids.main, function(e){
				var me = $(this);

				me.removeClass('inplayer-cls-border-lang');
				me.children().eq(0).removeClass("inplayer-cls-borderbottom-lang");
				$(ids.main + ' ul').stop().slideUp(100);
			});

			$('body').on('click', ids.main + ' li', function(e){
				var me = $(this);

				$(ids.main + ' div').attr('lang', me.prop('lang')).text(me.prop('lang').toUpperCase());
				me.removeClass('inplayer-cls-border-lang');
				me.children().eq(0).removeClass("inplayer-cls-borderbottom-lang");
				$(ids.main + ' ul').stop().slideUp(100);

				var selectedLang = me.data().entry;

				self.select(selectedLang, true);
			});
		},
		select: function(name, saveCookie, silent){
			if (name === current.name){
				return false;
			}

			if (!allLangs[name]){
				if (!silent) {
                    console.info('Language "' + name + '" is not supported. Languages that are currently supported: ' + Object.keys(allLangs).join(', ') + '.');
                }

                return false;
			}

			if (saveCookie){
				Cookie.set('inplayer-language', name, {
					path: '/'
				});
			}

			current.name = name;
			current.data = allLangs[name];
			current.code = current.data.code;

			return self.updateText();
		},
		updateText: function(){
			var root = $('body').children('[id^="inplayer-"]');

            events.global.trigger('language', {
                language: current
            });

			$.each(['text', 'placeholder', 'htmltext', 'tooltip'], function(i, prop){
				$.each(root.find('[data-' + prop + ']'), function(j, element){
					element = $(element);
					var entry = element.data()[prop];
					var text = self.getText(entry);

					if (prop === 'htmltext'){
						prop = 'html';
					}

					if (element[prop]){
						if ($.isFunction(element[prop])){
							element[prop](text);
						}else{
							element[prop] = text;
						}
					}else{
						element.attr(prop, text);
					}
				});
			});

			self.dataHtml(root.find('[data-html]'));

			return true;
		},
		getText: function(key, replacement, text){
			if (key){
                text = current.data[key] || instances.getBranding(state.current_asset.id)[key];

				if (!text){
					return key;
				}

                if (text.indexOf(Mustache.tags[0] + '&') >= 0){
                    return Mustache.render(text, $.extend({}, state.non_lang_texts, instances.getBranding(state.current_asset.id)));
                }

				if (!replacement){
					return text;
				}
				else if (typeof replacement === 'string'){
					return text.replace(/%s/, replacement);
				}
				else if ($.isArray(replacement)){
					$.each(replacement, function(i, rep){
						text = text.replace(/%s/, rep);
					});
					return text;
				}
				else if ($.isPlainObject(replacement)){
					return Mustache.render(text, replacement);
				}
			}else{
				return current.data;
			}
		},
		dataHtml: function(els){
			$.each(els, function(i, el){
				el = $(el);

				var text = self.getText(el.data().html);
				var symbols = Mustache.tags;
				var children = el.children('[data-text]');

				if (children.length) {
					$.each(children, function (i, child) {
						child = $(child);

						var data = child.data();
						if (data && data.text) {
							var key = new RegExp(symbols[0] + data.text + symbols[1]);
							text = text.replace(key, child.prop('outerHTML'));
						}
					});
				}

				el.html(text);
			});
		},
		error: function(key, replacement1, replacement2){
			throw new Error(self.getText(key, replacement1, replacement2));
		},
		info: function(key, replacement1, replacement2){
			console.info(self.getText(key, replacement1, replacement2));
		},
		setOptions: function(opts){
			var initial = Cookie.get('inplayer-language');

			if (isEmpty(initial) || isEmpty(allLangs[initial])) {
				initial = 'english';

				if (opts.language && opts.language.initial) {
					$.each(allLangs, function (name, data) {
						if (data.code === opts.language.initial) {
							initial = name;
						}
					});
				}
			}

			self.select(initial);
		}
	};

	self.load();

	return self;
})();

function checkForMissingLines(allLangs, inWhichOne){
	var missing = {},
		langs = {},
		defLang = allLangs['english'];

	inWhichOne ? langs[inWhichOne] = allLangs[inWhichOne] : langs = allLangs;

	$.each(langs, function(langName, langData){
		if (langData === defLang){
			return;
		}

		missing[langName] = {};

		$.each(defLang, function(key, text){
			if (!langData.hasOwnProperty(key)){
				missing[langName][key] = text;
			}
		});
	});

    // console.log(JSON.stringify(missing));
    console.log(missing);
}