module.exports = {
	console_element_doesnt_exist: 'Element met id "%s" bestaat niet.',
	console_saved_cards_not_implemented: 'Betaling met opgeslagen kaarten nog niet geïmplementeerd.',
	console_form_element_not_found: 'Formulier element niet gevonden, id: %s',
	console_body_element_not_found: 'Inhoudsdeel niet gevonden, id: %s',
	console_method_doesnt_exist: 'Methode %s bestaat niet.',
	console_asset_no_access_fees: 'Deze aanwinst heeft geen toegangsvergoeding.',
	global_ise_pls_try_again: 'Interne server error. Probeer opnieuw.',
	message_full_name: 'Volledige naam mag niet leeg zijn.',
	message_email: 'E-mail mag niet leeg zijn.',
    message_username: 'E-mail mag niet leeg zijn.',
	message_password: 'Wachtwoord mag niet leeg zijn.',
	message_password_confirmation: 'Wachtwoord bevestiging moet met ingegeven wachtwoord overeenkomen.',
	message_number: 'Kaart nummer is niet geldig.',
	message_card_expiry: 'Kaart vervaldatum is niet geldig.',
	message_cvv: 'De cvv is niet geldig.',
	message_card_name: 'Kaart naam mag niet leeg zijn.',
	message_unauthorized: 'Ongeldig token. Opnieuw inloggen.',
	subs_no_active_subscriptions: 'Geen actieve abonnementen',
	subs_created_on: 'Aangemaakt op',
	subs_next_billing_on: 'Volgende facturatie op',
	paywall_free_trial_text: 'Betalen voor deze aanwinst zal een abonnement aanmaken met %s gratis proef. U word in rekening gebracht %s %s nadat de gratis proef verloopt. U kunt altijd uw abonnement annuleren voordat de gratis proefperiode eindigt.',
	paywall_full_discount: '100% korting',
	placeholder_email_address: 'E-mailadres',
	placeholder_password: 'Wachtwoord',
	placeholder_repeat_password: 'Herhaal Wachtwoord',
	placeholder_name_on_card: 'Naam op de kaart',
	placeholder_full_name: 'Volledige naam',
    placeholder_first_name: 'Voornaam',
    placeholder_surname: 'Achternaam',
	tooltip_forgot_password: 'wachtwoord vergeten',
	tooltip_cancel_subscription: 'Annuleer abonnement',
	modal_enter_your_email: 'Vul uw e-mailadres in.',
	modal_submit: 'Verzenden',
	modal_back: 'terug',
	modal_login: 'inloggen',
	modal_sign_up: 'registreren',
	modal_forgot_password: 'wachtwoord vergeten',
	modal_register_capital: 'REGISTREREN',
	modal_login_capital: 'INLOGGEN',
	modal_choose_price: 'KIES DE PRIJS',
	modal_payment_details: 'BETALINGSDETAILS',
	modal_login_to_your_account: 'Log in op uw <%brand_name%> account',
	modal_dont_have_one: 'Heeft u er geen?',
	modal_register_here: 'Registreer hier',
	modal_enter_your_new_password: 'Vul uw nieuwe wachtwoord in.',
	modal_please_check_your_email: 'Controleer uw e-mail en kopieer het vergeten wachtwoord token.',
	modal_resend_token: 'stuur token opnieuw',
	modal_copyright: 'Copyright © Inplayer LLC, Alle rechten voorbehouden.',
	modal_terms: 'Voorwaarden',
	ladda_log_in: 'Inloggen',
	ladda_apply: 'Toepassen',
	ladda_next: 'Volgende',
	ladda_create_account: 'account aanmaken',
	account_your_subscriptions: 'Uw abonnementen',
	account_no_active_subscriptions: 'Geen actieve abonnementen',
	payment_pls_enter_your_card: 'Geef uw kaart in',
	payment_have_a_promotional_code: 'Heeft u een promotie code?',
	payment_im_paying_for: 'Ik betaal voor',
	payment_price: 'Prijs',
	payment_pay: 'Betalen',
	preview_protected_by_inplayer: 'Beschermd door InPlayer',
	preview_this_premium_content: 'Deze premium inhoud vereist een account om toegang te hebben.',
	preview_already_have_access: 'Heeft u al toegang?',
	preview_login_here: 'Log hier in met uw <%brand_name%> account',
	prices_pls_select_price: 'Selecteer prijs optie',
	register_register_new_account: 'Registreer een nieuw <%brand_name%> account',
	register_by_clicking_agree: 'Door te klikken op "Account Aanmaken" knop hieronder ga ik akkord met de <%register_terms_of_service%> en <%register_privacy_policy%>.',
	register_terms_of_service: 'Servicevoorwaarden',
	register_privacy_policy: 'Privacy beleid',
	register_already_have_account: 'Heeft u al een account? %s',
	register_login_here: 'Hier inloggen',
    complete_registration_with_social_login: '',
	saved_cards_pay_with_saved: 'Betalen met één van uw opgeslagen kaarten?',
	saved_cards_or_enter_new_card: 'Of voer hier een nieuwe kaart in',
	saved_cards_card_number: 'Kaart nummer',
	saved_cards_item_ordered: 'Artikel Besteld',
	saved_cards_go_back: 'ga Terug',
	user_menu_use_different: 'Gebruik een ander account',
	user_menu_my_account: 'Abonnementen...',
	user_menu_log_out: 'Uitloggen',
	card_management: 'Kaart beheer',
	subscriptions: 'Abonnementen',
	manage_your_cards: 'Beheer Uw Kaarten',
	select_card: 'Selecteer Kaart',
	edit_card: 'Wijzigen…',
	add_new_card: 'Nieuwe Kaart Toevoegen',
	code: 'nl'
};