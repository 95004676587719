'use strict';

var $ = require('jquery')
    , utils = require('../../utils.js')
    , unescape = require('lodash/unescape')
    , customParams = require('../../player-params')
    , events = require('../../global-events')
    , players = [];
;

events.global.on('logout', destroyContent);

module.exports = {
    inject: function (element, asset, success) {
        try {
            var content = JSON.parse(unescape(asset.content));

            if (content && $.isPlainObject(content)) {
                if (window.OO) {
                    injectContent(element, content, customParams, success);
                    return;
                }

                var scripts = customParams.getScripts('ooyala') || [
                    {
                        src: 'http://player.ooyala.com/static/v4/stable/4.6.9/core.min.js',
                        type: 'script'
                    },
                    {
                        src: 'http://player.ooyala.com/static/v4/stable/4.6.9/video-plugin/main_html5.min.js',
                        type: 'script'
                    },
                    {
                        src: 'http://player.ooyala.com/static/v4/stable/4.6.9/skin-plugin/html5-skin.min.js',
                        type: 'script'
                    },
                    {
                        src: 'http://player.ooyala.com/static/v4/stable/4.6.9/skin-plugin/html5-skin.min.css',
                        type: 'link'
                    }
                ];

                utils.loadScripts(scripts, function () {
                    injectContent(element, content, customParams, success);
                });
            }
        }
        catch (e) {
            throw new Error(e);
        }
    }
};

function injectContent(element, content, customParams, success) {
    var playerParam = {
        "pcode": content.pcode,
        "playerBrandingId": content.player_id,
        "skin": {
            "config": "http://player.ooyala.com/static/v4/stable/4.6.9/skin-plugin/skin.json"
        }
    };

    $.extend(playerParam, customParams.getParams('ooyala'));

    OO.ready(function() {
        var player = OO.Player.create(element.attr('id'), content.embed_code, playerParam);
        window.pp = player;

        players.indexOf(player) === -1 && players.push(player);

        success();
    });
}

function destroyContent(){
    $.each(players, function(i, player){
        player.destroy();
    });

    players = [];
}